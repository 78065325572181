import React, { useState } from "react";
import BackgroundImageFromUrl from "../../components/parseImage/BackgroundImageFromUrl";
import { checkImage } from "../../constants/checkImage";
import ConstantStrings from "../../constants/ConstantStrings";
import { loadWidthImage } from "../../constants/loadWidthImage";
import QuestionNumberTag from "../../detail-components/QuestionNumberTag";
import ScrollRef from "../../detail-components/ScrollRef";
import jsonParser from "../helper/jsonParser";
import { cacheImage, getCacheImage, getImageUrl } from "../helper/parseImage";

const QPrompt2_4 = ({ data, order, isLast }) => {
  const content = data?.content || data?.prompt;
  const [imgWidth, setImgWidth] = useState(0);
  // const [isLoading, setIsLoading] = useState(
  //   getCacheImage([content?.images[0]]) ? false : true
  // );
  const contentText =
    content?.texts[0] !== null &&
      content?.texts[0] !== undefined &&
      content?.texts[0]?.length > 0
      ? jsonParser(content?.texts[0])
      : content?.texts[0];
  const [isLoading, setIsLoading] = useState(
    checkImage(content?.images[0])
      ? getCacheImage([content?.images[0]])
        ? false
        : true
      : false
  );
  var key = getImageUrl(content?.images[0]);
  loadWidthImage(key, (err, img) => {
    cacheImage([key]);
    setImgWidth(
      (img.width / ConstantStrings.BASE_REM) * ConstantStrings.MULTIPLIERIMAGE
    );
    setIsLoading(false);
  });

  var imageStyle = {
    width: `${imgWidth}rem`,
    borderRadius: "1rem",
    height: "100%",
  };

  return (
    <div>
      {!isLast &&
        !isLoading &&
        <ScrollRef />}

      <BackgroundImageFromUrl
        objectId={content?.backgroundImage}
        className={`${isLoading ? "is-hidden" : ""
          } is-flex is-flex-direction-row is-center is-align-items-center is-justify-content-space-between has-background-kurio-main-color-white has-text-kurio-main-color-black`}
        style={{
          borderRadius: "1rem 1rem 0 0",
          fontSize: "1.25rem",
          lineHeight: "160%",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          padding: "2rem",
          gap: "2rem",
        }}
      >
        <div
          className={`${isLoading ? "is-hidden" : ""
            } is-narrow is-flex is-flex-direction-column is-justify-content-center is-align-items-flex-start`}
          style={{ gap: "1rem" }}
        >
          {data?.template.question !== null &&
            data?.template.question &&
            data?.template.question.length > 0 && (
              <QuestionNumberTag number={order === 0 ? data?.order : order} />
            )}

          <div className="content has-text-left" style={{}}>
            {contentText}
          </div>
        </div>
        <img
          style={imageStyle}
          src={getImageUrl(content?.images[0])}
          alt={content?.images[0]}
          onError={(e)=>{ e.target.src = ConstantStrings.DEFAULT_IMAGE;e.target.style.width = "2.5rem";e.target.style.height = "2.5rem"; }}
        />
      </BackgroundImageFromUrl>
    </div>
  );
};

export default QPrompt2_4;
