import { useState } from "react";
import BackgroundImageFromUrl from "../../components/parseImage/BackgroundImageFromUrl";
import { checkImage } from "../../constants/checkImage";
import ConstantStrings from "../../constants/ConstantStrings";
import { loadWidthImage } from "../../constants/loadWidthImage";
import QuestionNumberTag from "../../detail-components/QuestionNumberTag";
import ScrollRef from "../../detail-components/ScrollRef";
import jsonParser from "../helper/jsonParser";
import { cacheImage, getCacheImage, getImageUrl } from "../helper/parseImage";

function QPrompt2_2({ data, order, isLast }) {
  const content = data?.content || data?.prompt;
  const contentText =
    content?.texts[0] !== null &&
      content?.texts[0] !== undefined &&
      content?.texts[0]?.length > 0
      ? jsonParser(content?.texts[0])
      : content?.texts[0];

  var imageStyle = {
    borderRadius: "0 1rem 0 0",
    width: "100%",
    height: "100%",
  };
  var key = getImageUrl(
    content?.images?.length > 1 && checkImage(content?.images[1])
      ? content?.images[1]
      : content?.images[0]
  );
  const [isLoading, setIsLoading] = useState(() => {
    if (
      getCacheImage([key]) ||
      (!checkImage(content?.images[0]) && !checkImage(content?.images[1]))
    ) {
      return false;
    } else {
      return true;
    }
  });
  loadWidthImage(key, (err, img) => {
    cacheImage([key]);
    setIsLoading(false);
  });
  return (
    <div>
      {!isLast &&
        !isLoading &&
        <ScrollRef />}

      <BackgroundImageFromUrl
        objectId={content?.backgroundImage}
        className={`${isLoading ? "is-hidden" : ""
          }  columns m-auto is-centered has-background-kurio-main-color-white has-text-kurio-main-color-black p-0 is-justify-content-flex-end`}
        style={{
          borderRadius: "1rem 1rem 0 0",
          backgroundSize: "cover",
          fontSize: "1.25rem",
          lineHeight: "160%",
          maxWidth: "60rem",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
        }}
      >
        <div
          className={`${isLoading ? "is-hidden" : ""
            } column is-half is-flex is-flex-direction-column is-align-items-flex-start is-justify-content-center`}
          style={{
            padding: "2rem",
            gap: "1rem",
          }}
        >
          {data?.template.question !== null &&
            data?.template.question &&
            data?.template.question.length > 0 && (
              <QuestionNumberTag number={order === 0 ? data?.order : order} />
            )}

          <div
            className={`${isLoading ? "is-hidden" : ""} content-text `}
            style={{ maxWidth: "40rem" }}
          >
            {contentText}
          </div>

          <img src={getImageUrl(content?.images[0])} alt={content?.images[0]}onError={(e)=>{ e.target.src = ConstantStrings.DEFAULT_IMAGE;e.target.style.width = "2.5rem";e.target.style.height = "2.5rem"; }} />
        </div>
        <div
          className={` content-image column is-half p-0`}
          style={{ maxWidth: "50%" }}
        >
          <img
            style={imageStyle}
            src={getImageUrl(content?.images[1])}
            alt={content?.images[1]}
            onError={(e)=>{ e.target.src = ConstantStrings.DEFAULT_IMAGE;e.target.style.width = "2.5rem";e.target.style.height = "2.5rem"; }}
          />
        </div>
      </BackgroundImageFromUrl>
    </div>
  );
}

export default QPrompt2_2;
