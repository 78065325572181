import {
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
} from "chart.js";
import React, { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import { connect } from "react-redux";
import { getLifetimeStatistics } from "../../config/lifetimeStatistics";
import ScrollRefWrapper from "../../detail-components/ScrollRefWrapper";
import "./ProfileMainWrapper.styles.css";

const customBackgroundPlugin = {
  id: "customBackground",
  beforeDraw: function (chart) {
    const ctx = chart.ctx;

    ctx.fillStyle = "#E9EEF8"; // Màu nền
    ctx.fillRect(0, 0, chart.width, chart.height); // Vẽ hình chữ nhật toàn bộ
  },
};

const addArrowsFunction = {
  id: "addArrowsFunction",
  afterDraw: function (chart) {
    if (chart.config.options.arrows) {
      const { ctx } = chart;
      const xAxes = chart.scales.x;
      const yAxes = chart.scales.y;

      // Mũi tên trục X
      ctx.lineCap = "butt";
      ctx.save();
      ctx.beginPath();

      // ctx.moveTo(xAxes.left, xAxes.top);
      ctx.lineTo(xAxes.right, xAxes.top);
      ctx.lineTo(xAxes.right - 6, xAxes.top - 6);
      ctx.moveTo(xAxes.right, xAxes.top);
      ctx.lineTo(xAxes.right - 6, xAxes.top + 6);
      ctx.strokeStyle = "#0A2A66";
      ctx.lineWidth = 1;
      ctx.stroke();
      ctx.fill();

      // Mũi tên trục Y
      ctx.save();
      ctx.beginPath();
      ctx.moveTo(yAxes.right, yAxes.top);
      ctx.lineTo(yAxes.right, yAxes.top);
      ctx.lineTo(yAxes.right + 6, yAxes.top + 6);
      ctx.moveTo(yAxes.right, yAxes.top);
      ctx.lineTo(yAxes.right - 6, yAxes.top + 6);
      ctx.strokeStyle = "#0A2A66";
      ctx.lineWidth = 1;
      ctx.stroke();
      ctx.restore();
    }
  },
};

const customGridRightPlugin = {
  id: "customGridRightPlugin",
  beforeDraw: function (chart) {
    const ctx = chart.ctx;
    const xAxis = chart.scales.x;
    const yAxis = chart.scales.y;

    ctx.save();
    ctx.strokeStyle = "#0A2A66"; // Màu sắc grid của trục ngang ngoài cùng
    ctx.lineWidth = 1;

    // Tính toán vị trí x của grid trục ngang ngoài cùng
    const outerX = xAxis.getPixelForValue(xAxis.min);

    // Vẽ grid của trục ngang ngoài cùng
    ctx.beginPath();
    ctx.moveTo(outerX, yAxis.top);
    ctx.lineTo(outerX, yAxis.bottom);
    ctx.stroke();

    ctx.restore();
  },
};

// const calcu
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  customBackgroundPlugin,
  customGridRightPlugin,
  addArrowsFunction
);

ChartJS.register(LinearScale);

const ProfileMainWrapper = ({ grades }) => {
  const [listLifeStatistics, setListLifeStatistics] = useState([]);

  useEffect(() => {
    const lifetimeStatistic = async () => {
      const response = await getLifetimeStatistics();

      setListLifeStatistics(response.result);
    };

    lifetimeStatistic();
  }, [grades]);

  const filterGradesRankings = listLifeStatistics?.monthExamRankings?.filter(
    (item) => item.gradeIds.includes(grades)
  );

  let monthExamRankings =
    filterGradesRankings &&
    filterGradesRankings?.sort((a, b) => a.month - b.month);
  // data.sort((a, b) => a.month.localeCompare(b.month));
  monthExamRankings = monthExamRankings?.slice(5, 10);

  let labels =
    monthExamRankings && monthExamRankings?.map((item) => item.month); // tháng

  var subLabelSlice = labels?.map((item) => parseInt(item.slice(2, 4)));

  var labelsSlice = labels?.map((item) => parseInt(item.slice(4)));

  labels = labelsSlice?.map(
    (value, index) => `${value}/${subLabelSlice[index]}`
  );

  labels?.push("");
  labels?.push("");

  const maxY = 50;
  const minY = -10;
  const stepSize = 10;

  const originalData =
    monthExamRankings && monthExamRankings?.map((item) => item.ranking);

  const tableData =
    originalData !== undefined &&
    originalData?.map((item) => (item > 50 ? 50 : item === 1 ? 0 : item));

  const data = {
    labels,
    datasets: [
      {
        // label: "Xếp hạng",
        data: tableData && [null, ...tableData],
        borderColor: "#0A2A66",
        backgroundColor: "#0A2A66",
        fill: false,
        pointRadius: 8,
        pointHoverRadius: 8,
        pointBorderWidth: 6,
        hoverBorderWidth: 6,
        pointBackgroundColor: "#0A2A66",
        pointHoverBackgroundColor: "#FED131",
        pointBorderColor: "#FAFBFC",
        pointHoverBorderColor: "#572CD1",
        borderWidth: 2,
        bevelShadowColor: "rgba(0, 0, 0, 0.5)",
      },
    ],
  };

  const options = {
    arrows: true,
    responsive: true,
    maintainAspectRatio: false,

    scales: {
      x: {
        display: true,

        grid: {
          display: true,
          drawOnChartArea: false,
          drawTicks: true, //show ticks
          tickColor: (context) => {
            if (context.index !== labels.length - 1 && context.index !== 0)
              return "#0A2A66";
            if (context.index === 0) return "transparent";
          },
          drawBorder: true,
          color: (context) => {
            if (context.index !== labels.length - 1 && context.index !== 0)
              return "#BEC9E0";
            if (context.index === 0) return "#0A2A66";
          },
        },

        ticks: {
          color: "#0A2A66",
          beginAtZero: false, // Bắt đầu trục y không phải từ 0
          display: true,
          position: "top",

          font: (context) => {
            if (context.index !== labels.length - 1)
              return {
                size: 15,
                weight: "bold",
                family: "Arial",
              };
          }, // Màu cho các tick trục X

          callback: (_, index, ticks) => {
            if (index === 0) return "";
            if (index === ticks.length - 1) return "Tháng";
            return data.labels[index - 1];
          },
        },
      },
      y: {
        display: true,
        reverse: true,
        grid: {
          display: true,
          autoSkip: false,
          drawTicks: true, //show ticks

          tickColor: (context) => {
            if (context.index !== 6 && context.index !== 0) return "#0A2A66";
            if (context.index === 0) return "transparent";
          }, //ticks color (little line above points),
          drawBorder: false,
          color: (context) => {
            if (context.index !== 6 && context.index !== 0) return "#BEC9E0";
            if (context.index === 0) return "#0A2A66";
          },
        },

        suggestedMin: minY,
        suggestedMax: maxY,
        ticks: {
          color: "#0A2A66",
          stepSize,
          padding: 10,

          font: (context) => {
            if (context.index !== 6)
              return {
                size: 15,
                weight: "bold",
                family: "Arial",
              };
          }, // Màu cho các tick trục X
          callback: (_, index, ticks) => {
            if (index === 0) return `> ${maxY}`;
            if (index === ticks.length - 1) return `Thứ hạng`;
            return `${(ticks.length - index - 1) * stepSize + 1 - 10} - ${
              (ticks.length - index) * stepSize - 10
            }`;
          }, // Exclude the extended maximum value from ticks
        },
      },
    },

    plugins: {
      customBackgroundPlugin,
      customGridRightPlugin,
      addArrowsFunction,

      legend: {
        display: false, // Ẩn hiển thị chú thích
      },

      tooltip: {
        callbacks: {
          title: (context) => {
            return `Tháng ` + labels[context[0].dataIndex - 1];
          },
          label: (context) => {
            return `${originalData[context.dataIndex - 1]}`;
          },
        },
        backgroundColor: "#FAFBFC", // Màu nền tooltip
        displayColors: false, // Ẩn hộp màu
        titleColor: "#0A2A66", // Màu chữ tiêu đề tooltip
        bodyColor: "#572CD1", // Màu chữ nội dung tooltip
        padding: 20, // Khoảng cách giữa nội dung và viền tooltip
        bodyAlign: "center",
        shadowOffsetX: 3,
        shadowOffsetY: 3,
        shadowBlur: 10,
        shadowColor: "black",
        titleAlign: "center",
        bodyFont: {
          size: 20,
          weight: "bold",
        },
      },
    },
  };

  return (
    <div
      className="is-flex is-flex-direction-column is-align-content-center is-flex-wrap-nowrap"
      style={{ gap: "2.5rem", marginTop: "1rem", paddingBottom: "2rem" }}
    >
      <ScrollRefWrapper />
      <h1 className="has-text-centered has-text-kurio-main-color-black is-size-2 has-text-weight-bold">
        Sổ liên lạc
      </h1>

      <div
        className="is-flex is-flex-direction-column is-align-items-flex-start"
        style={{ gap: "4rem" }}
      >
        <div
          className="is-flex is-flex-direction-row is-justify-content-space-between is-align-content-flex-start"
          style={{ width: "50rem", gap: "auto", margin: "0 auto" }}
        >
          <div
            className="has-background-kurio-main-color-purple is-flex is-flex-direction-column is-justify-content-center is-align-items-center"
            style={{
              width: "15rem",
              height: "12.5rem",
              borderRadius: "1rem",
              border: "0.09375rem solid #0A2A66",
              boxShadow: "0.125rem 0.25rem 0rem 0rem #0A2A66",
            }}
          >
            <div
              className="is-flex is-align-content-flex-start is-flex-direction-column"
              style={{
                width: "10rem",
              }}
            >
              <p
                className="has-text-weight-bold has-text-kurio-main-color-white"
                style={{ fontSize: "1.25rem" }}
              >
                Bài giảng <br /> đã học
              </p>
              <span
                className="has-text-weight-bold has-text-kurio-main-color-white"
                style={{ fontSize: "4rem" }}
              >
                {listLifeStatistics?.learnedLessons}
              </span>
            </div>
          </div>

          <div
            className="has-background-kurio-red-main-accent is-flex is-flex-direction-column is-justify-content-center is-align-items-center"
            style={{
              width: "15rem",
              height: "12.5rem",
              borderRadius: "1rem",
              border: "0.09375rem solid #0A2A66",
              boxShadow: "0.125rem 0.25rem 0rem 0rem #0A2A66",
            }}
          >
            <div
              className="is-flex is-align-content-flex-start is-flex-direction-column"
              style={{ width: "10rem" }}
            >
              <p
                className="has-text-weight-bold has-text-kurio-main-color-white "
                style={{ fontSize: "1.25rem" }}
              >
                Bài tập <br />
                đã làm
              </p>
              <span
                className="has-text-weight-bold has-text-kurio-main-color-white"
                style={{ fontSize: "4rem" }}
              >
                {listLifeStatistics?.doneQuestions}
              </span>
            </div>
          </div>

          <div
            className="has-background-kurio-yellow-dark is-flex is-flex-direction-column is-justify-content-center is-align-items-center"
            style={{
              width: "15rem",
              height: "12.5rem",
              borderRadius: "1rem",
              border: "0.09375rem solid #0A2A66",
              boxShadow: "0.125rem 0.25rem 0rem 0rem #0A2A66",
            }}
          >
            <div
              className="is-flex is-align-content-flex-start is-flex-direction-column"
              style={{ width: "10rem" }}
            >
              <p
                className="has-text-weight-bold has-text-kurio-main-color-white"
                style={{ fontSize: "1.25rem" }}
              >
                Chuỗi ngày
                <br /> đăng nhập liên tiếp
              </p>
              <span
                className="has-text-weight-bold has-text-kurio-main-color-white"
                style={{ fontSize: "4rem" }}
              >
                {listLifeStatistics?.dailyLoginStreak}
              </span>
            </div>
          </div>
        </div>

        <div
          style={{ width: "50rem", margin: "0 auto", gap: "1.5rem" }}
          className="is-flex is-flex-direction-column"
        >
          <h2 className="has-text-kurio-main-color-black is-size-4 has-text-weight-bold">
            Ranking thi tháng
          </h2>

          <div className="ranking-profile">
            <Line data={data} options={options} />
          </div>
        </div>
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    grades: state.grades.gradeId,
  };
}

export default connect(mapStateToProps)(ProfileMainWrapper);
