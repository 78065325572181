import React, { useState } from "react";
import BackgroundImageFromUrl from "../../components/parseImage/BackgroundImageFromUrl";
import ConstantStrings from "../../constants/ConstantStrings";
import { loadWidthImage } from "../../constants/loadWidthImage";
import ScrollRef from "../../detail-components/ScrollRef";
import { cacheImage, getCacheImage, getImageUrl } from "../helper/parseImage";
import MainContent1Title from "./MainContent1Title";

const MainContent2Image = ({ data, isLast }) => {
  const content = data?.content;
  const [isLoading, setIsLoading] = useState(
    getCacheImage([content?.images[0]]) ? false : true
  );
  var key = getImageUrl(content?.images[0]);
  loadWidthImage(key, (err, img) => {
    cacheImage([key]);
    setIsLoading(false);
  });
  return (
    <div className={`has-text-centered`}>
      {!isLast && !isLoading && <ScrollRef />}

      <BackgroundImageFromUrl
        objectId={content?.backgroundImage}
        className={`= m-auto`}
        style={{
          borderRadius: "1rem",
          maxWidth: "60rem",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        {data?.title && !isLoading && <MainContent1Title title={data?.title} />}
        <div className={`= main-content-image`}>
          {/* <ImageFromUrl
            handleSetIsLoading={handleSetIsLoading}
            objectId={content?.images[0]}
          /> */}
          <img src={key} onError={(e)=>{ e.target.src = ConstantStrings.DEFAULT_IMAGE;e.target.style.width = "2.5rem";e.target.style.height = "2.5rem"; }} alt={content?.images[0]} />
        </div>
      </BackgroundImageFromUrl>
    </div>
  );
};

export default MainContent2Image;
