import React, { Fragment, useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import ScrollRefWrapper from '../../detail-components/ScrollRefWrapper';
import './assets/css/main.css';

var colorTextH1 = "#572CD1";
var colorWhite = "#FAFBFC";
var colorBlack = "#0A2A66";

const LandingPage = () => {
  const ListMain = [
    {
      id: 1,
      title: "Học Toán hiệu quả",
      document: "Học toán qua bài tập tương tác và hình ảnh trực quan. Hiểu sâu bản chất chỉ với 15 phút/ngày.",
      image: "/images/landing-page/image-items-1.png",
      imageTablet: "/images/landing-page/image-items-1-tablet.png",
      imageMobile: "/images/landing-page/image-items-1-mobile.png",
    },
    {
      id: 2,
      title: "Lộ trình cá nhân hóa",
      document: "Với thuật toán AI thông minh, Kurio đảm bảo mỗi học sinh có lộ trình phù hợp với trình độ và nhu cầu riêng.",
      image: "/images/landing-page/image-items-2.png",
      imageTablet: "/images/landing-page/image-items-2-tablet.png",
      imageMobile: "/images/landing-page/image-items-2-mobile.png",
    },
    {
      id: 3,
      title: "Luyện thi dễ dàng",
      document: "“Hạ gục” các kỳ thi cùng lộ trình ôn thi của Kurio. Nắm chắc kiến thức trọng tâm, ôn tập lại những phần kiến thức còn yếu.",
      image: "/images/landing-page/image-items-3.png",
      imageTablet: "/images/landing-page/image-items-3-tablet.png",
      imageMobile: "/images/landing-page/image-items-3-mobile.png",
    },
    {
      id: 4,
      title: "Lựa chọn của chuyên gia",
      document: "Chương trình học được soạn bởi những chuyên gia hàng đầu về toán giúp học phát triển tối đa tiềm năng trong môn toán.",
      image: "/images/landing-page/image-items-4.png",
      imageTablet: "/images/landing-page/image-items-4-tablet.png",
      imageMobile: "/images/landing-page/image-items-4-mobile.png",
    },
    {
      id: 5,
      title: "Vui vẻ. Hiệu quả. Mọi lúc, mọi nơi.",
      document: "Có mặt trên tất cả các thiết bị, từ máy tính cá nhân tới máy tính bảng và điện thoại.",
      image: "/images/landing-page/image-items-5.png",
      imageTablet: "/images/landing-page/image-items-5-tablet.png",
      imageMobile: "/images/landing-page/image-items-5-mobile.png",
    },
  ]

  const scrollableDivRef = useRef(null);
  const [isHeaderFixed, setHeaderFixed] = useState(null);
  const [isHeaderFixedMobile, setHeaderFixedMobile] = useState(null);
  const [showPopupDownload, setShowPopupDownload] = useState(true)

  const backToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth' // Cuộn mượt mà
    });
  };
  const handleClosePopupDownload = ()=>{
    setShowPopupDownload(false)
  }
  const  getMobileOperatingSystem=()=> {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;
    // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
        return "Windows Phone";
    }

    if (/android/i.test(userAgent)) {
        return "Android";
    }

    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        return "iOS";
    }

    return "unknown";
  }

  const handleDownloadApp = ()=>{
    var mobileOS = getMobileOperatingSystem();

    if (mobileOS === "Android") {
      window.location.href = "https://play.google.com/store/apps/details?id=vn.kurio.android"
    } else if (mobileOS === "iOS") {
      window.location.href = "https://apps.apple.com/us/app/to%C3%A1n-kurio/id6478091573"
    }
  }

  const handleScroll = () => {
    const header = document.getElementById('header-landingpage');
    const banner = document.getElementById('banner-landingpage');
    const headerTablet = document.getElementById('tablet-header');
    const bannerTablet = document.getElementById('tablet-banner');

    const scrollableDiv = scrollableDivRef.current;

    if (header && banner && scrollableDiv) {
      const headerHeight = header.offsetHeight;
      const divBanner = banner.getBoundingClientRect().top;
      const headerTabletHeight = headerTablet?.offsetHeight;
      const divTabletBanner = bannerTablet?.getBoundingClientRect().top;

      if (divBanner <= headerHeight && divTabletBanner <= headerTabletHeight) {
        if ((divBanner < 0 && divBanner <= headerHeight) || (divTabletBanner < 0 && divTabletBanner <= headerTabletHeight)) {
          setHeaderFixed(true);
        } else {
          setHeaderFixed(false);
        }
      }

      if (scrollableDiv.getBoundingClientRect().top <= -480) {
        setHeaderFixedMobile(true);
      } else {
        setHeaderFixedMobile(false);
      }
    }
  };


  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    const scrollableDiv = scrollableDivRef.current;
    if (scrollableDiv) {
      scrollableDiv.addEventListener('scroll', handleScroll);
      return () => {
        window.removeEventListener('scroll', handleScroll);
        scrollableDiv.removeEventListener('scroll', handleScroll);
      };
    }
  }, []);

  return (
    <>
      <ScrollRefWrapper />
      <div className='wrapper-content has-background-kurio-main-color-white'>
        {/* Desktop landing page */}
        <div className='container-landingpage has-background-kurio-main-color-white' id='dekstop-landingpage'>
          <header style={{ width: "100%", height: "7.5rem", padding: "0rem 2.5rem" }} className='is-flex is-justify-content-center is-align-items-center'>
            <div style={{ height: "100%", padding: "0rem 2rem", borderRadius: "0rem 0rem 2rem 2rem", background: "#F1EEFC" }} className='logo is-flex is-justify-content-center is-align-items-center'>
              <img style={{ width: "12.36194rem", height: "4rem", }} src="/images/landing-page/logo.svg" alt="" />
            </div>
          </header>

          <section id='header-landingpage' className={isHeaderFixed === null ? 'displayNone' : isHeaderFixed ? 'fixedHeader' : 'remote-fixed-header'} style={{ background: isHeaderFixed ? "#F1EEFC" : colorWhite, width: "100%" }}>
            <header style={{ width: "100%", height: "6rem", padding: "0rem 2.5rem" }} className={"is-flex is-flex-direction-row is-justify-content-space-between is-align-items-center"}>
              <img style={{ width: "9.27144rem", height: "3rem", }} src="/images/landing-page/logo.svg" alt="" />

              <Link to={'register'}>
                <button className='is-flex is-align-items-center is-justify-content-center' style={{ gap: "0.5rem", cursor: "pointer", boxShadow: "rgb(10, 42, 102) -2px 2px 0px 1px", width: "12.6875rem", height: "3.5rem", borderRadius: "1rem", border: "1px solid #0A2A66", background: colorTextH1 }}>
                  <p style={{ color: colorWhite, fontSize: "1.25rem", fontWeight: 700, textTransform: "uppercase" }}>Đăng ký</p>
                  <img src="/images/landing-page/icon-arrow.svg" alt="" />
                </button>
              </Link>
            </header>

          </section>
          <div className='is-clickable messenger-icon  is-flex is-justify-content-center is-align-items-center' 
            style={{
              position:"fixed", 
              bottom:"0rem", 
              right:"0rem",
            }}
          >
              <img onClick={() => window.open('https://m.me/kurio.vn')} src="/images/landing-page/logo-messenger.png" alt="" style={{width:"5rem", height:"5rem"}} />
          </div>
          <section id='banner-landingpage' style={{ background: colorWhite, width: "100%" }}>
            <div style={{ height: "35rem", padding: "0rem 5rem", gap: "5rem" }}
              className='is-flex is-justify-content-center is-align-items-center'>
              <div style={{ width: "30.8688rem", height: "26.46594rem" }}>
                <img style={{ width: "26.53631rem", height: "25rem" }} src="/images/landing-page/banner.png" alt="" />
              </div>

              <div className='is-flex is-flex-direction-column is-justify-content-center is-align-items-center' style={{ gap: "1.5rem" }}>
                <p style={{ fontSize: "3rem", fontWeight: 700, lineHeight: "normal", textAlign: "center", color: colorTextH1 }}>Học Toán không khó <br />
                  Đã có <b style={{ color: "#8864F0" }}>Kurio</b> lo</p>

                <div style={{ gap: "1.5rem" }} className='is-flex is-flex-direction-column is-align-items-flex-start'>
                  <Link to={'register'}>
                    <button className='is-flex is-align-items-center is-justify-content-center' style={{ gap: "0.5rem", cursor: "pointer", boxShadow: "rgb(10, 42, 102) -2px 2px 0px 1px", width: "20rem", height: "3.5rem", borderRadius: "1rem", border: "1px solid #0A2A66", background: colorTextH1 }}>
                      <p style={{ color: colorWhite, fontSize: "1.25rem", fontWeight: 700, textTransform: "uppercase" }}>Đăng ký</p>
                      <img src="/images/landing-page/icon-arrow.svg" alt="" />
                    </button>
                  </Link>
                  <div className='is-flex is-align-items-center is-flex-gap-4 has-text-kurio-main-color-black'>
                    <p style={{fontSize:"1rem", lineHeight:"normal"}}>Đã có Tài khoản?</p>
                    <Link to={'signin'}>
                      <button className='is-flex is-align-items-center is-justify-content-center' style={{
                        gap: "0.5rem", boxShadow: "rgb(10, 42, 102) -2px 2px 0px 1px", width: "10.20394rem", height: "3.5rem", borderRadius: "1rem", cursor: "pointer", border: "1px solid #0A2A66", background: colorWhite
                      }}
                        type="" > <p style={{ color: colorBlack, fontSize: "1.25rem", fontWeight: 700, textTransform: "uppercase" }}>đăng nhập</p></button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section id='main-landingpage' style={{ background: colorWhite, width: "100%" }}>
            <main className='is-flex is-flex-direction-column is-justify-content-center is-align-items-center' style={{ gap: "4rem", paddingBottom: "8rem" }}>
              {ListMain && ListMain.map((item, index) => {
                return (
                  item.id % 2 === 0 ? (
                    <div key={index} className='is-flex is-justify-content-center is-align-items-center' style={{ gap: "5rem" }}>
                      <div>
                        <img style={{
                          // width: '25rem',
                          height: "17.05356rem"
                        }} src={item.image} alt="" />
                      </div>

                      <div className='is-flex is-flex-direction-column is-justify-content-center is-align-items-flex-start' style={{ gap: "1.5rem" }}>
                        <p style={{ width: item.id === 2 ? "11.875rem" : "15rem", color: colorTextH1, fontSize: "2rem", fontWeight: 700, lineHeight: "normal" }}>{item.title}</p>

                        <span style={{ width: "25rem", color: colorBlack, fontSize: "1.25rem", fontWeight: 500, lineHeight: "normal" }}>{item.document}</span>
                      </div>
                    </div>
                  ) : (
                    <div key={index} className='is-flex is-justify-content-center is-align-items-center' style={{ gap: "5rem" }}>
                      <div className='is-flex is-flex-direction-column is-justify-content-center is-align-items-flex-start' style={{ gap: "1.5rem" }}>
                        <p style={{ width: item.id === 1 ? "19.5rem" : item.id === 3 ? "10.8rem" : "18rem", color: colorTextH1, fontSize: "2rem", fontWeight: 700, lineHeight: "normal" }}>{item.title}</p>

                        <span style={{ width: "25rem", color: colorBlack, fontSize: "1.25rem", fontWeight: 500, lineHeight: "normal" }}>{item.document}</span>
                      </div>
                      <div>
                        <img style={{
                          // width: '25rem',
                          height: "17.05356rem"
                        }} src={item.image} alt="" />
                      </div>
                    </div>
                  )
                )
              })}

            </main>
          </section>

          <section id='banner-sp-landingpage' style={{ background: "#E8E3F8", width: "100%" }}>
            <main className='is-flex is-flex-direction-column is-justify-content-center is-align-items-center'>
              <div className=' is-relative is-flex is-flex-direction-column is-align-items-center' style={{ gap: "1.5rem", paddingTop: "5rem" }}>
                <p style={{ width: "41.875rem", color: colorTextH1, textAlign: "center", fontSize: "3rem", fontWeight: 700, lineHeight: "normal" }}>Vì mọi học sinh đều có thể
                  yêu Toán và giỏi Toán.</p>

                <Link to={'register'}>
                  <button className='is-flex is-align-items-center is-justify-content-center' style={{ zIndex: 10, gap: "0.5rem", cursor: "pointer", boxShadow: "rgb(10, 42, 102) -2px 2px 0px 1px", width: "20rem", height: "3.5rem", borderRadius: "1rem", border: "1px solid #0A2A66", background: colorTextH1 }}>
                    <p style={{ color: colorWhite, fontSize: "1.25rem", fontWeight: 700, textTransform: "uppercase", }}>Đăng ký</p>
                    <img src="/images/landing-page/icon-arrow.svg" alt="" />
                  </button>
                </Link>
              </div>

              <img style={{ marginTop: "-6rem", }} src="/images/landing-page/banner-sp.png" alt="" />
            </main>
          </section>

          <section id='footer-landingpage' style={{ background: colorWhite, width: "100%", }}>
            <footer className='is-flex is-align-items-start' style={{ gap: "4rem", padding: "4rem" }}>
              <div className='is-flex is-flex-direction-column is-justify-content-center is-align-items-flex-start' style={{ gap: "1.5rem", width: "20rem" }}>
                <p style={{ fontWeight: 700, fontSize: "1.25rem", color: colorBlack, textTransform: "uppercase" }}>Liên hệ</p>
                <div className='is-flex is-flex-direction-column' style={{ gap: "1rem" }}>
                  <p style={{ fontWeight: 700, fontSize: "1.25rem", color: colorBlack, textTransform: "uppercase" }}>CÔNG TY CỔ PHẦN IKEMSO TOÀN CẦU</p>

                  <span style={{ fontWeight: 500, fontSize: "0.75rem", color: colorBlack, lineHeight: "1.125rem" }}>Giấy chứng nhận Đăng ký Kinh doanh số 0109501325 do Sở Kế hoạch và Đầu tư Thành phố Hà Nội cấp ngày 19/01/2021.</span>
                </div>

                <div className='is-flex is-flex-direction-column is-align-items-flex-start' style={{ gap: "1rem" }}>
                  <div className='is-flex is-flex-direction-row is-align-items-center' style={{ gap: "1rem" }}>
                    <img src="/images/landing-page/icon-gmail.svg" alt="" />
                    <p style={{ fontWeight: 500, fontSize: "1rem", color: colorBlack, lineHeight: "normal" }}>contact@ikemso.com</p>
                  </div>
                  <div className='is-flex is-flex-direction-row is-align-items-center' style={{ gap: "1rem" }}>
                    <img src="/images/landing-page/icon-phone.svg" alt="" />
                    <p style={{ fontWeight: 700, fontSize: "1rem", color: colorBlack, lineHeight: "normal" }}>1900 8157</p>
                  </div>
                  <div className='is-flex is-flex-direction-row is-align-items-center' style={{ gap: "1rem" }}>
                    <img src="/images/landing-page/icon-zalo.png" alt="" />
                    <p style={{ fontWeight: 700, fontSize: "1rem", color: colorBlack, lineHeight: "normal" }}>0971 500 120</p>
                  </div>
                  <div className='is-flex is-flex-direction-row is-align-items-center' style={{ gap: "1rem" }}>
                    <img src="/images/landing-page/icon-address.svg" alt="" />
                    <p style={{ fontWeight: 500, fontSize: "1rem", color: colorBlack, lineHeight: "1.5rem" }}>Số 128, phố Nguyễn Thái Học,
                      phường Điện Biên, quận Ba Đình, Hà Nội</p>
                  </div>
                </div>
              </div>

              <div className='is-flex is-flex-direction-column is-justify-content-center is-align-items-flex-start' style={{ gap: "1.5rem" }}>
                <p style={{ fontWeight: 700, fontSize: "1.25rem", color: colorBlack, textTransform: "uppercase" }}>Chính sách</p>

                <div className='is-flex is-flex-direction-column is-align-items-flex-start' style={{ gap: "1rem" }}>
                  <Link to={'/payment-methods'}><p style={{ fontWeight: 500, fontSize: "1rem", color: colorBlack, lineHeight: "normal" }}>Hình thức thanh toán</p></Link>
                  <Link to={'/return-policy'}><p style={{ fontWeight: 500, fontSize: "1rem", color: colorBlack, lineHeight: "normal" }}>Vận chuyển - Trả hàng hoàn tiền</p></Link>
                  <Link to={'/privacy-policy'}><p style={{ fontWeight: 500, fontSize: "1rem", color: colorBlack, lineHeight: "normal" }}>Chính sách bảo mật</p></Link>
                </div>

                <Link to={'/payment-policy'}>
                  <div className='is-flex is-flex-direction-row is-align-items-center is-justify-content-center' style={{ gap: "0.75rem", width: "15rem", height: "3.5rem", borderRadius: "1rem", border: "1px solid #0A2A66", background: colorWhite, boxShadow: "rgb(10, 42, 102) -2px 2px 0px 1px", }}>
                    <img style={{ width: "6.25rem", height: "1.89581rem" }} src="/images/landing-page/image-vnpay.png" alt="" />
                    <p style={{ width: "5.125rem", fontSize: "0.875rem", fontWeight: 700, color: colorBlack, lineHeight: "normal" }}>Chính sách thanh toán</p>
                  </div>
                </Link>
              </div>

              <div className='is-flex is-flex-direction-column is-align-items-flex-start' style={{ gap: "3rem" }}>
                <div className='is-flex is-flex-direction-column is-align-items-flex-start' style={{ gap: "1.5rem" }}>
                  <p style={{ fontWeight: 700, fontSize: "1.25rem", color: colorBlack, textTransform: "uppercase" }}>Ứng dụng</p>

                  <div className='is-flex is-flex-direction-column is-align-items-flex-start' style={{ gap: "1rem" }}>
                    <Link to={'https://apps.apple.com/us/app/to%C3%A1n-kurio/id6478091573'}>
                      <p style={{ fontWeight: 500, fontSize: "1rem", color: colorBlack, lineHeight: "normal" }}>KURIO cho iOS</p></Link>
                      <Link to={'https://play.google.com/store/apps/details?id=vn.kurio.android'}>
                        <p style={{ fontWeight: 500, fontSize: "1rem", color: colorBlack, lineHeight: "normal" }}>KURIO cho Android</p>
                      </Link>
                  </div>
                </div>

                <div className='is-flex is-flex-direction-column is-align-items-flex-start' style={{ gap: "1.5rem" }}>
                  <p style={{ fontWeight: 700, fontSize: "1.25rem", color: colorBlack, textTransform: "uppercase" }}>Mạng xã hội</p>

                  <div className='is-flex is-flex-direction-column is-align-items-flex-start' style={{ gap: "1rem" }}>
                    <Link to={'https://www.facebook.com/kurio.vn'}>
                      <p style={{ fontWeight: 500, fontSize: "1rem", color: colorBlack, lineHeight: "normal" }}>Facebook</p>
                    </Link>
                    {/* <p style={{ fontWeight: 500, fontSize: "1rem", color: colorBlack, lineHeight: "normal" }}>Youtube</p> */}
                    <Link to={'https://zalo.me/0971500120'}>
                      <p style={{ fontWeight: 500, fontSize: "1rem", color: colorBlack, lineHeight: "normal" }}>Zalo</p>
                    </Link>
                  </div>
                </div>
              </div>

              <div style={{ height: "24.125rem" }} className='is-flex is-flex-direction-column is-justify-content-space-between is-align-items-center'>
                <Link to={'http://online.gov.vn/Home/WebDetails/110320?AspxAutoDetectCookieSupport=1'}>
                  <img style={{ width: "12.5rem", height: "4.75113rem" }} src="/images/landing-page/image-bct.png" alt="" />
                </Link>
                <div className='is-flex is-flex-direction-row is-align-items-center is-justify-content-center' style={{ gap: "1.5rem" }}>
                  <img onClick={() => backToTop()} style={{ width: "5rem", height: "5rem", cursor: "pointer" }} src="/images/landing-page/icon-back-to-top.svg" alt="" />

                  <p onClick={() => backToTop()} style={{ fontSize: "1.25rem", color: colorBlack, cursor: "pointer", textTransform: "uppercase", fontWeight: 700, lineHeight: "normal", width: "6rem" }}>Về đầu trang</p>
                </div>
              </div>
            </footer>
          </section>
        </div>

        {/* Tablet landing page */}
        <div className='container-landingpage has-background-kurio-main-color-white' id='tablet-landingpage'>
          <header style={{ width: "100%", height: "7.5rem", padding: "0rem 2.5rem" }} className='is-flex is-justify-content-center is-align-items-center'>
            <div style={{ height: "100%", padding: "0rem 2rem", borderRadius: "0rem 0rem 2rem 2rem", background: "#F1EEFC" }} className='logo is-flex is-justify-content-center is-align-items-center'>
              <img style={{ width: "12.36194rem", height: "4rem" }} src="/images/landing-page/logo.svg" alt="" />
            </div>
          </header>

          <section id='tablet-header' className={isHeaderFixed === null ? 'displayNone' : isHeaderFixed ? 'fixedHeader' : 'remote-fixed-header'} style={{ background: isHeaderFixed ? "#F1EEFC" : colorWhite, width: "100%" }}>
            <header style={{ width: "100%", height: "6rem", padding: "0rem 2.5rem" }} className={"is-flex is-flex-direction-row is-justify-content-space-between is-align-items-center"}>
              <img style={{ width: "9.27144rem", height: "3rem" }} src="/images/landing-page/logo.svg" alt="" />

              <Link to={'register-mobile'}>
                <button className='is-flex is-align-items-center is-justify-content-center'  style={{ gap: "0.5rem", cursor: "pointer", boxShadow: "rgb(10, 42, 102) -2px 2px 0px 1px", width: "12.6875rem", height: "3.5rem", borderRadius: "1rem", border: "1px solid #0A2A66", background: colorTextH1 }}>
                  <p style={{ color: colorWhite, fontSize: "1.25rem", fontWeight: 700, textTransform: "uppercase" }}>Đăng ký</p>
                  <img src="/images/landing-page/icon-arrow.svg" alt="" />
                </button>
              </Link>
            </header>

          </section>

          <div className='is-clickable messenger-icon has-background-kurio-main-color-white is-flex is-justify-content-center is-align-items-center' 
            style={{
              position:"fixed", 
              bottom:"0rem", 
              right:"0rem",
            }}
          >
              <img onClick={() => window.open('https://m.me/kurio.vn')} src="/images/landing-page/logo-messenger.png" alt="" style={{width:"5rem", height:"5rem"}} />
          </div>

          <section id='tablet-banner' style={{ background: colorWhite, width: "100%" }}>
            <div style={{ padding: "4rem 5rem", gap: "5rem" }}
              className='is-flex is-flex-direction-column is-justify-content-center is-align-items-center'>
              <img style={{ width: "26.53631rem", height: "25rem" }} src="/images/landing-page/banner.png" alt="" />

              <div className='is-flex is-flex-direction-column is-justify-content-center is-align-items-center' style={{ gap: "1.5rem" }}>
                <p style={{ fontSize: "2.5rem", fontWeight: 700, lineHeight: "normal", textAlign: "center", color: colorTextH1 }}>Học Toán không khó <br />
                  Đã có <b style={{ color: "#8864F0" }}>Kurio</b> lo</p>

                <div style={{ gap: "1.5rem" }} className='is-flex is-flex-direction-column is-align-items-flex-start'>
                  <Link to={'register-mobile'}>
                    <button className='is-flex is-align-items-center is-justify-content-center' style={{ gap: "0.5rem", cursor: "pointer", boxShadow: "rgb(10, 42, 102) -2px 2px 0px 1px", width: "20rem", height: "3.5rem", borderRadius: "1rem", border: "1px solid #0A2A66", background: colorTextH1 }}>
                      <p style={{ color: colorWhite, fontSize: "1.25rem", fontWeight: 700, textTransform: "uppercase" }}>Đăng ký</p>
                      <img src="/images/landing-page/icon-arrow.svg" alt="" />
                    </button>
                  </Link>
                  <div className='is-flex is-align-items-center is-flex-gap-4 has-text-kurio-main-color-black'>
                    <p style={{fontSize:"1rem", lineHeight:"normal"}}>Đã có Tài khoản?</p>
                      <button className='is-flex is-align-items-center is-justify-content-center' onClick={handleDownloadApp} style={{
                        gap: "0.5rem", boxShadow: "rgb(10, 42, 102) -2px 2px 0px 1px", width: "10.20394rem", height: "3.5rem", borderRadius: "1rem", cursor: "pointer", border: "1px solid #0A2A66", background: colorWhite
                      }}
                        type="" > <p style={{ color: colorBlack, fontSize: "1.25rem", fontWeight: 700, textTransform: "uppercase" }}>tải app</p></button>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section id='tablet-main' style={{ background: colorWhite, width: "100%" }}>
            <main className='is-flex is-flex-direction-column is-justify-content-center is-align-items-center' style={{ gap: "4rem", paddingBottom: "8rem" }}>
              {ListMain && ListMain.map((item, index) => {
                return (
                  item.id % 2 === 0 ? (
                    <div key={index} className='is-flex is-justify-content-center is-align-items-center' style={{ gap: "2rem" }}>
                      <div>
                        <img style={{
                        }} src={item.imageTablet} alt="" />
                      </div>

                      <div className='is-flex is-flex-direction-column is-justify-content-center is-align-items-flex-start' style={{ gap: "1rem" }}>
                        <p style={{ width: item.id === 2 ? "11.875rem" : "15rem", color: colorTextH1, fontSize: "1.5rem", fontWeight: 700, lineHeight: "normal" }}>{item.title}</p>

                        <span style={{ width: "15rem", color: colorBlack, fontSize: "1rem", fontWeight: 500, lineHeight: "normal" }}>{item.document}</span>
                      </div>
                    </div>
                  ) : (
                    <div key={index} className='is-flex is-justify-content-center is-align-items-center' style={{ gap: "2rem" }}>
                      <div className='is-flex is-flex-direction-column is-justify-content-center is-align-items-flex-start' style={{ gap: "1rem" }}>
                        <p style={{ width: item.id === 1 ? "9rem" : item.id === 3 ? "10.8rem" : "16rem", color: colorTextH1, fontSize: "1.5rem", fontWeight: 700, lineHeight: "normal" }}>{item.title}</p>

                        <span style={{ width: "15rem", color: colorBlack, fontSize: "1rem", fontWeight: 500, lineHeight: "normal" }}>{item.document}</span>
                      </div>
                      <div>
                        <img style={{
                          width: '25rem',
                        }} src={item.imageTablet} alt="" />
                      </div>
                    </div>
                  )
                )
              })}

            </main>
          </section>

          <section id='tablet-banner' style={{ background: "#E8E3F8", width: "100%" }}>
            <main className='is-flex is-flex-direction-column is-justify-content-center is-align-items-center'>
              <div className='is-relative is-flex is-flex-direction-column is-align-items-center' style={{ gap: "1.5rem", paddingTop: "5rem" }}>
                <p style={{ width: "41.875rem", color: colorTextH1, textAlign: "center", fontSize: "3rem", fontWeight: 700, lineHeight: "normal" }}>Vì mọi học sinh đều có thể
                  yêu Toán và giỏi Toán.</p>

                <Link to={'register-mobile'}>
                  <button className='is-flex is-align-items-center is-justify-content-center'  style={{ zIndex: 10, gap: "0.5rem", cursor: "pointer", boxShadow: "rgb(10, 42, 102) -2px 2px 0px 1px", width: "20rem", height: "3.5rem", borderRadius: "1rem", border: "1px solid #0A2A66", background: colorTextH1 }}>
                    <p style={{ color: colorWhite, fontSize: "1.25rem", fontWeight: 700, textTransform: "uppercase", }}>Đăng ký</p>
                    <img src="/images/landing-page/icon-arrow.svg" alt="" />
                  </button>
                </Link>
              </div>

              <img style={{ marginTop: "-3rem", }} src="/images/landing-page/banner-sp.png" alt="" />
            </main>
          </section>

          <section id='tablet-footer' style={{ background: colorWhite, width: "100%", }}>
            <footer className='is-flex is-flex-direction-column is-align-items-start' style={{ gap: "4rem", padding: "4rem" }}>
              <div className='is-flex is-flex-direction-column is-justify-content-center is-align-items-flex-start' style={{ gap: "1.5rem" }}>
                <p style={{ fontWeight: 700, fontSize: "1.25rem", color: colorBlack, textTransform: "uppercase" }}>Liên hệ</p>
                <div className='is-flex is-flex-direction-column' style={{ gap: "1rem" }}>
                  <p style={{ fontWeight: 700, fontSize: "1.25rem", color: colorBlack, textTransform: "uppercase" }}>CÔNG TY CỔ PHẦN IKEMSO TOÀN CẦU</p>

                  <span style={{ fontWeight: 500, fontSize: "0.75rem", color: colorBlack, lineHeight: "1.125rem" }}>Giấy chứng nhận Đăng ký Kinh doanh số 0109501325 do Sở Kế hoạch và Đầu tư Thành phố Hà Nội cấp ngày 19/01/2021.</span>
                </div>

                <div className='is-flex is-flex-direction-column is-align-items-flex-start' style={{ gap: "1rem" }}>
                  <div className='is-flex is-flex-direction-row is-align-items-center' style={{ gap: "1rem" }}>
                    <img src="/images/landing-page/icon-gmail.svg" alt="" />
                    <p style={{ fontWeight: 500, fontSize: "1rem", color: colorBlack, lineHeight: "normal" }}>contact@ikemso.com</p>
                  </div>
                  <div className='is-flex is-flex-direction-row is-align-items-center' style={{ gap: "1rem" }}>
                    <img src="/images/landing-page/icon-phone.svg" alt="" />
                    <p style={{ fontWeight: 700, fontSize: "1rem", color: colorBlack, lineHeight: "normal" }}>1900 8157</p>
                  </div>
                  <div className='is-flex is-flex-direction-row is-align-items-center' style={{ gap: "1rem" }}>
                    <img src="/images/landing-page/icon-zalo.png" alt="" />
                    <p style={{ fontWeight: 700, fontSize: "1rem", color: colorBlack, lineHeight: "normal" }}>0971 500 120</p>
                  </div>
                  <div className='is-flex is-flex-direction-row is-align-items-flex-start' style={{ gap: "1rem" }}>
                    <img src="/images/landing-page/icon-address.svg" alt="" />
                    <p style={{ fontWeight: 500, fontSize: "1rem", color: colorBlack, lineHeight: "1.5rem" }}>Số 128, phố Nguyễn Thái Học, <br />
                      phường Điện Biên, quận Ba Đình, Hà Nội</p>
                  </div>
                </div>
              </div>

              <div className='is-flex is-flex-direction-row is-justify-content-center is-align-items-flex-start' style={{ gap: "4rem" }}>
                <div className='is-flex is-flex-direction-column is-justify-content-center is-align-items-flex-start' style={{ gap: "1.5rem" }}>
                  <p style={{ fontWeight: 700, fontSize: "1.25rem", color: colorBlack, textTransform: "uppercase" }}>Chính sách</p>

                  <div className='is-flex is-flex-direction-column is-align-items-flex-start' style={{ gap: "1rem" }}>
                    <Link to={'/payment-methods'}>
                      <p style={{ fontWeight: 500, fontSize: "1rem", color: colorBlack, lineHeight: "normal" }}>Hình thức thanh toán</p>
                    </Link>
                    <Link to={'/return-policy'}><p style={{ fontWeight: 500, fontSize: "1rem", color: colorBlack, lineHeight: "normal" }}>Vận chuyển - Trả hàng hoàn tiền</p></Link>
                    <Link to={'/privacy-policy'}><p style={{ fontWeight: 500, fontSize: "1rem", color: colorBlack, lineHeight: "normal" }}>Chính sách bảo mật</p></Link>
                  </div>

                  <Link to={'/payment-policy'}>
                    <div className='is-flex is-flex-direction-row is-align-items-center is-justify-content-center' style={{ gap: "0.75rem", width: "15rem", height: "3.5rem", borderRadius: "1rem", border: "1px solid #0A2A66", background: colorWhite, boxShadow: "rgb(10, 42, 102) -2px 2px 0px 1px", }}>
                      <img style={{ width: "6.25rem", height: "1.89581rem" }} src="/images/landing-page/image-vnpay.png" alt="" />
                      <p style={{ width: "5.125rem", fontSize: "0.875rem", fontWeight: 700, color: colorBlack, lineHeight: "normal" }}>Chính sách thanh toán</p>
                    </div>
                  </Link>
                </div>

                <div className='is-flex is-flex-direction-column is-align-items-flex-start' style={{ gap: "3rem" }}>
                  <div className='is-flex is-flex-direction-column is-align-items-flex-start' style={{ gap: "1.5rem" }}>
                    <p style={{ fontWeight: 700, fontSize: "1.25rem", color: colorBlack, textTransform: "uppercase" }}>Ứng dụng</p>

                    <div className='is-flex is-flex-direction-column is-align-items-flex-start' style={{ gap: "1rem" }}>
                      <Link to={'https://apps.apple.com/us/app/to%C3%A1n-kurio/id6478091573'}>
                        <p style={{ fontWeight: 500, fontSize: "1rem", color: colorBlack, lineHeight: "normal" }}>KURIO cho iOS</p></Link>
                        <Link to={'https://play.google.com/store/apps/details?id=vn.kurio.android'}><p style={{ fontWeight: 500, fontSize: "1rem", color: colorBlack, lineHeight: "normal" }}>KURIO cho Android</p></Link>
                    </div>
                  </div>

                  <div className='is-flex is-flex-direction-column is-align-items-flex-start' style={{ gap: "1.5rem" }}>
                    <p style={{ fontWeight: 700, fontSize: "1.25rem", color: colorBlack, textTransform: "uppercase" }}>Mạng xã hội</p>

                    <div className='is-flex is-flex-direction-column is-align-items-flex-start' style={{ gap: "1rem" }}>
                      <Link to={'https://www.facebook.com/kurio.vn'}>
                        <p style={{ fontWeight: 500, fontSize: "1rem", color: colorBlack, lineHeight: "normal" }}>Facebook</p>
                      </Link>
                      {/* <p style={{ fontWeight: 500, fontSize: "1rem", color: colorBlack, lineHeight: "normal" }}>Youtube</p> */}
                    <Link to={'https://zalo.me/0971500120'}>
                      <p style={{ fontWeight: 500, fontSize: "1rem", color: colorBlack, lineHeight: "normal" }}>Zalo</p>
                    </Link>
                    </div>
                  </div>
                </div>
              </div>

              <div style={{ width: "100%" }} className='is-flex is-flex-direction-row is-justify-content-space-between is-align-items-center'>
                <Link to={'http://online.gov.vn/Home/WebDetails/110320?AspxAutoDetectCookieSupport=1'}>
                  <img style={{ width: "12.5rem", height: "4.75113rem" }} src="/images/landing-page/image-bct.png" alt="" />
                </Link>
                <div className='is-flex is-flex-direction-row is-align-items-center is-justify-content-center' style={{ gap: "1.5rem" }}>
                  <img onClick={() => backToTop()} style={{ width: "5rem", height: "5rem", cursor: "pointer" }} src="/images/landing-page/icon-back-to-top.svg" alt="" />

                  <p onClick={() => backToTop()} style={{ fontSize: "1.25rem", color: colorBlack, cursor: "pointer", textTransform: "uppercase", fontWeight: 700, lineHeight: "normal", width: "6rem" }}>Về đầu trang</p>
                </div>
              </div>
            </footer>
          </section>
        </div>

        {/* Mobile landing page */}
        <div className='container-landingpage has-background-kurio-main-color-white' id='mobile-landingpage'>
          {showPopupDownload && (
            <Fragment>
              <div style={{ position: "fixed", bottom: "0", width: "100%",zIndex:999 }}>
                <div className='is-flex is-justify-content-flex-end' style={{width:"100%"}}>
                <div className='is-clickable messenger-icon is-flex is-justify-content-center is-align-items-center' 
                  style={{
                    width: "5rem",
                    height: "5rem",
                    padding: "6/16rem",
                  }}
                >
                    <img onClick={() => window.open('https://m.me/kurio.vn')} src="/images/landing-page/logo-messenger.svg" alt="" style={{width:"3rem", height:"3rem"}} />
                </div>
                </div>
                <div id='bottom-popup' className='is-relative' style={{ width: "100%", display: "flex", justifyContent: "center" }}>
                  <span className='icon-cancel is-flex is-justify-content-center is-align-items-center' onClick={handleClosePopupDownload} style={{ position: "absolute",zIndex:2, right: "1rem", top: "15%", width: "1.5rem", height: "1.5rem", borderRadius: "1rem 1rem 0rem 0rem", background: "#E8E3F8" }}>
                    <img src="/images/landing-page/icon-cancel.svg" alt="" />
                  </span>
                  <div style={{ position: "relative", maxWidth: "100%", width: "100%", bottom: "-0.7rem" }}>
                    <img style={{ width: "100%" }} src="/images/landing-page/image-mobile-popup.svg" alt="" />
                    <button className='btn-mobile-popup is-flex is-align-items-center is-justify-content-center' onClick={handleDownloadApp} style={{ position: "absolute",zIndex:1, top: "60%", right: "5%", transform: "translateY(-50%)", gap: "0.5rem", cursor: "pointer", boxShadow: "rgb(10, 42, 102) -2px 2px 0px 1px", width: "11.5rem", height: "2.5rem", borderRadius: "1rem", border: "1px solid #0A2A66", background: colorTextH1 }}>
                      <img src="/images/landing-page/icon-download.svg" alt="" />
                      <p style={{ color: colorWhite, fontSize: "1rem", fontWeight: 700, textTransform: "uppercase" }}>tải app ngay</p>
                    </button>
                  </div>
                </div>
              </div>
            </Fragment>
          )}
          {!showPopupDownload &&(
            <div className='is-clickable messenger-icon  is-flex is-justify-content-center is-align-items-center' 
            style={{
              position:"fixed", 
              bottom:"0rem", 
              right:"0rem",
            }}
            >
                <img onClick={() => window.open('https://m.me/kurio.vn')} src="/images/landing-page/logo-messenger.png" alt="" style={{width:"5rem", height:"5rem"}} />
            </div>
          )}

          <header className='is-flex is-justify-content-center is-align-items-center' style={{ width: "100%", height: "5rem", padding: "0rem 1rem" }}>
            <div className='is-flex is-justify-content-center is-align-items-center' style={{ padding: "0rem 1.5rem", borderRadius: "0rem 0rem 1rem 1rem", background: "#F1EEFC", height: "100%" }}>
              <img style={{ width: "9.72144rem", height: "3rem" }} src="/images/landing-page/logo.svg" alt="" />
            </div>
          </header>

          <section id='mobile-header' className={isHeaderFixedMobile === null ? 'displayNone' : isHeaderFixedMobile ? 'fixedHeader' : 'remote-fixed-header'} style={{ background: isHeaderFixedMobile ? "#F1EEFC" : colorWhite, width: "100%" }}>
            <header style={{ width: "100%", height: "5rem", padding: "0rem 1rem" }} className='is-flex is-flex-direction-row is-justify-content-space-between is-align-items-center'>
              <img style={{ width: "2.63394rem", height: "3rem" }} src="/images/landing-page/logo-mobile.svg" alt="" />

              <Link to={'register-mobile'}>
                <button className='is-flex is-align-items-center is-justify-content-center' style={{ gap: "0.5rem", cursor: "pointer", boxShadow: "rgb(10, 42, 102) -2px 2px 0px 1px", width: "10rem", height: "2.5rem", borderRadius: "1rem", border: "1px solid #0A2A66", background: colorTextH1 }}>
                  <p style={{ color: colorWhite, fontSize: "1rem", fontWeight: 700, textTransform: "uppercase" }}>Đăng ký</p>
                  <img src="/images/landing-page/icon-arrow.svg" alt="" />
                </button>
              </Link>
            </header>
          </section>

          <section ref={scrollableDivRef} id='mobile-banner' style={{ background: colorWhite, width: "100%" }}>
            <div className='is-flex is-flex-direction-column is-justify-content-center is-align-items-center' style={{ height: "35rem", gap: "1.5rem" }}>
              <img style={{ width: "17.5rem", height: "16.48681rem" }} src="/images/landing-page/banner.png" alt="" />

              <div className='is-flex is-flex-direction-column is-justify-content-center is-align-items-center' style={{ gap: "1.5rem" }}>
                <p style={{ fontSize: "1.5rem", fontWeight: 700, lineHeight: "normal", textAlign: "center", color: colorTextH1 }}>Học Toán không khó <br />
                  Đã có <b style={{ color: "#8864F0" }}>Kurio</b> lo</p>

                <div style={{ gap: "1rem" }} className='is-flex is-flex-direction-column is-align-items-flex-start'>
                  <Link to={'register-mobile'}>
                    <button className='is-flex is-align-items-center is-justify-content-center'  style={{ gap: "0.5rem", cursor: "pointer", boxShadow: "rgb(10, 42, 102) -2px 2px 0px 1px", width: "15rem", height: "3.5rem", borderRadius: "1rem", border: "1px solid #0A2A66", background: colorTextH1 }}>
                      <p style={{ color: colorWhite, fontSize: "1rem", fontWeight: 700, textTransform: "uppercase" }}>Đăng ký</p>
                      <img src="/images/landing-page/icon-arrow.svg" alt="" />
                    </button>
                  </Link>
                  <div className='is-flex is-align-items-center is-justify-content-space-between has-text-kurio-main-color-black' style={{width:"15.125rem"}}>
                    <p className='has-text-centered' style={{lineHeight:"normal", fontSize:"0.75rem",width:"4.125rem"}}>Bạn đã có
                      Tài khoản?</p>
                    {/* <Link to={'signin'}>
                    </Link> */}
                      <button className='is-flex is-align-items-center is-justify-content-center' onClick={handleDownloadApp} style={{
                        gap: "0.5rem", boxShadow: "rgb(10, 42, 102) -2px 2px 0px 1px", width: "9.5rem", height: "3.5rem", borderRadius: "1rem", cursor: "pointer", border: "1px solid #0A2A66", background: colorWhite
                      }}
                        type="" > <p style={{ color: colorBlack, fontSize: "1rem", fontWeight: 700, textTransform: "uppercase" }}>tải app</p></button>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section id='mobile-main' style={{ background: colorWhite, width: "100%" }}>
            <main className='is-flex is-flex-direction-column is-align-items-center' style={{ gap: "3rem", padding: "3rem 0rem" }}>
              {ListMain && ListMain.map((item, index) => {
                return (
                  <div key={index} className='is-flex is-flex-direction-column is-justify-content-center is-align-items-center' style={{ gap: "3rem" }}>
                    <div className='is-flex is-flex-direction-column is-justify-content-center is-align-items-center' style={{ gap: "0.75rem" }}>
                      <p style={{ width: item.id === 2 ? "11.875rem" : "15rem", textAlign: "center", color: colorTextH1, fontSize: "1.5rem", fontWeight: 700, lineHeight: "normal" }}>{item.title}</p>

                      <span style={{ width: "17.5rem", color: colorBlack, fontSize: "0.75rem", fontWeight: 500, lineHeight: "normal", textAlign: "center" }}>{item.document}</span>
                    </div>

                    <div>
                      <img style={{
                        width: '17.5rem',
                        height: index === 4 ?"11.125rem": "14.9375rem"
                      }} src={item.imageMobile} alt="" />
                    </div>


                  </div>

                )
              })}
            </main>
          </section>

          <section id='mobile-banner-sp' style={{ background: "#E8E3F8", width: "100%" }}>
            <main className='is-flex is-flex-direction-column is-justify-content-center is-align-items-center'>
              <div className='is-flex is-flex-direction-column is-align-items-center' style={{ gap: "1.5rem", paddingTop: "4rem" }}>
                <p style={{ width: "17.5rem", color: colorTextH1, textAlign: "center", fontSize: "1.5rem", fontWeight: 700, lineHeight: "normal" }}>Vì mọi học sinh đều có thể
                  yêu Toán và giỏi Toán.</p>

                <Link to={'register-mobile'}>
                  <button className='is-flex is-align-items-center is-justify-content-center' style={{ zIndex: 10, gap: "0.5rem", cursor: "pointer", boxShadow: "rgb(10, 42, 102) -2px 2px 0px 1px", width: "15rem", height: "3.5rem", borderRadius: "1rem", border: "1px solid #0A2A66", background: colorTextH1 }}>
                    <p style={{ color: colorWhite, fontSize: "1rem", fontWeight: 700, textTransform: "uppercase", }}>Đăng ký</p>
                    <img src="/images/landing-page/icon-arrow.svg" alt="" />
                  </button>
                </Link>
              </div>

              <img style={{ marginTop: "-1rem", }} src="/images/landing-page/banner-sp.png" alt="" />
            </main>
          </section>

          <section id='mobile-footer' style={{ background: colorWhite, width: "100%", marginBottom: "10%" }}>
            <footer className='is-flex is-flex-direction-column is-align-items-start' style={{ gap: "3rem", padding: "4rem 2rem" }}>
              <div className='is-flex is-flex-direction-column is-justify-content-center is-align-items-flex-start' style={{ gap: "1rem" }}>
                <p style={{ fontWeight: 700, fontSize: "1rem", color: colorBlack, textTransform: "uppercase" }}>Liên hệ</p>
                <div className='is-flex is-flex-direction-column' style={{ gap: "0.5rem" }}>
                  <p style={{ fontWeight: 700, fontSize: "1rem", color: colorBlack, textTransform: "uppercase" }}>CÔNG TY CỔ PHẦN IKEMSO TOÀN CẦU</p>

                  <span style={{ fontWeight: 500, fontSize: "0.75rem", color: colorBlack, lineHeight: "1.125rem" }}>Giấy chứng nhận Đăng ký Kinh doanh số 0109501325 do Sở Kế hoạch và Đầu tư Thành phố Hà Nội cấp ngày 19/01/2021.</span>
                </div>

                <div className='is-flex is-flex-direction-column is-align-items-flex-start' style={{ gap: "0.75rem" }}>
                  <div className='is-flex is-flex-direction-row is-align-items-center' style={{ gap: "1rem" }}>
                    <img src="/images/landing-page/icon-gmail.svg" alt="" />
                    <p style={{ fontWeight: 500, fontSize: "0.75rem", color: colorBlack, lineHeight: "normal" }}>contact@ikemso.com</p>
                  </div>
                  <div className='is-flex is-flex-direction-row is-align-items-center' style={{ gap: "1rem" }}>
                    <img src="/images/landing-page/icon-phone.svg" alt="" />
                    <p style={{ fontWeight: 700, fontSize: "0.75rem", color: colorBlack, lineHeight: "normal" }}>1900 8157</p>
                  </div>
                  <div className='is-flex is-flex-direction-row is-align-items-center' style={{ gap: "1rem" }}>
                    <img src="/images/landing-page/icon-zalo.png" alt="" />
                    <p style={{ fontWeight: 700, fontSize: "0.75rem", color: colorBlack, lineHeight: "normal" }}>0971 500 120</p>
                  </div>
                  <div className='is-flex is-flex-direction-row is-align-items-center' style={{ gap: "1rem" }}>
                    <img src="/images/landing-page/icon-address.svg" alt="" />
                    <p style={{ fontWeight: 500, fontSize: "0.75rem", color: colorBlack, lineHeight: "1.125rem" }}>Số 128, phố Nguyễn Thái Học,
                      phường Điện Biên, quận Ba Đình, Hà Nội</p>
                  </div>
                </div>
              </div>

              <div className='is-flex is-flex-direction-column is-justify-content-center is-align-items-flex-start' style={{ gap: "1rem" }}>
                <p style={{ fontWeight: 700, fontSize: "1rem", color: colorBlack, textTransform: "uppercase" }}>Chính sách</p>

                <div className='is-flex is-flex-direction-column is-align-items-flex-start' style={{ gap: "0.5rem" }}>
                  <Link to={'/payment-methods'}><p style={{ fontWeight: 500, fontSize: "0.75rem", color: colorBlack, lineHeight: "normal" }}>Hình thức thanh toán</p></Link>
                  <Link to={'/return-policy'}><p style={{ fontWeight: 500, fontSize: "0.75rem", color: colorBlack, lineHeight: "normal" }}>Vận chuyển - Trả hàng hoàn tiền</p></Link>
                  <Link to={'/privacy-policy'}><p style={{ fontWeight: 500, fontSize: "0.75rem", color: colorBlack, lineHeight: "normal" }}>Chính sách bảo mật</p></Link>
                </div>

                <Link to={'/payment-policy'}>
                  <div className='is-flex is-flex-direction-row is-align-items-center is-justify-content-center' style={{ gap: "0.75rem", width: "15rem", height: "3.5rem", borderRadius: "1rem", border: "1px solid #0A2A66", background: colorWhite, boxShadow: "rgb(10, 42, 102) -2px 2px 0px 1px", }}>
                    <img style={{ width: "6.25rem", height: "1.89581rem" }} src="/images/landing-page/image-vnpay.png" alt="" />
                    <p style={{ width: "5.125rem", fontSize: "0.875rem", fontWeight: 700, color: colorBlack, lineHeight: "normal" }}>Chính sách thanh toán</p>
                  </div>
                </Link>
              </div>

              <div className='is-flex is-flex-direction-column is-align-items-flex-start' style={{ gap: "3rem" }}>
                <div className='is-flex is-flex-direction-column is-align-items-flex-start' style={{ gap: "1rem" }}>
                  <p style={{ fontWeight: 700, fontSize: "1rem", color: colorBlack, textTransform: "uppercase" }}>Ứng dụng</p>

                  <div className='is-flex is-flex-direction-column is-align-items-flex-start' style={{ gap: "0.5rem" }}>
                    <Link to={'https://apps.apple.com/us/app/to%C3%A1n-kurio/id6478091573'}>
                      <p style={{ fontWeight: 500, fontSize: "0.75rem", color: colorBlack, lineHeight: "normal" }}>KURIO cho iOS</p></Link>
                      <Link to={'https://play.google.com/store/apps/details?id=vn.kurio.android'}>
                    <p style={{ fontWeight: 500, fontSize: "0.75rem", color: colorBlack, lineHeight: "normal" }}>KURIO cho Android</p></Link>
                  </div>
                </div>

                <div className='is-flex is-flex-direction-column is-align-items-flex-start' style={{ gap: "1rem" }}>
                  <p style={{ fontWeight: 700, fontSize: "1rem", color: colorBlack, textTransform: "uppercase" }}>Mạng xã hội</p>

                  <div className='is-flex is-flex-direction-column is-align-items-flex-start' style={{ gap: "0.5rem" }}>
                    <Link to={'https://www.facebook.com/kurio.vn'}>
                      <p style={{ fontWeight: 500, fontSize: "0.75rem", color: colorBlack, lineHeight: "normal" }}>Facebook</p>
                    </Link>
                    {/* <p style={{ fontWeight: 500, fontSize: "0.75rem", color: colorBlack, lineHeight: "normal" }}>Youtube</p> */}
                    <Link to={'https://zalo.me/0971500120'}>
                      <p style={{ fontWeight: 500, fontSize: "0.75rem", color: colorBlack, lineHeight: "normal" }}>Zalo</p>
                    </Link>
                  </div>
                </div>
              </div>

              <div className='is-flex is-flex-direction-row is-align-items-center' style={{ gap: "4rem" }}>
                <Link to={'http://online.gov.vn/Home/WebDetails/110320?AspxAutoDetectCookieSupport=1'}>
                  <img style={{ width: "6.90625rem", height: "2.625rem" }} src="/images/landing-page/image-bct.png" alt="" />
                </Link>
                <div className='is-flex is-flex-direction-row is-align-items-center is-justify-content-center' style={{ gap: "1rem" }}>
                  <img onClick={() => backToTop()} style={{ zIndex: 2, width: "2.5rem", height: "2.5rem", cursor: "pointer" }} src="/images/landing-page/icon-back-to-top.svg" alt="" />

                  <p onClick={() => backToTop()} style={{ zIndex: 2, fontSize: "0.875rem", color: colorBlack, cursor: "pointer", textTransform: "uppercase", fontWeight: 700, lineHeight: "normal", width: "3.75rem" }}>Về đầu trang</p>
                </div>
              </div>
            </footer>
          </section>
        </div>
      </div >
    </>
  )
}

export default LandingPage