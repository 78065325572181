import React from "react";
import { useNavigate } from "react-router-dom";
import PrimaryButtonLevelSection from "../../components/button/primary-button/PrimaryButtonLevelSection";
import BackgroundImageFromUrl from "../../components/parseImage/BackgroundImageFromUrl";
import ImageFromUrl from "../../components/parseImage/ImageFromUrl";
import ScrollRefWrapper from "../../detail-components/ScrollRefWrapper";

const ExamPractice = () => {
  const navigate = useNavigate();
  return (
    <div
      className="is-flex is-flex-direction-column is-align-items-center is-justify-content-center is-relative"
      style={{ minHeight: "100vh", top: "-5.25rem" }}
    >
      <ScrollRefWrapper />
      <div className="is-flex is-flex-direction-column is-align-items-center is-flex-gap-5 ">
        <p
          className="has-text-weight-bold has-text-kurio-main-color-black"
          style={{
            fontSize: "2rem",
            lineHeight: "normal",
            fontStyle: "normal",
          }}
        >
          Luyện thi
        </p>

        <div className=" is-flex is-flex-gap-6 is-align-items-flex-start is-justify-content-center">
          <BackgroundImageFromUrl
            objectId={
              "admin/frontend-images/exam-practice/bg-exam-practice-ikmc.png"
            }
            className="is-flex is-flex-direction-column is-justify-content-center is-align-items-center"
            style={{
              border: "0.09375rem solid #0A2A66",
              borderRadius: "1rem",
              width: "25rem",
              height: "25rem",
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
              boxShadow: "0.125rem 0.25rem 0rem 0rem #0A2A66",
            }}
          >
            <div
              className="is-flex is-flex-direction-column is-flex-gap-4 is-align-items-center is-justify-content-center"
              style={{ width: "20.5rem" }}
            >
              <img
                style={{
                  width: "7.5rem",
                  height: "7.5rem",
                  objectFit: "cover",
                }}
                src="/images/practice-ikmc/menu-ikmc-left-icon.svg"
                alt=""
              />
              <p
                className="has-text-weight-normal has-text-centered has-text-kurio-main-color-white"
                style={{
                  fontSize: "2rem",
                  fontFamily: "Dela Gothic One",
                  fontStyle: "normal",
                  lineHeight: "110%",
                }}
              >
                Luyện thi IKMC
              </p>
              <p
                className="has-text-centered has-text-centered has-text-kurio-main-color-white"
                style={{
                  fontSize: "1rem",
                  fontStyle: "normal",
                  lineHeight: "normal",
                }}
              >
                Trở thành phiên bản tốt nhất của bạn trong kỳ thi IKMC sắp tới!
              </p>
              <PrimaryButtonLevelSection
                onClick={() => navigate("/practice-ikmc")}
                isDarkColor={true}
              >
                khám phá
              </PrimaryButtonLevelSection>
            </div>
          </BackgroundImageFromUrl>
          <BackgroundImageFromUrl
            objectId={
              "admin/frontend-images/exam-practice/bg-exam-practice-semester.png"
            }
            className="is-flex is-align-items-center is-justify-content-center has-text-kurio-main-color-purple"
            style={{
              border: "0.09375rem solid #0A2A66",
              borderRadius: "1rem",
              width: "25rem",
              height: "25rem",
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
              boxShadow: "0.125rem 0.25rem 0rem 0rem #0A2A66",
            }}
          >
            <div
              className="is-flex is-flex-direction-column is-flex-gap-4 is-align-items-center is-justify-content-center"
              style={{ width: "20.5rem" }}
            >
              <ImageFromUrl
                style={{
                  width: "7.5rem",
                  height: "7.5rem",
                  objectFit: "cover",
                }}
                objectId="admin/frontend-images/auth/image-left-signup.svg"
                alt=""
              />
              <p
                className="has-text-weight-normal has-text-centered"
                style={{
                  fontSize: "2rem",
                  fontFamily: "Dela Gothic One",
                  fontStyle: "normal",
                  lineHeight: "110%",
                }}
              >
                Luyện thi Học kỳ
              </p>
              <p
                className="has-text-centered"
                style={{
                  fontSize: "1rem",
                  fontStyle: "normal",
                  lineHeight: "normal",
                }}
              >
                Chuẩn bị sẵn sàng để kết quả tốt nhất trong các kỳ thì học kỳ
                tại trường.
              </p>
              <PrimaryButtonLevelSection
                onClick={() => navigate("/semester-exam-preparation")}
              >
                khám phá
              </PrimaryButtonLevelSection>
            </div>
          </BackgroundImageFromUrl>
        </div>
      </div>
    </div>
  );
};

export default ExamPractice;
