import React, { useCallback, useContext, useEffect } from "react";
import { NumberleContext } from "./Numberle";
import "./Numberle.style.css";

const Keyboard = () => {
  const middleRow = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"];
  const bottomRow = ["Delete", "Enter"];
  const { state, onSelect, onEnter, onDelete } = useContext(NumberleContext);
  const handleClick = (event) => {
    // keyboardType(event.currentTarget.textContent);
    if (state.gameOver) return;
    if (event.currentTarget.textContent === "Enter".toUpperCase()) {
      onEnter();
    } else if (event.currentTarget.textContent === "Delete".toUpperCase()) {
      onDelete();
    } else {
      middleRow.forEach((key) => {
        if (
          event.currentTarget.textContent.toUpperCase() === key.toUpperCase()
        ) {
          onSelect(key);
        }
      });
      bottomRow.forEach((key) => {
        if (
          event.currentTarget.textContent.toUpperCase() === key.toUpperCase()
        ) {
          onSelect(key);
        }
      });
    }
  };
  const handleKeyboard = useCallback(
    (event) => {
      if (state.gameOver) return;
      if (event.key === "Enter") {
        onEnter();
      } else if (event.key === "Backspace") {
        onDelete();
      } else {
        middleRow.forEach((key) => {
          if (event.key.toLowerCase() === key.toLowerCase()) {
            onSelect(key);
          }
        });
        bottomRow.forEach((key) => {
          if (event.key.toLowerCase() === key.toLowerCase()) {
            onSelect(key);
          }
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [state.currentLetterPos, state.gameOver, state.board]
  );
  useEffect(() => {
    document.addEventListener("keydown", handleKeyboard);

    return () => {
      document.removeEventListener("keydown", handleKeyboard);
    };
  }, [handleKeyboard]);
  return (
    <div
      className={` keyboard is-flex is-align-items-center is-justify-content-center is-flex-wrap-wrap has-text-weight-bold`}
      onKeyDown={handleKeyboard}
    >
      <div className="is-flex is-align-items-center is-justify-content-center is-flex-wrap-wrap keyboard-number">
        {middleRow.map((letter) => (
          <div
            className={` ${
              state?.correctList?.includes(letter)
                ? "has-background-kurio-green-main-accent has-text-kurio-main-color-white"
                : state?.foundList?.includes(letter)
                ? "has-background-kurio-main-color-yellow has-text-kurio-main-color-white"
                : state?.usedList?.includes(letter)
                ? "has-background-kurio-gray-main-accent has-text-kurio-main-color-white"
                : letter !== "Delete" && letter !== "Enter"
                ? "has-text-kurio-main-color-black"
                : ""
            }  keyStyle is-flex is-justify-content-center is-align-items-center is-clickable`}
            style={{
              border: "1px solid",
              color:
                letter === "Delete"
                  ? "#E64934"
                  : letter === "Enter"
                  ? "#3E1D9C"
                  : "",
              borderColor:
                state?.correctList?.includes(letter) ||
                state?.foundList?.includes(letter) ||
                state?.usedList?.includes(letter)
                  ? "transparent"
                  : letter === "Delete"
                  ? "#F6DEDC !important"
                  : letter === "Enter"
                  ? "#E8E3F8 !important"
                  : "#D3D6DA",
              background:
                letter === "Delete"
                  ? "#F6DEDC"
                  : letter === "Enter"
                  ? "#E8E3F8"
                  : "",
            }}
            key={letter}
            onClick={handleClick}
          >
            {letter}
          </div>
        ))}
      </div>
      <div className="is-flex is-align-items-center is-justify-content-center is-flex-wrap-wrap keyboard-number">
        {bottomRow.map((letter) => (
          <div
            className={` ${
              state?.correctList?.includes(letter)
                ? "has-background-kurio-green-main-accent has-text-kurio-main-color-white"
                : state?.foundList?.includes(letter)
                ? "has-background-kurio-main-color-yellow has-text-kurio-main-color-white"
                : state?.usedList?.includes(letter)
                ? "has-background-kurio-gray-main-accent has-text-kurio-main-color-white"
                : letter !== "Delete" && letter !== "Enter"
                ? "has-text-kurio-main-color-black"
                : ""
            }  is-flex is-justify-content-center is-align-items-center is-clickable`}
            style={{
              border: "1px solid",
              boxShadow:
                letter === "Delete"
                  ? "0.125rem 0.25rem 0rem 0rem #E64934"
                  : letter === "Enter"
                  ? "0.125rem 0.25rem 0rem 0rem #3E1D9C"
                  : "",
              fontSize: "1.125rem",
              height: "3.5rem",
              width: "6rem",
              borderRadius: "10px",
              color:
                letter === "Delete"
                  ? "#E64934"
                  : letter === "Enter"
                  ? "#3E1D9C"
                  : "",
              borderColor:
                state?.correctList?.includes(letter) ||
                state?.foundList?.includes(letter) ||
                state?.usedList?.includes(letter)
                  ? "transparent"
                  : letter === "Delete"
                  ? "#F6DEDC !important"
                  : letter === "Enter"
                  ? "#E8E3F8 !important"
                  : "#D3D6DA",
              background:
                letter === "Delete"
                  ? "#F6DEDC"
                  : letter === "Enter"
                  ? "#E8E3F8"
                  : "",
            }}
            key={letter}
            onClick={handleClick}
          >
            {letter.toUpperCase()}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Keyboard;
