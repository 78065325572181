import React from "react";
import PopupCloseButton from "../../components/button/special-button/PopupCloseButton";
import ImageFromUrl from "../../components/parseImage/ImageFromUrl";

const AchievementDetail = ({
  achievementDetail,
  setShowPopupAchievementDetail,
}) => {
  var boxStyle = {
    width: "15rem",
    height: "20rem",
    borderRadius: "1rem",
    border: "1.5px solid #0A2A66",
    boxShadow: "0.125rem 0.25rem 0rem 0rem #0A2A66",
  };

  const removePopup = () => {
    setShowPopupAchievementDetail(false);
  };
  return (
    <div
      className="modal is-active modal-background"
      style={{
        opacity: 1,
        zIndex: 1000,
        width: "100%",
        height: "100%",
      }}
    >
      <div
        className=" modal-background"
        style={{ opacity: 0.8 }}
        onClick={removePopup}
      ></div>
      <div
        className="is-relative has-text-kurio-main-color-black is-relative has-background-kurio-main-color-white is-flex is-flex-direction-column is-flex-gap-6 is-align-items-center is-justify-content-center"
        style={{
          width: "50rem",
          padding: "3rem",
          borderRadius: "2rem",
          maxHeight: "42rem",
        }}
      >
        <div
          style={
            achievementDetail?.achievedImageUrl !== null &&
            achievementDetail?.achievedImageUrl?.length > 0
              ? { ...boxStyle, zIndex: 1 }
              : { width: "10rem", height: "12.5rem" }
          }
          className={`${
            achievementDetail.achievedImageUrl !== null &&
            achievementDetail.achievedImageUrl?.length > 0
              ? "has-background-kurio-main-color-white"
              : ""
          } is-flex is-align-items-center is-justify-content-center is-relative`}
        >
          {achievementDetail.count >= 1 && achievementDetail.type === 2 && (
            <div
              className="has-text-weight-bold"
              style={{
                display: "flex",
                position: "absolute",
                backgroundColor: "#0A2A66",
                padding: "0.5rem 1rem",
                flexDirection: "column",
                alignItems: "flex-start",
                gap: "0.625rem",
                borderRadius: "1rem",
                border: "1px solid #FAFBFC",
                boxShadow: "rgb(10, 42, 102) 0.125rem 0.25rem 0rem 0rem",
                zIndex: 2, // Đặt zIndex cao hơn để nằm trên ảnh
                bottom: "-1rem",
              }}
            >
              <p className="is-size-4 has-text-weight-bold has-text-kurio-main-color-white">
                {achievementDetail.type === 2 && "x "}
                {achievementDetail.count}{" "}
              </p>
            </div>
          )}
          <ImageFromUrl
            className="is-relative"
            isAchievement={true}
            style={{
              border: "1.5px solid #0A2A66",
              width: "13rem",
              height: "18rem",
              borderRadius: "1rem",
              filter: achievementDetail.count <= 0 ? "grayscale(100%)" : "",
              zIndex: 0, // Đặt zIndex thấp hơn để ảnh nằm ở dưới cùng
            }}
            objectId={
              achievementDetail?.achievedImageUrl !== null &&
              achievementDetail?.achievedImageUrl?.length > 0
                ? achievementDetail.achievedImageUrl
                : "admin/frontend-images/achievement/badge-image.svg"
            }
            alt=""
          />
        </div>

        {/* {achievementDetail.count > 0 && achievementDetail.count < 1 && (
          <p className="is-size-3 has-text-weight-bold is-lowercase">
            {achievementDetail.count}%
          </p>
        )} */}
        <div
          style={{ gap: "1rem" }}
          className="is-flex is-flex-direction-column is-justify-content-center is-align-items-center"
        >
          <p
            className="has-text-weight-bold"
            style={{ fontSize: "1.5rem", textAlign: "center" }}
          >
            {achievementDetail.name}
          </p>

          <p
            className="has-text-weight-medium"
            style={{
              fontSize: "1rem",
              width: "15rem",
              lineHeight: "normal",
              textAlign: "center",
            }}
          >
            {achievementDetail.description}
          </p>
        </div>

        <div
          onClick={removePopup}
          className=""
          style={{
            position: "absolute",
            top: "-8%",
            left: "-4%",
            zIndex: 999,
            background: "transparent",
            border: "none",
            width: "5rem",
            fontSize: "2rem",
          }}
        >
          <PopupCloseButton />
        </div>
      </div>
    </div>
  );
};

export default AchievementDetail;
