import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import NotificationUpgradeAccount from "../../components/NotificationUpgradeAccount";
import PaginationButtonSmall from "../../components/button/pagination-button/PaginationButtonSmall";
import PrimaryButtonPageLevel from "../../components/button/primary-button/PrimaryButtonLevelPage";
import {
  createNewPracticesTopic,
  getALLTopic,
} from "../../config/practiceTopic";
import { getAllGrade, getGradeUserInfo } from "../../reducers/gradesSlice";
import { getPracticeTopicQuestion } from "../../reducers/practiceElectiveSlice";
import PracticeElectiveMainWrapper from "../practice-elective/PracticeElectiveMainWrapper";
import SelectDifficultIKMC from "./SelectDifficultIKMC";
import SelectLevelIKMC from "./SelectLevelIKMC";

const ChallengeIKMC = ({ userInfo }) => {
  const [step, setStep] = useState(1);
  const [listLevelId, setListLevelId] = useState([]);
  const [difficult, setDifficult] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [practiceQuestionListId, setPracticeQuestionListId] = useState(null);
  const [showError, setShowError] = useState(false);
  const dispatch = useDispatch();
  const handleNextState = async () => {
    if (step === 1) {
      setStep((step) => step + 1);
    } else {
      try {
        const { result: topics } = await getALLTopic();
        const { result } = await createNewPracticesTopic({
          gradeIds: listLevelId,
          topicId: topics.filter((item) => item.name === "IKMC")[0]._id,
          complexityIds: difficult,
        });
        setPracticeQuestionListId(result);
        dispatch(
          getPracticeTopicQuestion({
            id: result,
            page: 1,
            size: 50,
          })
        );
        setShowModal(true);
        // setStep(1);
      } catch (error) {
        // alert(error)
        if (
          error?.response?.data?.errorMessage.includes(
            "Bạn không phải là tài khoản premium, không thể làm bài kiểm tra này"
          ) ||
          error?.response?.data?.errorMessage.includes(
            "Bài luyện tập tự chọn của bạn đã vượt quá số lượng cho phép"
          ) ||
          (userInfo?.premiumFrom &&
            userInfo?.premiumTo &&
            (new Date(userInfo?.premiumFrom).getTime() > new Date().getTime() ||
              new Date(userInfo?.premiumTo).getTime() <
                new Date().getTime())) ||
          userInfo.premiumFrom === null ||
          userInfo.premiumTo === null
        ) {
          // return <NotificationUpgradeAccount isShowPopup={true}/>
          setShowError(true);
        } else {
          alert(error?.response?.data?.errorMessage);
        }
      }
    }
  };
  const handleClickToFilterLevel = () => {
    setStep(1);
    setListLevelId([]);
    setDifficult([]);
  };
  const handleSelectLevel = (level) => {
    setListLevelId(level);
  };
  const handleSelectDifficult = (difficult) => {
    setDifficult(difficult);
  };
  const handleSetShowError = (state) => {
    setShowError(state);
  };
  const handleEndPractice = () => {
    setStep(1);
    setListLevelId([]);
    setDifficult([]);
    setShowModal(false);
  };
  useEffect(() => {
    if (step === 1) {
      dispatch(getAllGrade());
    }
    if (userInfo === null) {
      dispatch(getGradeUserInfo());
    }
  }, [step, dispatch, userInfo]);
  return (
    <div>
      {showError && (
        <NotificationUpgradeAccount
          handleSetShowError={(state) => handleSetShowError(state)}
          isShowPopup={showError}
        />
      )}
      {showModal && (
        <PracticeElectiveMainWrapper
          practiceQuestionListId={practiceQuestionListId}
          handleEndPractice={handleEndPractice}
          IKMCMode={true}
        />

        // <DailyMission
        //   practiceQuestionListId={practiceQuestionListId}
        //   handleEndPractice={handleEndPractice}
        // />
      )}
      <div
        className="is-flex is-flex-direction-column is-align-items-center m-auto"
        style={{ gap: "2.5rem" }}
      >
        <div className="has-text-kurio-main-color-white is-flex is-flex-direction-column is-align-items-center is-flex-gap-3 is-justify-content-center">
          <p
            className="has-text-weight-bold has-text-center has-text-kurio-main-color-white"
            style={{
              fontSize: "2rem",
              lineHeight: "2.4375rem",
              color: "#0A2A66",
            }}
          >
            Thử thách IKMC
          </p>
          <p
            style={{
              fontSize: "1rem",
              fontStyle: "normal",
              lineHeight: "normal",
            }}
          >
            Luyện tập ngẫu nhiên với hệ thống câu hỏi mô phỏng Đề thi IKMC.
          </p>
        </div>
        <div
          className="is-flex is-flex-direction-column is-align-items-center"
          style={{ gap: "1.5rem" }}
        >
          <div
            className={`is-flex p-0 ${
              step === 2
                ? "is-flex-direction-column is-align-items-center"
                : " is-flex-direction-row is-justify-content-space-between is-align-items-center "
            }`}
            style={{ gap: "2.5rem" }}
          >
            <div
              className="is-flex is-flex-direction-row is-align-items-center is-justify-content-center p-0"
              style={{ gap: "2.5rem" }}
            >
              <div className="is-flex is-flex-direction-row is-justify-content-center is-align-items-center has-text-weight-bold">
                <PaginationButtonSmall
                  children={1}
                  onClick={handleClickToFilterLevel}
                  isClick={step === 1}
                ></PaginationButtonSmall>
                {step === 2 && listLevelId.length > 1 && (
                  <>
                    <div
                      style={{
                        width: "1.5rem",
                        border: "0.1rem dashed #0A2A66",
                      }}
                    ></div>
                    <PaginationButtonSmall
                      children={2}
                      // onClick={handleClickFilterDifficult}
                      isClick={step === 2}
                    />
                  </>
                )}
              </div>
              {step === 1 && (
                <p
                  className="has-text-kurio-main-color-white"
                  style={{
                    color: "#0A2A66",
                    fontSize: "1.125rem",
                    lineHeight: "normal",
                  }}
                >
                  <span
                    className="has-text-weight-bold has-text-kurio-main-color-white"
                    style={{ color: "#0A2A66" }}
                  >
                    Bước 1:
                  </span>{" "}
                  Chọn cấp độ IKMC bạn muốn luyện tập
                </p>
              )}
              {step === 2 && (
                <p
                  className="has-text-kurio-main-color-white"
                  style={{
                    color: "#0A2A66",
                    fontSize: "1.125rem",
                    lineHeight: "normal",
                  }}
                >
                  <span
                    className="has-text-kurio-main-color-white has-text-weight-bold"
                    style={{ color: "#0A2A66" }}
                  >
                    Bước 2:
                  </span>{" "}
                  Chọn độ khó bạn muốn luyện tập
                </p>
              )}
            </div>
          </div>

          <div className="">
            {step === 1 && (
              <SelectLevelIKMC
                listLevelId={listLevelId}
                handleSelect={handleSelectLevel}
              />
            )}
            {step === 2 && (
              <SelectDifficultIKMC
                difficult={difficult}
                handleSelect={handleSelectDifficult}
              />
            )}
          </div>
        </div>

        {step === 2 ? (
          <PrimaryButtonPageLevel
            style={{ height: "4rem" }}
            onClick={handleNextState}
            disabled={difficult.length === 0}
          >
            Bắt đầu
          </PrimaryButtonPageLevel>
        ) : (
          <div style={{ width: "15rem" }}>
            {step === 1 ? (
              <PrimaryButtonPageLevel
                style={{ height: "4rem" }}
                disabled={listLevelId.length === 0 ? true : false}
                onClick={handleNextState}
              >
                Tiếp Theo
              </PrimaryButtonPageLevel>
            ) : (
              ""
            )}
          </div>
        )}
      </div>
    </div>
  );
};
function mapStateToProps(state) {
  return {
    userInfo: state.grades.listUserInfo,
  };
}

export default connect(mapStateToProps)(ChallengeIKMC);
