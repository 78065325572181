/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Countdown, { zeroPad } from "react-countdown";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import ReactPlayer from "react-player";
import { connect, useDispatch } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";
import NextButton from "../components/button/pagination-button/NextButton";
import PreviousButton from "../components/button/pagination-button/PreviousButton";
import SlideOnSlider from "../components/button/pagination-button/SlideOnSlider";
import PrimaryButtonPageLevel from "../components/button/primary-button/PrimaryButtonLevelPage";
import PrimaryButtonLevelSection from "../components/button/primary-button/PrimaryButtonLevelSection";
import PrimaryButtonQuestion from "../components/button/primary-button/PrimaryButtonQuestion";
import SecondaryButtonLevelPage from "../components/button/secondary-button/SecondaryButtonLevelPage";
import PopupCloseButton from "../components/button/special-button/PopupCloseButton";
import BackgroundImageFromUrl from "../components/parseImage/BackgroundImageFromUrl";
import ImageFromUrl from "../components/parseImage/ImageFromUrl";
import { getListUnit } from "../config/ListLesson";
import { checkServerTimeError } from "../config/checkAndCallDailyLoginApi";
import { getExamRanking } from "../config/examsQuestion";
import { getLesson } from "../config/lesson";
import ScrollRefWrapper from "../detail-components/ScrollRefWrapper";
import {
  getCurrentEvent,
  setClearStateImageUrlDaily,
} from "../reducers/dailyMissionSlice";
import {
  clearExamQuestion,
  clearState,
  createNewExams,
  getExamQuestion,
  getExamQuestionSolutions,
  getListExamsInformation,
  getListMonthlyExamsQuestion,
  getViewableExamSolutions,
} from "../reducers/examsQuestionSlice";
import { setIsChangeGrade, setLastLesson } from "../reducers/gradesSlice";
import {
  getALesson,
  setCurrentLessonProgress,
} from "../reducers/questionsSlice";
import "../styling/layout-components/HomePage.css";
import { LoadingImage } from "../templates/helper/LoadingImage";
import { getAllImage } from "../templates/helper/getAllImages";
import jsonParser from "../templates/helper/jsonParser";
import { chunk, findLastItemNonEmpty } from "../templates/helper/splitArray";
import DailyMissionMainWrapper from "./daily-mission/DailyMissionMainWrapper";

const HomePage = ({
  grades,
  lastLesson,
  listMonthlyExams,
  deadline,
  currentScore,
  imageUrls,
  maxScore,
  listGradeUnit,
  serverTime,
  isChangeGrade,
  sections,
  questionExam,
  questionDaily,
  startHP,
  status,
  listUserInfo,
  currentHP,
  error,
  gradeName,
}) => {
  const [countdown, setCountdown] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [examMonthly, setExamMonthly] = useState([]);
  // const [examSolution, setExamSolution] = useState([]);
  const [iconSolution, setIconSolution] = useState(false);
  const [showPopupMonthlyExam, setShowPopupMonthlyExam] = useState(false);
  const [showPopupWarning, setShowPopupWarning] = useState(false);
  const [showPopupFinished, setShowPopupFinished] = useState(false);
  const [apiCalled, setApiCalled] = useState(false);
  const [showPopupRanking, setShowPopupRanking] = useState(false);
  const [showPopupContinue, setShowPopupContinue] = useState(false);
  const [topRanking, setTopRanking] = useState(null);
  const [userRank, setUserRank] = useState(null);
  const [currentPage, setCurrentPage] = useState(0);
  const [firstLesson, setFirstLesson] = useState(null);
  const [checkLesson, setCheckLesson] = useState(false);
  const [unitImage, setUnitImage] = useState("");
  const [loadingImage, setLoadingImage] = useState(false);
  const [showPopupStartDailyMission, setShowPopupStartDailyMission] =
    useState(false);
  const [showPopupCongratulations, setShowPopupCongratulations] =
    useState(false);
  const [showPopupSuccessDailyMission] = useState(false);
  const [selectedMenu] = useState("/activities");
  const [showPopupCalculatePoints, setShowPopupCalculatePoints] =
    useState(false);
  const [startExam, setStartExam] = useState(false);
  const [showPopupIntro, setShowPopupIntro] = useState(false);
  // const lastLoginDate = localStorage.getItem("lastLoginDate");

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const prevRoute = useLocation();
  const cookies = new Cookies();
  const token = cookies.get("signinUser");

  const MenuPopupDaily = [
    {
      _id: "1",
      title: "Nhiệm vụ",
      icon: "/images/menu/icon-game.svg",
      pathName: "/activities",
    },
    {
      _id: "2",
      title: "Thi tháng",
      icon: "/images/menu/icon-exam.svg",
      pathName: "/monthly-exams",
    },
    {
      _id: "3",
      title: "Luyện thi",
      icon: "/images/menu/icon-kurio.svg",
      pathName: "/practice-ikmc",
    },
    {
      _id: "4",
      title: "Luyện tập",
      icon: "/images/menu/icon-pen.svg",
      pathName: "/exercise",
    },
    {
      _id: "5",
      title: "Bài giảng",
      icon: "/images/menu/icon-book.svg",
      pathName: "/list-unit",
    },
  ];

  const [defaultSelected] = useState(
    MenuPopupDaily.map((item) => item.pathName === selectedMenu)
      .flat()
      .indexOf(true)
  );
  const handleSetStartExam = (state) => {
    setStartExam(state);
    setShowPopupMonthlyExam(false);
    setShowPopupFinished(false);
    setShowPopupRanking(false);
    setShowPopupWarning(false);
  };
  const handleChangePopupIntro = (state) => {
    setShowPopupIntro(state);
  };

  const handleClickShowPopupStartDaily = () => {
    if (
      !questionDaily ||
      (questionDaily === undefined && questionDaily === null)
    ) {
      alert("Hiện tại chưa có nhiệm vụ hàng ngày");
      return;
    }

    setShowPopupStartDailyMission(true);
    // setShowPopupDailyMission(false);
  };

  // const handleCancelPopupDailyMission = () => {
  //   // setShowPopupDailyMission(false);
  //   window.scrollTo({
  //     top: 0,
  //     behavior: "smooth",
  //   });
  // };

  const handleShowPopupCalculatePoints = () => {
    // setShowPopupCalculatePoints(!showPopupCalculatePoints);
    setShowPopupCalculatePoints(!showPopupCalculatePoints);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const handleClickRanking = () => {
    if (topRanking && topRanking?.length > 0) {
      setShowPopupRanking(true);
    }
  };

  const handleSetLoadingImage = (state) => {
    setLoadingImage(state);
  };

  const getFirstLesson = async (unitId) => {
    try {
      const { result } = await getListUnit(unitId);
      setFirstLesson(result.lessons[0]);
    } catch (error) {
      console.log("err", error);
    }
  };

  const checkALesson = async (id) => {
    try {
      const { result: lesson } = await getLesson(id, token);
      const { result } = await getListUnit(lesson.header.unit.id);
      dispatch(setLastLesson({ ...lastLesson, order: lesson.order }));
      setUnitImage(result.image);
      setCheckLesson(false);
    } catch (error) {
      setCheckLesson(true);
    }
  };

  const rerender = ({ days, hours, minutes, seconds, completed }) => {
    return (
      <div className="time-content-card">
        {isLoading ? (
          <span className="loader"></span>
        ) : (
          `${zeroPad(days)} ngày ${zeroPad(hours)} : ${zeroPad(
            minutes
          )} : ${zeroPad(seconds)}`
        )}
      </div>
    );
  };

  const handleClick = async (lessonId) => {
    const { result: lesson } = await getLesson(lessonId, token);
    const { result } = await getListUnit(lesson?.header?.unit.id);
    if (result !== null) {
      const indexLesson = result?.lessons?.findIndex(obj => obj._id === result?.lessons?.findIndex(obj => obj._id === lessonId)) > 9 ?
        result?.lessons?.findIndex(obj => obj._id === lessonId) :
        `0` + result?.lessons?.findIndex(obj => obj._id === lessonId)
      cookies.set("indexLesson", indexLesson)
    }

    if (lessonId !== null) {
      try {
        await dispatch(getALesson({ lessonId }));
        dispatch(setCurrentLessonProgress([lastLesson]));
        cookies.remove("lessonId");
        localStorage.removeItem("lesson_end_lesson");
        if (lessonId !== null) {
          cookies.set("lessonId", lessonId);
        }
        setLoadingImage(true);
        // navigate("/lesson");
      } catch (error) {
        console.log("home", error);
      }
    }
  };

  const handleClickShowPopup = async () => {
    const checkServerTime = await checkServerTimeError();

    if (checkServerTime) {
      // checkServerTime === true: lệch thời gian với server
      window.location.href = "error-server-time";
    } else {
      // if (listUserInfo?.premiumFrom === null &&
      //   listUserInfo?.premiumTo === null) {
      //   setTimeout(() => {
      //     setStartExam(true);
      //   }, 300);
      // } else {
      //   if (listUserInfo?.premiumFrom &&
      //     listUserInfo?.premiumTo &&
      //     new Date(listUserInfo?.premiumFrom).getTime() <=
      //     new Date().getTime() &&
      //     new Date(listUserInfo?.premiumTo).getTime() <
      //     new Date().getTime()) {
      //     setTimeout(() => {
      //       setStartExam(true);
      //     }, 300);
      //   } else {
      //   }
      // }
      if (listMonthlyExams !== null) {
        const remainingTime =
          new Date(listMonthlyExams[0]?.endDate).getTime() -
          new Date().getTime();
        const minutesTime = Math.floor(remainingTime / (60 * 1000));

        if (minutesTime <= listMonthlyExams[0]?.examTime) {
          setShowPopupWarning(true);
        } else {
          if (
            JSON.parse(localStorage.getItem("listExamInfoMonthly"))?.length >
            0 &&
            JSON.parse(localStorage.getItem("listExamInfoMonthly"))[0]
              ?.takeExamId
          ) {
            setShowPopupContinue(true);
          } else {
            setShowPopupMonthlyExam(true);
          }
        }
      }
    }
  };

  const handleClickShowSolution = () => {
    if (
      localStorage.getItem("solutionInfo") !== undefined &&
      JSON.parse(localStorage.getItem("solutionInfo")).takeExamIdLP !== null
    ) {
      dispatch(
        getExamQuestionSolutions({
          takeExamsQuestionId: JSON.parse(localStorage.getItem("solutionInfo"))
            .takeExamIdLP,
        })
      );

      localStorage.setItem(
        "takeExamIdLPMonthly",
        JSON.parse(localStorage.getItem("solutionInfo")).takeExamIdLP
      );
      setTimeout(() => {
        setStartExam(true);
      }, 300);
      // setTimeout(() => {
      //   navigate("/exam-questions", {
      //     state: { prevRouter: prevRoute.pathname },
      //   });
      // }, 500);
    }

    if (
      localStorage.getItem("solutionInfo") !== undefined &&
      JSON.parse(localStorage.getItem("solutionInfo"))
    ) {
      if (
        JSON.parse(localStorage.getItem("solutionInfo")).takeExamIdLP ===
        null &&
        JSON.parse(localStorage.getItem("solutionInfo")).solutionsViewable ===
        true
      ) {
        dispatch(
          getViewableExamSolutions({
            examYearId: JSON.parse(localStorage.getItem("solutionInfo"))?._id,
            token,
          })
        );

        localStorage.setItem(
          "examYearIdMonthly",
          JSON.parse(localStorage.getItem("solutionInfo"))?._id
        );
        setTimeout(() => {
          setStartExam(true);
        }, 300);
        // setTimeout(() => {
        //   navigate("/exam-questions", {
        //     state: { prevRouter: prevRoute.pathname },
        //   });
        // }, 500);
      }
    }
  };

  const handleFocusHubExam = (e) => {
    if (e?.target?.className === "hub__exam-solution") {
      navigate("/monthly-exams");
    }
  };

  const removePopupOutSide = (e) => {
    if (e?.target?.className === "modal is-active modal-background") {
      setShowPopupMonthlyExam(false);
      setShowPopupFinished(false);
      setShowPopupRanking(false);
      setShowPopupWarning(false);
    }
  };

  const handleNavigate = () => {
    setShowPopupCongratulations(false);
    localStorage.setItem("popupCongratulationCheck", false);
    localStorage.setItem("showDailyQuiz", true);
    navigate("/achievement");
  };

  const handleClickStartExam = () => {
    // const getLocalStorage =
    //   localStorage.getItem("listExamInfo") &&
    //   JSON.parse(localStorage.getItem("listExamInfo"))[0];
    localStorage.removeItem("questionStateExams");
    localStorage.removeItem("pagination");
    localStorage.removeItem("showPopupDailyNow")

    if (
      (!localStorage.getItem("takeExamIdInfo") ||
        !localStorage.getItem("createExamQuestionId")) &&
      !JSON.parse(localStorage.getItem("listExamInfoMonthly"))[0]?.takeExamId
    ) {
      dispatch(
        createNewExams({
          examsQuestionId: JSON.parse(
            localStorage.getItem("listExamInfoMonthly")
          )[0].id,
          token,
        })
      );

      localStorage.setItem(
        "createExamQuestionId",
        JSON.parse(localStorage.getItem("listExamInfoMonthly"))[0].id
      );
      dispatch(clearExamQuestion());
      setTimeout(() => {
        setStartExam(true);
      }, 300);
      // setTimeout(() => {
      //   navigate("/exam-questions", {
      //     state: { prevRouter: prevRoute.pathname },
      //   });
      // }, 500);
    } else {
      // dispatch(
      //   getExamQuestion({
      //     takeExamsQuestionId: localStorage.getItem("takeExamIdInfo")
      //       ? localStorage.getItem("takeExamIdInfo")
      //       : JSON.parse(localStorage.getItem("listExamInfoMonthly"))[0]
      //           ?.takeExamId,
      //     token,
      //   })
      // );
      dispatch(clearExamQuestion());
      setTimeout(() => {
        setStartExam(true);
      }, 300);
      // setTimeout(() => {
      //   navigate("/exam-questions", {
      //     state: { prevRouter: prevRoute.pathname },
      //   });
      // }, 500);
    }
  };

  const removePopup = () => {
    setShowPopupMonthlyExam(false);
    setShowPopupFinished(false);
    setShowPopupRanking(false);
    setShowPopupWarning(false);
    setShowPopupContinue(false);
    setShowPopupCongratulations(false);
    localStorage.setItem("popupCongratulationCheck", false);
    localStorage.removeItem("showPopupDailyNow")
  };

  const handleSelectPage = (index) => {
    setCurrentPage(index);
  };

  const prevSelect = () => {
    if (currentPage > 0) {
      setCurrentPage((currentPage) => currentPage - 1);
    }
  };

  const nextSelect = () => {
    if (currentPage < topRanking?.length) {
      setCurrentPage((currentPage) => currentPage + 1);
    }
  };

  function isValidImageAtIndex(list, index) {
    return (
      Array.isArray(list) &&
      list !== undefined &&
      list !== null &&
      list.length > 0 &&
      list[index] &&
      list[index] !== ""
    );
  }

  function isValidImageAtIndexMonthly(list, index) {
    return (
      Array.isArray(list) &&
      list !== undefined &&
      list !== null &&
      list[0]?.images?.length > index &&
      list[0].images[index] &&
      list[0].images[index] !== ""
    );
  }

  // const onHandleClickMenu = (menu, id) => {
  //   setSelectedMenu(menu);
  //   setDefaultSelected(id - 1);
  // };

  // const handleCancelPopup = (e) => {
  //   if (e === 1) {
  //     navigate("/monthly-exams");
  //   } else if (e === 2) {
  //     navigate("/practice-ikmc");
  //   } else if (e === 3) {
  //     navigate("/exercise");
  //   } else {
  //   }

  //   setShowPopupDailyMission(false);
  // };
  // Get the current date and time

  const convertTimeZoneUTC = (date) => {
    const currentDate = date;

    // Specify the timezone (UTC+7:00 Bangkok, Hanoi)
    const timeZone = "Asia/Bangkok";

    // Create a formatter with the specified timezone
    const formatter = new Intl.DateTimeFormat("en-US", {
      timeZone,
      month: "numeric",
    });

    // Format the current date and time according to the specified timezone
    const formattedDate = formatter.format(currentDate);
    let monthValue = parseInt(formattedDate, 10); // Chuyển đổi sang số và cộng thêm 1

    // Đặt giá trị là 12 nếu tháng là 1
    if (monthValue === 1) {
      monthValue = 12;
    } else {
      // Trừ đi 1 cho giá trị zero-based cho các tháng khác
      monthValue -= 1;
    }

    return Number(monthValue);
  };

  useEffect(() => {
    localStorage.removeItem("questionStateExams");
    localStorage.removeItem("targets");
    localStorage.removeItem("nameExam");
    localStorage.removeItem("listExamInfo");
    localStorage.removeItem("currentScore");
    localStorage.removeItem("maxScore");
    localStorage.removeItem("listNewExamSolutions");
    localStorage.removeItem("examsQuestionId");
    localStorage.removeItem("takeExamYearQuestionId");
    localStorage.removeItem("yearExamNow");
    localStorage.removeItem("pagination");
    dispatch(clearState());
    dispatch(clearExamQuestion());

    const listCookies = cookies.getAll();
    const lastLoginKey = "lastLoginDate";
    const clearAllKey = "clearAll";
    const showPopupDailyNow = "showPopupDailyNow";
    const popupCongratulationCheck = "popupCongratulationCheck";
    Object.keys(listCookies).map(
      (cookie) =>
        cookie !== "signinUser" &&
        cookie !== "register" &&
        cookie !== "gradeId" &&
        cookies.remove(cookie)
    );

    // if (!lastLoginKey) {
    //   const keys = Object.keys(localStorage);
    //   keys.forEach((key) => {
    //     if (key !== lastLoginKey) {
    //       localStorage.removeItem(key);
    //     }
    //   });
    // }

    window.onbeforeunload = () => {
      if (window.location.pathname === "/") {
        const keys = Object.keys(localStorage);
        keys.forEach((key) => {
          if (
            key !== lastLoginKey &&
            key !== showPopupDailyNow &&
            key !== popupCongratulationCheck &&
            key !== clearAllKey
          ) {
            localStorage.removeItem(key);
          }
        });
      }
      window.history.replaceState(null, "");
    };

    // dispatch(getGradeUserInfo());
    const monthlySolution = JSON.parse(localStorage.getItem("solutionInfo"));
    const getRanking = async (id) => {
      try {
        const { result } = await getExamRanking(id);
        setUserRank(result.userRanking);
        setTopRanking(chunk(result.topRanking, 10));
      } catch (error) {
        console.log("err", error);
      }
    };
    if (monthlySolution && !isChangeGrade) {
      if (topRanking === null) {
        getRanking(monthlySolution?._id);
      }
    }

    if (
      JSON.parse(localStorage.getItem("listExamInfoMonthly"))?.length > 0 &&
      localStorage.getItem("solutionInfo") !== undefined &&
      localStorage.getItem("solutionInfo")
    ) {
      if (monthlySolution?.takeExamIdLP !== null && !isChangeGrade) {
        if (
          monthlySolution?.takeExamIdLP !==
          JSON.parse(localStorage.getItem("listExamInfoMonthly"))[0]?.takeExamLP
        ) {
          if (monthlySolution?.takeExamIdLP !== null) {
            dispatch(
              getExamQuestionSolutions({
                takeExamsQuestionId: monthlySolution?.takeExamIdLP,
              })
            );

            setIconSolution(false);
          }
        } else {
          if (
            JSON.parse(localStorage.getItem("solutionInfo"))?.takeExamIdLP ===
            null &&
            JSON.parse(localStorage.getItem("solutionInfo"))
              ?.solutionsViewable === true
          ) {
            dispatch(
              getViewableExamSolutions({
                examYearId: JSON.parse(localStorage.getItem("solutionInfo"))
                  ?._id,
                token,
              })
            );
          }
          setIconSolution(true);
        }
      } else {
        setIconSolution(true);
      }
    } else {
      setIconSolution(true);
    }

    if (isChangeGrade && grades) {
      const keys = Object.keys(localStorage);
      keys.forEach((key) => {
        if (
          key !== lastLoginKey &&
          key !== showPopupDailyNow &&
          key !== popupCongratulationCheck &&
          key !== clearAllKey
        ) {
          localStorage.removeItem(key);
        }
      });
      setFirstLesson(null);
      setUnitImage("");

      // if (JSON.parse(localStorage.getItem("solutionInfo"))) {
      //   localStorage.removeItem("solutionInfo");
      // }
      dispatch(getListMonthlyExamsQuestion({ page: 1, size: 50 }));

      dispatch(setClearStateImageUrlDaily());
      dispatch(getCurrentEvent());

      if (localStorage.getItem("solutionInfo") !== null) {
        if (
          JSON.parse(localStorage.getItem("solutionInfo"))?.takeExamIdLP !==
          null
        ) {
          dispatch(
            getExamQuestionSolutions({
              takeExamsQuestionId: JSON.parse(
                localStorage.getItem("solutionInfo")
              )?.takeExamIdLP,
            })
          );
        } else if (
          JSON.parse(localStorage.getItem("solutionInfo"))?.takeExamIdLP ===
          null &&
          JSON.parse(localStorage.getItem("solutionInfo"))
            ?.solutionsViewable === true
        ) {
          dispatch(
            getViewableExamSolutions({
              examYearId: JSON.parse(localStorage.getItem("solutionInfo"))?._id,
              token,
            })
          );
        }
      }

      if (localStorage.getItem("solutionInfo") !== null) {
        if (topRanking === null) {
          getRanking(JSON.parse(localStorage.getItem("solutionInfo"))?._id);
        }
      } else {
        setUserRank(null);
        setTopRanking(null);
      }

      dispatch(setIsChangeGrade(false));
    }

    // if (grades) {
    //   dispatch(getListGradeUnits({ grades }));
    // }

    // daily-mission

    // if (questionDaily === null) {
    //   dispatch(getCurrentEvent());
    // }

    if (
      lastLesson &&
      lastLesson?._id !== null &&
      unitImage === "" &&
      listGradeUnit?.length > 0
    ) {
      checkALesson(lastLesson._id);
    }
    if (
      listGradeUnit !== undefined &&
      listGradeUnit?.length > 0 &&
      lastLesson &&
      lastLesson?._id === null &&
      firstLesson === null
    ) {
      getFirstLesson(listGradeUnit[0]?._id);
    }

    if (JSON.parse(localStorage.getItem("listExamInfoMonthly"))?.length > 0) {
      if (
        JSON.parse(localStorage.getItem("listExamInfoMonthly"))[0]
          ?.takeExamLP ||
        (deadline !== null &&
          new Date(deadline).getTime() - new Date(serverTime).getTime() <= 0)
      ) {
        localStorage.removeItem("createExamQuestionId");
        localStorage.removeItem("takeExamMonthlyQuestionId");
        localStorage.removeItem("takeExamIdInfo");
        localStorage.removeItem("listNewExamSolutions");
        localStorage.removeItem("size");
        localStorage.removeItem("listExamInfo");
      }
    }

    if (listMonthlyExams !== null || listMonthlyExams !== undefined) {
      const currentDate = new Date();
      setExamMonthly(
        listMonthlyExams?.filter(
          (item, index) =>
            item.startDate !== null &&
            item.endDate !== null &&
            new Date(item.endDate).getTime() >=
            new Date(currentDate).getTime() &&
            new Date(item.startDate).getTime() <=
            new Date(currentDate).getTime()
        )
      );

      // setExamSolution(JSON.parse(localStorage.getItem("solutionInfo")));

      const endDate = new Date(
        JSON.parse(localStorage.getItem("examMonthlyNow"))?.endDate
      );

      const now = new Date();
      if (endDate && endDate.toString() !== "Invalid Date") {
        const remainingTime = endDate.getTime() - now.getTime();

        if (!isNaN(remainingTime)) {
          if (remainingTime > 0) {
            setCountdown(remainingTime);
          } else {
            setCountdown(Math.abs(remainingTime));
          }
        }
        setTimeout(() => {
          setIsLoading(false); // Set isLoading to false after the data has loaded
        }, 300);
      } else {
        setIsLoading(false);
      }

      if (JSON.parse(localStorage.getItem("listExamInfoMonthly"))?.length > 0) {
        if (
          JSON.parse(localStorage.getItem("listExamInfoMonthly"))[0]
            ?.takeExamId !== null ||
          localStorage.getItem("takeExamIdInfo")
        ) {
          dispatch(
            getListExamsInformation({
              examYearId: JSON.parse(
                localStorage.getItem("listExamInfoMonthly")
              )[0]?.id,
            })
          );

          dispatch(
            getExamQuestion({
              takeExamsQuestionId:
                localStorage.getItem("takeExamIdInfo") !== null
                  ? localStorage.getItem("takeExamIdInfo")
                  : JSON.parse(localStorage.getItem("listExamInfoMonthly"))[0]
                    ?.takeExamId,
              token,
            })
          );
        }
      }

      if (!apiCalled) {
        dispatch(getListMonthlyExamsQuestion({ page: 1, size: 50 }));
        setApiCalled(true);
      }
    } else {
      localStorage.removeItem("solutionInfo");
      localStorage.removeItem("examMonthlyNow");
      localStorage.removeItem("listExamInfoMonthly");

      dispatch(getListMonthlyExamsQuestion({ page: 1, size: 50 }));
    }
  }, [
    listMonthlyExams,
    questionDaily,
    apiCalled,
    dispatch,
    isChangeGrade,
    grades,
    lastLesson?._id,
    listGradeUnit,
    defaultSelected,
  ]);

  if (startExam) {
    // if (listUserInfo?.premiumFrom === null &&
    //   listUserInfo?.premiumTo === null) {
    //   return (
    //     <NotificationUpgradeAccount
    //       handleSetShowError={(state) => handleSetStartExam(state)}
    //       isShowPopup={startExam}
    //     />
    //   );
    // } else {
    //   if (
    //     listUserInfo?.premiumFrom &&
    //     listUserInfo?.premiumTo &&
    //     new Date(listUserInfo?.premiumFrom).getTime() <= new Date().getTime() &&
    //     new Date(listUserInfo?.premiumTo).getTime() < new Date().getTime()
    //   ) {
    //     return (
    //       <NotificationUpgradeAccount
    //         handleSetShowError={(state) => handleSetStartExam(state)}
    //         isShowPopup={startExam}
    //       />
    //     );
    //   } else {
    //     if (
    //       localStorage.getItem("takeExamIdLPMonthly") ||
    //       JSON.parse(localStorage.getItem("listExamInfoMonthly"))[0]
    //         ?.takeExamId !== null ||
    //       localStorage.getItem("takeExamMonthlyQuestionId") ||
    //       localStorage.getItem("examYearIdMonthly")
    //     ) {
    //       setTimeout(() => {
    //         navigate("/monthly-exam-questions", {
    //           state: { prevRouter: prevRoute.pathname },
    //         });
    //       }, 500);
    //     }
    //   }
    // }
    if (
      localStorage.getItem("takeExamIdLPMonthly") ||
      JSON.parse(localStorage.getItem("listExamInfoMonthly"))[0]
        ?.takeExamId !== null ||
      localStorage.getItem("takeExamMonthlyQuestionId") ||
      localStorage.getItem("examYearIdMonthly")
    ) {
      setTimeout(() => {
        navigate("/monthly-exam-questions", {
          state: { prevRouter: prevRoute.pathname },
        });
      }, 500);
    }
    // if (error && error.includes("Bạn không phải là tài khoản premium, không thể làm bài kiểm tra này")) {
    //
    // } else {
    //
    // }
  }

  if (loadingImage) {
    if (sections !== null && sections?.length > 0) {
      const result = getAllImage(sections, "LESSON");
      if (result && result?.length > 0) {
        return (
          <>
            <LoadingImage
              images={result}
              router={"lesson"}
              handleSetLoadingImage={(state) => handleSetLoadingImage(state)}
            />
          </>
        );
      } else {
        navigate("/lesson");
        // setLoadingImage(false);
      }
    }
  }

  return (
    <div>
      <ScrollRefWrapper />

      {showPopupCongratulations && (
        <BackgroundImageFromUrl
          objectId="admin/frontend-images/hub/popup-chuc-mung-dat-thanh-tich.svg"
          className="modal is-active modal-background"
          style={{
            opacity: 1,
            zIndex: 1000,
            width: "100%",
            height: "100%",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          <div className="is-flex is-flex-direction-row is-align-items-center">
            <div
              className="is-flex is-flex-direction-column"
              style={{ gap: "2.5rem" }}
            >
              <p
                style={{
                  fontSize: "8rem",
                  textAlign: "center",
                  fontFamily: "Sriracha",
                  lineHeight: "87.5%",
                  fontWeight: 400,
                  color: "#FAFBFC",
                }}
              >
                Chúc <br /> mừng!
              </p>

              <p
                style={{
                  textAlign: "center",
                  fontSize: "1.5rem",
                  fontFamily: "Montserrat",
                  lineHeight: "normal",
                  fontWeight: 500,
                  color: "#FAFBFC",
                }}
              >
                Bạn đã đạt được thêm <br />{" "}
                <b>1 huy hiệu cho Bảng Thành Tích!</b>
              </p>

              <div
                className="is-flex is-flex-direction-row"
                style={{ gap: "1rem" }}
              >
                <PrimaryButtonPageLevel
                  style={{
                    height: "4rem",
                    width: "20rem",
                    borderRadius: "1rem",
                    maxWidth: "20rem",
                  }}
                  isDarkColor={true}
                  onClick={handleNavigate}
                >
                  TỚI TRANG THÀNH TÍCH
                </PrimaryButtonPageLevel>
                <PrimaryButtonPageLevel
                  style={{
                    height: "4rem",
                    width: "10rem",
                    borderRadius: "1rem",
                  }}
                  onClick={removePopup}
                >
                  Đóng
                </PrimaryButtonPageLevel>
              </div>
            </div>

            <div style={{ width: "33.22rem" }}>
              <div className="is-relative" style={{ marginLeft: "-3rem" }}>
                <img src="/images/hub/background-congratulations.svg" alt="" />
                <div
                  style={{
                    position: "absolute",
                    left: "6.3rem",
                    top: "7rem",
                  }}
                >
                  <div className="is-relative">
                    <ImageFromUrl
                      style={{
                        position: "absolute",
                        left: "3.3rem",
                        top: "-4rem",
                      }}
                      objectId="admin/frontend-images/hub/image-congratulations-12.svg"
                      alt=""
                    />
                    <ImageFromUrl objectId="admin/frontend-images/hub/bg-laurel.svg" alt="" />
                  </div>

                  <div
                    style={{
                      padding: "1.5rem 4rem",
                      width: "20.5rem",
                      height: "6.625rem",
                      borderRadius: "5rem",
                      border: "8px solid #fed131",
                      margin: "0 auto",
                    }}
                    className="is-flex is-align-items-center has-background-kurio-yellow-light-3"
                  >
                    <p
                      className="has-text-kurio-main-color-black"
                      style={{
                        fontSize: "1.5rem",
                        fontWeight: 700,
                        lineHeight: "normal",
                        textAlign: "center",
                      }}
                    >
                      Hạ gục Quái vật Tháng {new Date().getMonth() + 1}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </BackgroundImageFromUrl>
      )}

      {showPopupStartDailyMission && (
        <DndProvider backend={HTML5Backend}>
          <DailyMissionMainWrapper
            showPopupStartDailyMission={showPopupStartDailyMission}
            setShowPopupCalculatePoints={setShowPopupCalculatePoints}
            setShowPopupStartDailyMission={setShowPopupStartDailyMission}
            showPopupSuccessDailyMission={showPopupSuccessDailyMission}
            setShowPopupCongratulations={setShowPopupCongratulations}
            showPopupCongratulations={showPopupCongratulations}
          />
        </DndProvider>
      )}

      {showPopupIntro && (
        <div
          className={`modal is-active
         p-0 `}
          style={{
            backgroundSize: "contain",
            zIndex: "200",
            backgroundImage:
              "url('/images/hub/background-popup-daily-mission.svg')",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "bottom",
          }}
        >
          <div className="modal-background" style={{ opacity: 0.8 }}></div>
          <div
            className="is-relative has-background-kurio-main-color-white"
            style={{
              width: "53.33331rem",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              backgroundSize: "contain",
              height: "30rem",
              borderRadius: "2.5rem",
              position: "relative",
            }}
          >
            <ReactPlayer
              className="video-intro"
              style={{
                width: "100%",
                margin: "0 auto",
                zIndex: 1,
                position: "relative",
                maxWidth: "53.33331rem",
                aspectRatio: "16/9",
              }}
              width="100%"
              height="30rem"
              url={"https://www.youtube.com/watch?v=V_kkepJhJos"}
              controls={true}
            />
            <div
              onClick={() => handleChangePopupIntro(false)}
              style={{
                position: "absolute",
                top: "-2rem",
                left: "-1.5rem",
                zIndex: 2,
                background: "transparent",
                border: "none",
                width: "5rem",
                fontSize: "2rem",
              }}
            >
              <PopupCloseButton />
            </div>
          </div>
        </div>
      )}

      {showPopupContinue && (
        <div
          onClick={removePopupOutSide}
          className="modal is-active modal-background"
          style={{ opacity: 1, zIndex: 1000 }}
        >
          <div className="modal-quiz is-relative">
            <h2 style={{ color: "#0A2A66" }} className="title-popup-quiz">
              ! Thi tháng !
            </h2>
            <p className="documnent-quiz">
              Bạn đang làm <b>Đề thi Tháng 11. </b>
            </p>

            <div className="is-flex " style={{ gap: "1rem", margin: "0 auto" }}>
              <PrimaryButtonPageLevel
                onClick={handleClickStartExam}
                style={{ height: "4rem", borderRadius: "1rem" }}
              >
                Làm tiếp
              </PrimaryButtonPageLevel>
            </div>

            <div
              onClick={removePopup}
              className=""
              style={{
                position: "absolute",
                top: "-8%",
                left: "-4%",
                zIndex: 9999,
                background: "transparent",
                border: "none",
                width: "5rem",
                fontSize: "2rem",
              }}
            >
              <PopupCloseButton />
            </div>
          </div>
        </div>
      )}

      {/* {showPopupDailyMission && (
        
      )} */}

      {showPopupCalculatePoints && (
        <div
          className="modal is-active modal-background"
          style={{
            opacity: 1,
            zIndex: 1000,
            backgroundImage:
              'url("/images/hub/background-popup-daily-mission.svg")',
            width: "100%",
            height: "100%",
            backgroundRepeat: "no-repeat",
            backgroundSize: "contain",
            backgroundPosition: "center",
          }}
        >
          <BackgroundImageFromUrl
            objectId="admin/frontend-images/hub/background-calculate-points.svg"
            className="is-relative "
            style={{
              width: "56.875rem",

              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              backgroundSize: "contain",
              height: "34.125rem",
              borderRadius: "3.8125rem",
              position: "relative",
            }}
          >
            <div
              style={{
                position: "position",
                top: "2rem",
                right: "-2.7rem",
                width: "100%",
              }}
            >
              <div
                style={{
                  position: "absolute",
                  zIndex: "110",
                  top: "-3rem",
                  right: "12rem",
                }}
              >
                <img
                  className="is-relative"
                  src="/images/hub/nav-bar-calculate-points.svg"
                  alt=""
                />
              </div>
            </div>

            <div style={{ padding: "2rem" }}>
              <div
                style={{
                  width: " 52.125rem",
                  height: "30.125rem",
                  borderRadius: "4rem",
                  padding: "5.19rem 6.06rem",
                  background: "#fff",
                  margin: "0 auto",
                }}
              >
                <div
                  className="main-caculator-point"
                  style={{
                    width: "41rem",
                    maxHeight: "22.5rem",
                  }}
                >
                  <div style={{ width: "39rem", height: "22.5rem" }}>
                    <p
                      className="has-text-kurio-main-color-black"
                      style={{
                        fontSize: "1.25",
                        fontWeight: 500,
                        textAlign: "justify",
                      }}
                    >
                      Nhiệm vụ của bạn là đánh bại quái vật để thu thập trọn bộ
                      sticker quái vật của Kurio. <br />
                      1. Quái vật được sinh ra vào ngày đầu tiên của mỗi tháng
                      với 100 điểm sức khỏe. <br />
                      2. Mỗi ngày, bạn sẽ gặp phải 5 rào cản là 5 câu hỏi toán
                      học. Trả lời đúng mỗi câu hỏi sẽ trừ 1 điểm sức khỏe của
                      quái vật. <br />
                      3. Với mỗi ngày bạn không tham gia trận chiến, quái vật sẽ
                      hồi sức! Vậy nên các bạn nhớ tham gia nhiệm vụ hằng ngày
                      nhé! <br />
                      4. Phần thưởng khi đánh bại quái vật là huy hiệu quái vật
                      tương ứng để bạn lưu vào bộ sưu tập của mình. <br />
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div
              onClick={handleShowPopupCalculatePoints}
              style={{
                position: "absolute",
                top: "-2rem",
                left: "-1.5rem",
                zIndex: 9999,
                background: "transparent",
                border: "none",
                width: "5rem",
                fontSize: "2rem",
              }}
            >
              <PopupCloseButton />
            </div>
          </BackgroundImageFromUrl>
        </div>
      )}

      {showPopupRanking && (
        <div
          onClick={removePopupOutSide}
          className="modal is-active modal-background"
          style={{ opacity: 1, zIndex: 1000 }}
        >
          <BackgroundImageFromUrl
            objectId="admin/frontend-images/hub/background-top-ranking-popup.png"
            className="popup-ranking-monthly-exam"
          >
            <div className="header-top-popup-ranking">
              <div className="title-ranking-popup">
                <div className="ranking-title">
                  <ImageFromUrl
                    objectId="admin/frontend-images/hub/title-ranking-monthly.png"
                    alt=""
                  />
                  <img
                    style={{ marginLeft: "-1rem" }}
                    className="monthly-number"
                    src={`/images/hub/ranking-monthly-${String(convertTimeZoneUTC(new Date()))}.svg`}
                    alt=""
                  />
                </div>

                <div className="ranking-personal">
                  <p>Hạng cá nhân</p>
                  <span>{userRank ? userRank?.ranking : "-"}</span>
                </div>
              </div>

              <div className="ranking-popup-monthly-exam is-inline-flex is-flex-direction-column is-align-items-center">
                <div
                  className="is-flex is-flex-direction-row is-relative has-text-centered"
                  style={{ height: "8.3rem", width: "30.1rem" }}
                >
                  <div
                    className="is-flex is-flex-direction-column is-align-items-center has-text-centered"
                    style={{
                      gap: "0.69rem",
                      position: "absolute",
                      left: "2.5rem",
                      top: "2.5rem",
                      width: "4.5rem",
                    }}
                  >
                    {topRanking &&
                      topRanking?.length > 0 &&
                      topRanking[0][1]?.profileImage?.length > 0 ? (
                      <ImageFromUrl
                        style={{
                          width: "4.5rem",
                          height: "4.5rem",
                          border: "0.25rem solid #FAFBFC",
                          borderRadius: "3rem",
                        }}
                        objectId={
                          topRanking &&
                          topRanking?.length > 0 &&
                          topRanking[0][1]?.profileImage
                        }
                      />
                    ) : (
                      <ImageFromUrl
                        style={{
                          width: "4.5rem",
                          height: "4.5rem",
                          outline: "0.25rem solid #FAFBFC",
                          borderRadius: "3rem",
                        }}
                        objectId="admin/frontend-images/avatar/chim-bt.svg"
                        alt=""
                      />
                    )}
                    <span
                      className="has-text-kurio-main-color-white has-text-weight-bold"
                      style={{ fontSize: "1.25rem", fontStyle: "normal" }}
                    >
                      {topRanking &&
                        topRanking?.length > 0 &&
                        topRanking[0]?.length > 1 &&
                        findLastItemNonEmpty(
                          topRanking[0][1]?.fullname?.split(" ")
                        )}
                    </span>
                  </div>
                  <div
                    className="is-flex is-flex-direction-column is-align-items-center has-text-centered"
                    style={{
                      gap: "0.69rem",
                      width: "4.5rem",
                      position: "absolute",
                      top: "-1rem",
                      left: "12.85rem",
                    }}
                  >
                    {topRanking &&
                      topRanking?.length > 0 &&
                      topRanking[0][0]?.profileImage?.length > 0 ? (
                      <ImageFromUrl
                        style={{
                          width: "4.5rem",
                          height: "4.5rem",
                          border: "0.25rem solid #FAFBFC",
                          borderRadius: "3rem",
                        }}
                        objectId={
                          topRanking &&
                          topRanking?.length > 0 &&
                          topRanking[0][0]?.profileImage
                        }
                      />
                    ) : (
                      <ImageFromUrl
                        style={{
                          width: "4.5rem",
                          height: "4.5rem",
                          outline: "0.25rem solid #FAFBFC",
                          borderRadius: "3rem",
                        }}
                        objectId="admin/frontend-images/avatar/chim-bt.svg"
                        alt=""
                      />
                    )}
                    <span
                      className="has-text-kurio-main-color-white has-text-weight-bold"
                      style={{
                        fontSize: "1.25rem",
                        fontStyle: "normal",
                        width: "4.5rem",
                      }}
                    >
                      {topRanking &&
                        topRanking?.length > 0 &&
                        findLastItemNonEmpty(
                          topRanking[0][0]?.fullname?.split(" ")
                        )}
                    </span>
                  </div>
                  <div
                    className="is-flex is-flex-direction-column is-align-items-center has-text-centered"
                    style={{
                      gap: "0.69rem",
                      position: "absolute",
                      width: "4.5rem",
                      top: "4rem",
                      right: "2.5rem",
                    }}
                  >
                    {topRanking &&
                      topRanking?.length > 0 &&
                      topRanking[0][2]?.profileImage?.length > 0 ? (
                      <ImageFromUrl
                        style={{
                          width: "4.5rem",
                          height: "4.5rem",
                          border: "0.25rem solid #FAFBFC",
                          borderRadius: "3rem",
                        }}
                        objectId={
                          topRanking &&
                          topRanking?.length > 0 &&
                          topRanking[0][2]?.profileImage
                        }
                      />
                    ) : (
                      <ImageFromUrl
                        style={{
                          width: "4.5rem",
                          height: "4.5rem",
                          outline: "0.25rem solid #FAFBFC",
                          borderRadius: "3rem",
                        }}
                        objectId="admin/frontend-images/avatar/chim-bt.svg"
                        alt=""
                      />
                    )}
                    <span
                      className="has-text-kurio-main-color-white has-text-weight-bold"
                      style={{ fontSize: "1.25rem", fontStyle: "normal" }}
                    >
                      {topRanking &&
                        topRanking?.length > 0 &&
                        topRanking[0]?.length > 2 &&
                        findLastItemNonEmpty(
                          topRanking[0][2]?.fullname?.split(" ")
                        )}
                    </span>
                  </div>
                </div>
                <ImageFromUrl
                  objectId="admin/frontend-images/hub/buc-ranking-popup.svg"
                  alt=""
                />
              </div>
            </div>
            <div className="main-content-ranking">
              <div className="left is-flex is-flex-direction-column is-align-items-flex-start is-flex-gap-3">
                {topRanking &&
                  topRanking?.length > 0 &&
                  topRanking[currentPage]
                    ?.filter((item, index) => index <= 4 && item)
                    .map((itemRanking, i) => {
                      return (
                        <div
                          key={i}
                          className={` ${itemRanking.ranking === 1
                            ? ""
                            : itemRanking.ranking === 2
                              ? "has-background-kurio-red-light-3"
                              : itemRanking.ranking === 3
                                ? "has-background-kurio-blue-light-3"
                                : itemRanking.ranking === 4
                                  ? "has-background-kurio-green-light-3"
                                  : itemRanking.ranking === 5
                                    ? "has-background-kurio-green-light-3"
                                    : "has-background-kurio-light-gray"
                            } item-ranking`}
                        >
                          <span
                            className={`${itemRanking.ranking === 1
                              ? "has-background-kurio-main-color-yellow has-text-kurio-main-color-purple"
                              : itemRanking.ranking === 2
                                ? "has-background-kurio-red-main-accent has-text-kurio-main-color-white"
                                : itemRanking.ranking === 3
                                  ? "has-background-kurio-blue-main-accent has-text-kurio-main-color-white"
                                  : itemRanking.ranking === 4
                                    ? "has-background-kurio-green-main-accent has-text-kurio-main-color-white"
                                    : itemRanking.ranking === 5
                                      ? "has-background-kurio-green-main-accent has-text-kurio-main-color-white"
                                      : "has-background-kurio-gray-light-1 has-text-kurio-main-color-black"
                              }`}
                          >
                            {itemRanking.ranking}
                          </span>
                          <p className="name-user">{itemRanking.fullname}</p>
                          <p className="exam-score">{itemRanking.score}</p>
                        </div>
                      );
                    })}
              </div>
              <div className="right is-flex is-flex-direction-column is-align-items-flex-start is-flex-gap-3">
                {topRanking &&
                  topRanking[currentPage]?.length > 6 &&
                  topRanking[currentPage]
                    ?.filter((item, index) => index > 4 && item)
                    .map((itemRanking, i) => {
                      return (
                        <div
                          key={i}
                          className={` ${itemRanking.ranking === 1
                            ? ""
                            : itemRanking.ranking === 2
                              ? "has-background-kurio-red-light-3"
                              : itemRanking.ranking === 3
                                ? "has-background-kurio-blue-light-3"
                                : itemRanking.ranking === 4
                                  ? "has-background-kurio-green-light-3"
                                  : itemRanking.ranking === 5
                                    ? "has-background-kurio-green-light-3"
                                    : "has-background-kurio-light-gray"
                            } item-ranking`}
                        >
                          <span
                            className={`${itemRanking.ranking === 1
                              ? "has-background-kurio-main-color-yellow has-text-kurio-main-color-purple"
                              : itemRanking.ranking === 2
                                ? "has-background-kurio-red-main-accent has-text-kurio-main-color-white"
                                : itemRanking.ranking === 3
                                  ? "has-background-kurio-blue-main-accent has-text-kurio-main-color-white"
                                  : itemRanking.ranking === 4
                                    ? "has-background-kurio-green-main-accent has-text-kurio-main-color-white"
                                    : itemRanking.ranking === 5
                                      ? "has-background-kurio-green-main-accent has-text-kurio-main-color-white"
                                      : "has-background-kurio-gray-light-1 has-text-kurio-main-color-black"
                              }`}
                          >
                            {itemRanking.ranking}
                          </span>
                          <p className="name-user">{itemRanking.fullname}</p>
                          <p className="exam-score">{itemRanking.score}</p>
                        </div>
                      );
                    })}
              </div>
            </div>
            <div className="pagination-ranking">
              <PreviousButton
                disabled={currentPage === 0}
                onClick={prevSelect}
              />

              <div className="slide-pagination">
                {topRanking &&
                  topRanking.map((item, index) => {
                    return (
                      <SlideOnSlider
                        onClick={() => handleSelectPage(index)}
                        isClick={index === currentPage}
                        // disabled={currentPage === 0}
                        key={index}
                      />
                    );
                  })}
              </div>

              <NextButton
                disabled={currentPage + 1 === topRanking?.length}
                onClick={nextSelect}
              />
            </div>

            <div
              onClick={removePopup}
              className=""
              style={{
                position: "absolute",
                top: "0rem",
                left: "-3rem",
                zIndex: 9999,
                background: "transparent",
                border: "none",
                width: "5rem",
                fontSize: "2rem",
              }}
            >
              <PopupCloseButton />
            </div>
          </BackgroundImageFromUrl>
        </div>
      )}

      {showPopupMonthlyExam && (
        <div
          onClick={removePopupOutSide}
          className="modal is-active modal-background"
          style={{ opacity: 1, zIndex: 1000 }}
        >
          <div className="modal-quiz is-relative">
            <h2 style={{ color: "#0A2A66" }} className="title-popup-quiz">
              ! Lưu ý !
            </h2>
            <p className="documnent-quiz">
              Bạn chỉ được làm{" "}
              {listMonthlyExams !== null && listMonthlyExams[0]?.name}{" "}
              <b>một lần duy nhất</b>. <br /> Thời gian làm bài là{" "}
              <b>
                {listMonthlyExams !== null && listMonthlyExams[0]?.examTime}{" "}
                phút
              </b>
              . <br /> Bạn có thật sự muốn bắt đầu?
            </p>

            <div
              className="is-flex is-flex-direction-row"
              style={{ gap: "1rem", margin: "0 auto" }}
            >
              <SecondaryButtonLevelPage onClick={removePopup}>
                Để lúc khác
              </SecondaryButtonLevelPage>
              <PrimaryButtonPageLevel
                onClick={handleClickStartExam}
                style={{ height: "4rem" }}
              >
                Bắt đầu ngay
              </PrimaryButtonPageLevel>
            </div>

            <div
              onClick={removePopup}
              className=""
              style={{
                position: "absolute",
                top: "-8%",
                left: "-4%",
                zIndex: 9999,
                background: "transparent",
                border: "none",
                width: "5rem",
                fontSize: "2rem",
              }}
            >
              <PopupCloseButton />
            </div>
          </div>
        </div>
      )}

      {showPopupWarning && (
        <div
          onClick={removePopupOutSide}
          className="modal is-active modal-background"
          style={{ opacity: 1, zIndex: 1000 }}
        >
          <div className="modal-quiz is-relative">
            <h2 style={{ color: "#0A2A66" }} className="title-popup-quiz">
              ! Lưu ý !
            </h2>
            <p className="documnent-quiz">
              {listMonthlyExams !== null && listMonthlyExams[0]?.name}{" "}
              <b>
                chỉ còn{" "}
                {listMonthlyExams !== null &&
                  Math.floor(
                    (new Date(listMonthlyExams[0]?.endDate).getTime() -
                      new Date().getTime()) /
                    (60 * 1000)
                  )}{" "}
                phút
              </b>
              . <br /> Thời gian làm bài là{" "}
              <b>
                {listMonthlyExams !== null && listMonthlyExams[0]?.examTime}{" "}
                phút
              </b>
              . <br /> Bạn có thật sự muốn bắt đầu?
            </p>

            <div
              className="is-flex is-flex-direction-row"
              style={{ gap: "1rem", margin: "0 auto" }}
            >
              <SecondaryButtonLevelPage onClick={removePopup}>
                Để lúc khác
              </SecondaryButtonLevelPage>
              <PrimaryButtonPageLevel
                onClick={handleClickStartExam}
                style={{ height: "4rem" }}
              >
                {JSON.parse(localStorage.getItem("listExamInfoMonthly"))
                  ?.length > 0 &&
                  JSON.parse(localStorage.getItem("listExamInfoMonthly"))[0]
                    ?.takeExamId
                  ? "Làm tiếp"
                  : "Bắt đầu ngay"}
              </PrimaryButtonPageLevel>
            </div>

            <div
              onClick={removePopup}
              className=""
              style={{
                position: "absolute",
                top: "-8%",
                left: "-4%",
                zIndex: 9999,
                background: "transparent",
                border: "none",
                width: "5rem",
                fontSize: "2rem",
              }}
            >
              <PopupCloseButton />
            </div>
          </div>
        </div>
      )}

      {showPopupFinished && (
        <div
          onClick={removePopupOutSide}
          className="modal is-active modal-background"
          style={{ opacity: 1, zIndex: 1000 }}
        >
          <div className="modal-quiz is-relative">
            <h2 style={{ color: "#0A2A66" }} className="title-popup-quiz">
              ! Hết giờ !
            </h2>
            <p className="documnent-quiz">
              Thời hạn hoàn thành Đề thi tháng {new Date().getMonth()} đã kết
              thúc. <br />
              Lần sau bạn hãy cố gắng làm sớm hơn nhé!{" "}
            </p>

            <div
              className="is-flex is-flex-direction-row"
              style={{ gap: "1rem", margin: "0 auto" }}
            >
              <PrimaryButtonPageLevel
                style={{ height: "4rem" }}
                onClick={removePopup}
              >
                Hoàn Thành
              </PrimaryButtonPageLevel>
            </div>

            <div
              onClick={removePopup}
              className=""
              style={{
                position: "absolute",
                top: "-8%",
                left: "-4%",
                zIndex: 9999,
                background: "transparent",
                border: "none",
                width: "5rem",
                fontSize: "2rem",
              }}
            >
              <PopupCloseButton />
            </div>
          </div>
        </div>
      )}

      <div className="content-hub">
        {/* <ImageFromUrl
          style={{
            borderRadius: "1rem",
            // border:
            //   "1.5px solid var(--kurio-main-colors-kurio-main-black, #0A2A66)",
          }}
          objectId="admin/frontend-images/hub/banner-homepage-acc-free.svg"
        /> */}

        <div
          onClick={() => navigate("/practice-ikmc")}
          style={{
            cursor: "pointer",
            marginBottom: "2rem"
             ,
          }}
        >
          <ImageFromUrl
            style={{
              boxShadow: "2px 4px 0px 0px #0a2a66",
              borderRadius: "1rem",
              // border:
              //   "1.5px solid var(--kurio-main-colors-kurio-main-black, #0A2A66)",
            }}
            objectId="admin/frontend-images/hub/banner-exam-question.svg"
            alt=""
          />
        </div>

        <div
          className={`
            home-page__container`}
        >
          <div className="grade-unit__group-main">
            {lastLesson?._id === null ? (
              <>
                <div style={{ width: "13rem" }}>
                  <img
                    style={{
                      position: "absolute",
                      zIndex: 2,
                      top: "2.9rem",
                      left: "0rem",
                    }}
                    src="/images/hub/image-boy-lesson.svg"
                    alt=""
                  />
                  <>
                    <ImageFromUrl
                      style={{
                        width: "10rem",
                        position: "absolute",
                        borderRadius: "1rem",
                        top: "-1.8rem",
                        boxShadow: "2px 4px 0px 0px #0a2a66",
                        // border:
                        //   "1.5px solid var(--kurio-main-colors-kurio-main-black, #0A2A66)",
                      }}
                      objectId="admin/frontend-images/hub/1-background-lesson-hub.svg"
                      alt=""
                    />

                    <div className="status-start__grade-unit">
                      <ImageFromUrl objectId="admin/frontend-images/hub/image-start-lesson.svg" alt="" />
                    </div>
                  </>
                </div>

                <div className="document-grade-unit__home-page">
                  {lastLesson?._id === null ? (
                    <>
                      <div
                        className="is-flex is-flex-direction-column"
                        style={{ gap: "0.75rem" }}
                      >
                        <div className="content-document-home-page">
                          <p
                            className="document-grade-unit has-text-kurio-main-color-black"
                            style={{
                              textAlign: "left",
                              fontWeight: "bold",
                              fontSize: "1.5rem",
                            }}
                          >
                            Bài giảng
                          </p>
                        </div>

                        <p className="documnent-start-lesson has-text-kurio-main-color-black">
                          Ôn lại và đào sâu các kiến thức đã học trên lớp.
                        </p>
                      </div>

                      <PrimaryButtonLevelSection
                        onClick={() => handleClick(firstLesson?._id)}
                      >
                        Bắt đầu học
                      </PrimaryButtonLevelSection>
                    </>
                  ) : (
                    <>
                      {checkLesson ? (
                        <>
                          <div className="content-document-home-page">
                            <p className="document-grade-unit has-text-kurio-main-color-black">
                              Bài bạn đang học đang được cập nhật.
                            </p>
                          </div>
                        </>
                      ) : (
                        <>
                          <div
                            className="is-flex is-flex-direction-column"
                            style={{ gap: "0.75rem" }}
                          >
                            <div className="content-document-home-page">
                              <p
                                className="document-grade-unit has-text-kurio-main-color-black"
                                style={{
                                  textAlign: "left",
                                  fontWeight: "bold",
                                  fontSize: "1.5rem",
                                }}
                              >
                                Bài giảng
                              </p>
                            </div>

                            <p className="documnent-start-lesson has-text-kurio-main-color-black">
                              Ôn lại và đào sâu các kiến thức đã học trên lớp.
                            </p>
                          </div>

                          <PrimaryButtonLevelSection
                            onClick={() => handleClick(firstLesson?._id)}
                          >
                            Bắt đầu học
                          </PrimaryButtonLevelSection>
                        </>
                      )}
                    </>
                  )}
                </div>
              </>
            ) : (
              <>
                <div style={{ width: "13rem" }}>
                  <img
                    style={{
                      position: "absolute",
                      zIndex: 2,
                      top: "2.9rem",
                      left: "0rem",
                    }}
                    src="/images/hub/image-boy-lesson.svg"
                    alt=""
                  />
                  {listGradeUnit && listGradeUnit?.length > 0 && lastLesson && (
                    <>
                      <ImageFromUrl
                        style={{
                          width: "10rem",
                          position: "absolute",
                          top: "-1.8rem",
                          borderRadius: "1rem",
                          boxShadow: "2px 4px 0px 0px #0a2a66",
                          // border:
                          //   "1.5px solid var(--kurio-main-colors-kurio-main-black, #0A2A66)",
                        }}
                        objectId="admin/frontend-images/hub/1-background-lesson-hub.svg"
                        alt=""
                      />

                      <div className="status__grade-unit">
                        <p className="title__grade-unit has-text-kurio-main-color-white">
                          Đang học
                        </p>
                        <div className="after-background_grade-unit">
                          {!checkLesson && (
                            <ImageFromUrl
                              style={{
                                width: "100%",
                                height: "100%",
                                objectFit: "cover",
                                borderRadius: "0.5rem",
                              }}
                              objectId={
                                lastLesson?._id !== null
                                  ? checkLesson
                                    ? ""
                                    : lastLesson?.image
                                  : listGradeUnit[0].image
                              }
                              alt=""
                            />
                          )}
                        </div>
                      </div>
                    </>
                  )}
                </div>

                <div className="document-grade-unit__home-page">
                  {lastLesson?._id === null ? (
                    <>
                      <div
                        className="is-flex is-flex-direction-column"
                        style={{ gap: "0.75rem" }}
                      >
                        <p
                          className="document-grade-unit has-text-kurio-main-color-black"
                          style={{
                            textAlign: "left",
                            fontWeight: "bold",
                            fontSize: "1.5rem",
                          }}
                        >
                          Đang học
                        </p>

                        <span className="title-grade-unit__home-page">
                          <p>
                            {listGradeUnit?.length > 0 &&
                              jsonParser(
                                listGradeUnit[0]?.unitName,
                                0,
                                "list-unit-name"
                              )}
                            {/* Chương 1 - Bài 1 */}
                          </p>
                        </span>

                        <div
                          style={{
                            fontSize: "1.25rem",
                            fontWeight: 700,
                            lineHeight: "1.4rem",
                            // cursor: "pointer",
                            display: "-webkit-box",
                            WebkitLineClamp: 2,
                            WebkitBoxOrient: "vertical",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                          className="content-document-home-page"
                        >
                          <p
                            className="document-grade-unit has-text-kurio-main-color-back"
                            style={{ textAlign: "left" }}
                          >
                            {jsonParser(firstLesson?.title, 0, "")}
                          </p>
                        </div>
                      </div>

                      <PrimaryButtonLevelSection
                        onClick={() => handleClick(lastLesson?._id)}
                      >
                        Học tiếp
                      </PrimaryButtonLevelSection>
                    </>
                  ) : (
                    <>
                      {checkLesson ? (
                        <>
                          <div className="content-document-home-page">
                            <p className="document-grade-unit has-text-kurio-main-color-black">
                              Bài bạn đang học đang được cập nhật
                            </p>
                          </div>
                        </>
                      ) : (
                        <>
                          <div
                            className="is-flex is-flex-direction-column is-align-items-flex-start"
                            style={{ gap: "0.75rem" }}
                          >
                            <p
                              className="document-grade-unit has-text-kurio-main-color-black"
                              style={{
                                textAlign: "left",
                                fontWeight: "bold",
                                fontSize: "1.5rem",
                              }}
                            >
                              Đang học
                            </p>

                            {lastLesson && lastLesson?.unitName?.length > 0 && (
                              <span className="title-grade-unit__home-page">
                                <p>
                                  {lastLesson &&
                                    lastLesson?.unitName?.length > 0 &&
                                    jsonParser(
                                      lastLesson?.unitName,
                                      0,
                                      "list-unit-name"
                                    )}
                                </p>
                              </span>
                            )}

                            <div
                              style={{
                                fontSize: "1.25rem",
                                fontWeight: 700,
                                lineHeight: "1.4rem",
                                // cursor: "pointer",
                                display: "-webkit-box",
                                WebkitLineClamp: 2,
                                WebkitBoxOrient: "vertical",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                              }}
                              className="content-document-home-page has-text-kurio-main-color-black"
                            >
                              <p
                                className="document-grade-unit"
                                style={{ textAlign: "left" }}
                              >
                                {jsonParser(lastLesson?.title, 0, "")}
                              </p>
                            </div>
                          </div>
                          <PrimaryButtonLevelSection
                            onClick={() => handleClick(lastLesson?._id)}
                          >
                            Học tiếp
                          </PrimaryButtonLevelSection>
                        </>
                      )}
                    </>
                  )}
                </div>
              </>
            )}
          </div>

          <div className="grade-unit__group-main">
            <div style={{ width: "13rem" }}>
              <img
                style={{
                  position: "absolute",
                  zIndex: 2,
                  top: "3.2rem",
                  left: "3.8rem",
                }}
                src="/images/hub/image-girl-lesson.svg"
                alt=""
              />
              <>
                <ImageFromUrl
                  style={{
                    position: "absolute",
                    top: "-1.8rem",
                    left: "0.5rem",
                  }}
                  objectId="admin/frontend-images/hub/2-background-lesson-hub.svg"
                  alt=""
                />
              </>
            </div>

            <div className="document-grade-unit__home-page">
              <div className="document-grade-unit__home-page">
                <>
                  <div
                    className="is-flex is-flex-direction-column"
                    style={{ gap: "0.75rem" }}
                  >
                    <div className="content-document-home-page">
                      <p
                        className="document-grade-unit has-text-kurio-main-color-black"
                        style={{
                          textAlign: "left",
                          fontWeight: "bold",
                          fontSize: "1.5rem",
                        }}
                      >
                        Luyện tập
                      </p>
                    </div>

                    <p className="documnent-start-lesson has-text-kurio-main-color-black">
                      Hoàn thành các bài tập thú vị và kiếm thêm điểm số!
                    </p>
                  </div>

                  <PrimaryButtonLevelSection
                    onClick={() => navigate("/exercise")}
                  >
                    Thử sức
                  </PrimaryButtonLevelSection>
                </>
              </div>
            </div>
          </div>
        </div>

        <BackgroundImageFromUrl
          objectId={
            isValidImageAtIndex(imageUrls, 7)
              ? imageUrls[7]
              : "admin/frontend-images/hub/3-banner-daily-mission-default.svg"
          }
          // ${!ConstantStrings.HIDE_GRADES.includes(gradeName) ? "" : "is-hidden"
          // } 
          className={`
            is-relative is-flex is-flex-direction-row is-justify-content-center is-align-items-center`}
          style={{
            width: "60rem",
            borderRadius: "1rem",
            boxShadow: " 2px 4px 0px 0px #0a2a66",
            border:
              "1.5px solid var(--kurio-main-colors-kurio-main-black, #0A2A66)",
            margin: "0 auto",
            height: "15rem",
            gap: "1.84rem",
            backgroundPosition: "center",
            // backgroundImage: 'url("/images/hub/3-banner-daily-mission.svg")',
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
          }}
        >
          <div
            style={{
              position: "absolute",
              top: "2rem",
              left: "2rem",
            }}
          >
            <img src="/images/hub/messerger-kurio.svg" alt="" />
          </div>
          <div style={{ width: "19.375rem", textAlign: "center" }}>
            <ImageFromUrl
              style={{
                width: "19.375rem",
                position: "relative",
                left: "2rem",
                top: "1.2rem",
              }}
              objectId={
                currentHP !== 0
                  ? startHP !== 0 &&
                    startHP / 2 < currentHP &&
                    currentHP <= startHP &&
                    questionDaily !== null
                    ? isValidImageAtIndex(imageUrls, 4)
                      ? imageUrls[4]
                      : "admin/frontend-images/hub/2-1 quai vat khoe manh.png"
                    : startHP !== 0 &&
                      currentHP <= startHP / 2 &&
                      questionDaily !== null
                      ? isValidImageAtIndex(imageUrls, 5)
                        ? imageUrls[5]
                        : "admin/frontend-images/hub/2-2 quai vat yeu.png"
                      : currentHP === 0 && questionDaily !== null
                        ? isValidImageAtIndex(imageUrls, 6)
                          ? imageUrls[6]
                          : "admin/frontend-images/hub/2-3 quai vat dau hang.png"
                        : ""
                  : ""
              }
              alt=""
            />

            <div
              className="is-flex is-flex-direction-row is-align-items-center is-justify-content-center has-background-kurio-main-color-light-gray"
              style={{
                width: "21.0625rem",
                background: "#FAFBFC",
                position: "absolute",
                zIndex: 1,
                height: "2.5rem",
                top: "12.4rem",
                left: "4.5rem",
                gap: "1rem",
                borderRadius: "1rem 1rem 0rem 0rem",
                padding: "0.5rem 0rem",
                border:
                  "1.5px solid var(--kurio-main-colors-kurio-main-black, #0A2A66)",
              }}
            >
              <div className="progress-bar-container">
                <div
                  className="progress-bar"
                  style={{
                    width: `${isNaN(currentHP / startHP)
                      ? 100
                      : (currentHP / startHP) * 100
                      }%`,
                    backgroundColor: "#E34D4D", // Màu xanh cho phần trước
                  }}
                ></div>
              </div>

              <p
                className="has-text-kurio-main-color-black"
                style={{
                  fontSize: "1.25rem",
                  fontWeight: 700,
                  textTransform: "uppercase",
                }}
              >
                <b
                  className="has-text-kurio-red-main-accent"
                  style={{ marginRight: "0.3rem" }}
                >
                  {currentHP}
                </b>
                /<b style={{ marginLeft: "0.3rem" }}>{startHP}</b>
              </p>
            </div>
          </div>

          <div className="is-relative is-flex is-flex-direction-column is-justify-content-space-evenly ">
            <img
              style={{
                width: "7.5625rem",
                position: "absolute",
                top: "1.2rem",
                right: "0rem",
                cursor: "pointer",
              }}
              onClick={handleShowPopupCalculatePoints}
              src={"/images/hub/button-calculate-points.svg"}
              alt=""
            />

            <ImageFromUrl
              style={{ cursor: "pointer", marginTop: "1.5rem" }}
              objectId={
                (questionDaily !== undefined || questionDaily !== null) &&
                  questionDaily
                    ?.map(
                      (item) => item.correct === true || item.correct === false
                    )
                    .flat()
                    .indexOf(false) !== -1
                  ? "admin/frontend-images/hub/button-daily-mission.svg"
                  : "admin/frontend-images/hub/title-finished-success-quiz-daily.svg"
              }
              alt=""
              onClick={handleClickShowPopupStartDaily}
            />
          </div>
        </BackgroundImageFromUrl>

        <div className="main__content-homepage">
          <div
            className="hub__ranking-exam"
            style={{ width: "20rem", height: "27,5rem" }}
            onClick={handleClickRanking}
          >
            <BackgroundImageFromUrl
              objectId={topRanking && topRanking?.length > 0
                ? "admin/frontend-images/hub/background-hub-ranking-result.svg"
                : "admin/frontend-images/hub/background-hub-ranking-result.svg"}
              className={`${topRanking && topRanking?.length > 0
                ? " is-flex-direction-column is-align-items-center is-justify-content-flex-end"
                : " is-align-items-flex-end is-justify-content-center"
                } hub__raking is-inline-flex`}
              style={{
                width: "100%",
                height: "100%",
                gap: "2.75rem",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeart",
                backgroundPosition: "top center",
                boxShadow: "2px 4px 0px 0px #0a2a66",
                borderRadius: "1rem",
                border:
                  "1.5px solid var(--kurio-main-colors-kurio-main-black, #0A2A66)",
              }}
            >
              {topRanking && topRanking?.length > 0 && (
                <>
                  {/* <div className="has-text-kurio-main-color-white has-text-weight-bold is-flex is-flex-direction-row is-flex-gap-7 is-align-items-center">
                  <div
                    className="is-flex is-flex-direction-row is-align-items-center"
                    style={{ gap: "0.63rem" }}
                  >
                    <p
                      style={{
                        width: "5.65rem",
                        fontSize: "1.125rem",
                        fontStyle: "normal",
                        lineHeight: "normal",
                        height: "2.75rem",
                      }}
                    >
                      Xếp hạng thi tháng
                    </p>
                    <p style={{ fontSize: "4rem" }}>8</p>
                  </div>
                  <div>
                    <NextButton />
                  </div>
                </div> */}
                  <div
                    style={{
                      position: "absolute",
                      bottom: "1.5rem",
                      left: "3.75rem",
                    }}
                  >
                    <PrimaryButtonQuestion onClick={handleClickRanking}>
                      Xem chi tiết
                    </PrimaryButtonQuestion>
                  </div>
                </>
              )}
              <div
                className="is-inline-flex is-flex-direction-column is-align-items-center is-justify-content-space-between"
                style={{ height: "17.5rem" }}
              >
                <div
                  className="is-flex is-align-items-center is-justify-content-center"
                  style={{
                    position: "absolute",
                    top: "2.5rem",
                    gap: "0.5625rem",
                    // left: "2.875rem",
                    height: "4.1875rem",
                    width: "20rem"
                    // boxShadow: "2px 4px 0px 0px #0A2A66",
                  }}
                >
                  <p
                    style={{
                      fontSize: "1.75rem",
                      color: "#FAFBFC",
                      fontWeight: 700,
                      lineHeight: "110%",
                      textShadow: "2px 3px #0A2A66",
                      fontFamily: "Dela Gothic One",
                    }}
                  >
                    Xếp hạng <br /> Thi tháng
                  </p>
                  <p
                    style={{
                      fontSize: "6rem",
                      color: "#FAFBFC",
                      fontWeight: 700,
                      lineHeight: "110%",
                      textShadow: "2px 3px #0A2A66",
                      fontFamily: "Montserrat",
                    }}
                  >
                    {convertTimeZoneUTC(new Date())}
                  </p>
                </div>
                <div
                  className="is-flex is-flex-direction-row is-relative has-text-centered"
                  style={{ height: "8.3rem", width: "15.75rem" }}
                >
                  <div
                    className="is-flex is-flex-direction-column is-align-items-center"
                    style={{
                      gap: "0.69rem",
                      position: "absolute",
                      left: "1rem",
                      top: "1.5rem",
                      width: "4rem",
                    }}
                  >
                    {topRanking && topRanking?.length > 0 && (
                      <>
                        {topRanking[0][1]?.profileImage?.length > 0 ? (
                          <ImageFromUrl
                            style={{
                              width: "4rem",
                              height: "4rem",
                              outline: "0.25rem solid #FAFBFC",
                              borderRadius: "3rem",
                            }}
                            objectId={
                              topRanking &&
                              topRanking?.length > 0 &&
                              topRanking[0][1]?.profileImage
                            }
                          />
                        ) : (
                          <ImageFromUrl
                            style={{
                              width: "4rem",
                              height: "4rem",
                              outline: "0.25rem solid #FAFBFC",
                              borderRadius: "3rem",
                            }}
                            objectId="admin/frontend-images/avatar/chim-bt.svg"
                            alt=""
                          />
                        )}
                      </>
                    )}
                    {topRanking && topRanking?.length > 0 && (
                      <span
                        className="has-text-kurio-main-color-white has-text-weight-bold has-background-kurio-red-main-accent"
                        style={{
                          fontSize: "0.75rem",
                          fontStyle: "normal",
                          borderRadius: "1rem",
                          padding: "0.25rem 0.5rem",
                          boxShadow: " 2px 2px 0px 0px #0A2A66",
                        }}
                      >
                        {topRanking &&
                          topRanking?.length > 0 &&
                          topRanking[0]?.length > 1 &&
                          findLastItemNonEmpty(
                            topRanking[0][1]?.fullname?.split(" ")
                          )}
                      </span>
                    )}
                  </div>
                  <div
                    className="is-flex is-flex-direction-column is-align-items-center"
                    style={{
                      gap: "0.69rem",
                      width: "6rem",
                      position: "absolute",
                      top: "-3rem",
                      left: "4.75rem",
                    }}
                  >
                    {topRanking && topRanking?.length > 0 && (
                      <>
                        {topRanking[0][0]?.profileImage?.length > 0 ? (
                          <ImageFromUrl
                            style={{
                              width: "6rem",
                              height: "6rem",
                              outline: "0.25rem solid #FAFBFC",
                              borderRadius: "3rem",
                            }}
                            objectId={
                              topRanking &&
                              topRanking?.length > 0 &&
                              topRanking[0][0]?.profileImage
                            }
                          />
                        ) : (
                          <ImageFromUrl
                            style={{
                              width: "6rem",
                              height: "6rem",
                              outline: "0.25rem solid #FAFBFC",
                              borderRadius: "3rem",
                            }}
                            objectId="admin/frontend-images/avatar/chim-bt.svg"
                            alt=""
                          />
                        )}
                      </>
                    )}
                    {topRanking && topRanking?.length > 0 && (
                      <span
                        className="has-text-kurio-main-color-black has-text-weight-bold has-background-kurio-main-color-yellow"
                        style={{
                          fontSize: "0.75rem",
                          fontStyle: "normal",
                          width: "4rem",
                          borderRadius: "1rem",
                          padding: "0.25rem 0.5rem",
                          boxShadow: " 2px 2px 0px 0px #0A2A66",
                        }}
                      >
                        {topRanking &&
                          topRanking?.length > 0 &&
                          findLastItemNonEmpty(
                            topRanking[0][0]?.fullname?.split(" ")
                          )}
                      </span>
                    )}
                  </div>
                  <div
                    className="is-flex is-flex-direction-column is-align-items-center"
                    style={{
                      gap: "0.69rem",
                      position: "absolute",
                      width: "4rem",
                      top: "2.5rem",
                      right: "1rem",
                    }}
                  >
                    {topRanking && topRanking?.length > 0 && (
                      <>
                        {topRanking[0][2]?.profileImage?.length > 0 ? (
                          <ImageFromUrl
                            style={{
                              width: "4rem",
                              height: "4rem",
                              outline: "0.25rem solid #FAFBFC",
                              borderRadius: "3rem",
                            }}
                            objectId={
                              topRanking &&
                              topRanking?.length > 0 &&
                              topRanking[0][2]?.profileImage
                            }
                          />
                        ) : (
                          <ImageFromUrl
                            style={{
                              width: "4rem",
                              height: "4rem",
                              outline: "0.25rem solid #FAFBFC",
                              borderRadius: "3rem",
                            }}
                            objectId="admin/frontend-images/avatar/chim-bt.svg"
                            alt=""
                          />
                        )}
                      </>
                    )}
                    {topRanking && topRanking?.length > 0 && (
                      <span
                        className="has-text-kurio-main-color-white has-text-weight-bold has-background-kurio-blue-main-accent"
                        style={{
                          fontSize: "0.75rem",
                          fontStyle: "normal",
                          borderRadius: "1rem",
                          padding: "0.25rem 0.5rem",
                          boxShadow: " 2px 2px 0px 0px #0A2A66",
                        }}
                      >
                        {topRanking &&
                          topRanking?.length > 0 &&
                          topRanking[0]?.length > 2 &&
                          findLastItemNonEmpty(
                            topRanking[0][2]?.fullname?.split(" ")
                          )}
                      </span>
                    )}
                  </div>
                </div>
                <ImageFromUrl
                  style={{ width: "15.75rem", height: "11.5rem" }}
                  objectId="admin/frontend-images/hub/buc-ranking-hub.svg"
                  alt=""
                />
              </div>
            </BackgroundImageFromUrl>
          </div>

          <div
            className="is-flex is-flex-direction-column"
            style={{ gap: "1.4375rem" }}
          >
            {examMonthly?.length > 0 ? (
              <BackgroundImageFromUrl
                objectId={
                  isValidImageAtIndexMonthly(listMonthlyExams, 0)
                    ? listMonthlyExams[0]?.images[0]
                    : "admin/frontend-images/hub/background-default-hub-monthly-exam.svg"
                }
                onClick={handleFocusHubExam}
                className="hub__exam-solution"
              >
                <div
                  className={
                    (JSON.parse(localStorage.getItem("listExamInfoMonthly"))
                      ?.length > 0 &&
                      JSON.parse(localStorage.getItem("listExamInfoMonthly"))[0]
                        ?.takeExamLP !== null) ||
                      (deadline &&
                        serverTime &&
                        new Date(deadline).getTime() -
                        new Date(serverTime).getTime() <=
                        0)
                      ? "add-css-content-card-monthly-exam"
                      : " content-card-monthly-exam"
                  }
                >
                  <div
                    className={
                      (JSON.parse(localStorage.getItem("listExamInfoMonthly"))
                        ?.length > 0 &&
                        JSON.parse(
                          localStorage.getItem("listExamInfoMonthly")
                        )[0]?.takeExamLP !== null) ||
                        (deadline &&
                          serverTime &&
                          new Date(deadline).getTime() -
                          new Date(serverTime).getTime() <=
                          0)
                        ? "time-finished-card"
                        : "add-create-time"
                    }
                  >
                    <p>Còn lại</p>
                  </div>
                  {isLoading ? (
                    <span className="loader"></span>
                  ) : (
                    <Countdown
                      key="countdown1"
                      date={Date.now() + countdown}
                      renderer={rerender}
                    />
                  )}

                  {isLoading ? (
                    <span className="loader"></span>
                  ) : (JSON.parse(localStorage.getItem("listExamInfoMonthly"))
                    ?.length > 0 &&
                    JSON.parse(localStorage.getItem("listExamInfoMonthly"))[0]
                      ?.takeExamLP !== null) ||
                    (deadline &&
                      serverTime &&
                      new Date(deadline).getTime() -
                      new Date(serverTime).getTime() <=
                      0) ? (
                    <ImageFromUrl
                      objectId="admin/frontend-images/hub/button-success-exam-monthly.svg"
                      alt=""
                    />
                  ) : (
                    <PrimaryButtonPageLevel
                      onClick={handleClickShowPopup}
                      style={{ height: "4rem" }}
                    >
                      {JSON.parse(localStorage.getItem("listExamInfoMonthly"))
                        ?.length > 0 &&
                        JSON.parse(localStorage.getItem("listExamInfoMonthly"))[0]
                          ?.takeExamId
                        ? "Làm Tiếp"
                        : "Thử Sức"}
                    </PrimaryButtonPageLevel>
                  )}
                </div>
              </BackgroundImageFromUrl>
            ) : (
              <BackgroundImageFromUrl
                objectId={
                  isValidImageAtIndexMonthly(listMonthlyExams, 0)
                    ? listMonthlyExams[0]?.images[0]
                    : "admin/frontend-images/hub/background-default-hub-monthly-exam.svg"
                }
                onClick={handleFocusHubExam}
                className="hub__exam-solution"
              >
                <div
                  className={
                    (JSON.parse(localStorage.getItem("listExamInfoMonthly"))
                      ?.length > 0 &&
                      JSON.parse(localStorage.getItem("listExamInfoMonthly"))[0]
                        ?.takeExamLP !== null) ||
                      (deadline &&
                        serverTime &&
                        new Date(deadline).getTime() -
                        new Date(serverTime).getTime() <=
                        0)
                      ? "add-css-content-card-monthly-exam "
                      : "content-card-monthly-exam "
                  }
                >
                  <div>
                    <div
                      className={
                        (JSON.parse(localStorage.getItem("listExamInfoMonthly"))
                          ?.length > 0 &&
                          JSON.parse(
                            localStorage.getItem("listExamInfoMonthly")
                          )[0]?.takeExamLP !== null) ||
                          (deadline &&
                            serverTime &&
                            new Date(deadline).getTime() -
                            new Date(serverTime).getTime() <=
                            0)
                          ? "time-finished-card"
                          : "add-create-time"
                      }
                    >
                      <p>Còn lại</p>
                    </div>
                    <Countdown
                      key="countdown3"
                      date={Date.now() - 1}
                      renderer={rerender}
                    />
                  </div>

                  {(JSON.parse(localStorage.getItem("listExamInfoMonthly"))
                    ?.length > 0 &&
                    JSON.parse(localStorage.getItem("listExamInfoMonthly"))[0]
                      ?.takeExamLP !== null) ||
                    (deadline &&
                      serverTime &&
                      new Date(deadline).getTime() -
                      new Date(serverTime).getTime() <=
                      0) ||
                    localStorage.getItem("successExam") ? (
                    <ImageFromUrl
                      objectId="admin/frontend-images/hub/button-success-exam-monthly.svg"
                      alt=""
                    />
                  ) : (
                    <PrimaryButtonPageLevel
                      // onClick={handleClickShowPopup}
                      disabled={true}
                      style={{ height: "4rem" }}
                    >
                      Thử Sức
                    </PrimaryButtonPageLevel>
                  )}
                </div>
              </BackgroundImageFromUrl>
            )}
            {!localStorage.getItem("examMonthlyNow") ||
              localStorage.getItem("examMonthlyNow") === undefined ||
              (JSON.parse(localStorage.getItem("solutionInfo")) &&
                JSON.parse(localStorage.getItem("listExamInfoMonthly")) &&
                JSON.parse(localStorage.getItem("listExamInfoMonthly"))?.length >
                0 &&
                JSON.parse(localStorage.getItem("solutionInfo"))[0]?._id !==
                JSON.parse(localStorage.getItem("listExamInfoMonthly"))[0]
                  ?.id) ? (
              <BackgroundImageFromUrl
                objectId={
                  isValidImageAtIndexMonthly(listMonthlyExams, 2)
                    ? listMonthlyExams[0]?.images[2]
                    : "admin/frontend-images/hub/background-default-hub-solution-exam.svg"
                }
                className="hub__show-solution-exam"
                style={{ backgroundSize: "cover" }}
              >
                <div className="content-card-hub-solution">
                  <div className="time-finished-card">
                    <div className="time-finished-card-show-solution">
                      <p>Điểm tháng trước</p>
                    </div>

                    <div className="time-content-card-show-solution">
                      {isLoading ? (
                        <span className="loader"></span>
                      ) : !iconSolution ? (
                        <span>
                          {currentScore} / {maxScore}
                        </span>
                      ) : (
                        <ImageFromUrl
                          objectId="admin/frontend-images/hub/icon-point-exam.svg"
                          alt=""
                        />
                      )}
                    </div>
                  </div>
                  <PrimaryButtonLevelSection
                    disabled={!JSON.parse(localStorage.getItem("solutionInfo"))}
                    onClick={handleClickShowSolution}
                  >
                    Xem Lời Giải
                  </PrimaryButtonLevelSection>
                </div>
              </BackgroundImageFromUrl>
            ) : (
              <BackgroundImageFromUrl
                objectId={
                  isValidImageAtIndexMonthly(listMonthlyExams, 2)
                    ? listMonthlyExams[0]?.images[2]
                    : "admin/frontend-images/hub/background-default-hub-solution-exam.svg"
                }
                className="hub__solution"
                style={{ backgroundSize: "cover" }}
              >
                <div className="content-card-hub-solution">
                  <div className="time-finished-card">
                    <p>Xem lời giải</p>
                  </div>{" "}
                  {isLoading ? (
                    <span className="loader"></span>
                  ) : (
                    <Countdown
                      key="countdown2"
                      date={Date.now() + countdown}
                      renderer={rerender}
                    />
                  )}
                </div>
              </BackgroundImageFromUrl>
            )}
          </div>
        </div>

        {/* <div className="hub__card-promoted-feature">
          <div
            className="group-promoted"
            onClick={() => navigate("/activities/exams-vault")}
          >
            <img src="/images/hub/icon-hub-exam.svg" alt="" />

            <p>Đề thi IKMC theo năm</p>
          </div>
          <div className="group-promoted">
            <ImageFromUrl
              objectId="admin/frontend-images/hub/icon-hub-customer-care.svg"
              alt=""
            />

            <p>Chăm sóc Khách hàng</p>
          </div>
          <div className="group-promoted">
            <ImageFromUrl
              objectId="admin/frontend-images/hub/icon-hub-manual.svg"
              alt=""
            />

            <p>Hướng dẫn Sử dụng</p>
          </div>
        </div> */}
      </div>

      <div
        className="footer-hub"
        style={{ padding: "0rem 4.5rem 3rem 4.5rem" }}
      >
        <hr style={{ marginBottom: "4rem" }} />

        <div
          className="content-footer-hub is-flex is-flex-direction-row is-justify-content-space-between is-align-content-center"
          style={{ width: "50rem" }}
        >
          <img src="/images/menu/logo.svg" alt="" />

          <ul>
            <li
            >
              <Link to={"/list-unit"}>Bài giảng</Link>
            </li>
            <li
            >
              <Link to={"/exercise"}>Luyện tập</Link>
            </li>

            <li
              // className={`${!ConstantStrings.HIDE_GRADES.includes(gradeName)
              //   ? ""
              //   : "is-hidden"
              //   }`}
            >
              <Link to={"/activities"}>Nhiệm vụ</Link>
            </li>
            <li>
              <Link to={"/monthly-exams"}>Thi tháng</Link>
            </li>
            <li>
              <Link to={"/practice-ikmc"}>Luyện thi</Link>
            </li>
          </ul>

          <ul>
            <li>
              <Link to={"/account"}>Tài khoản</Link>
            </li>
            <li>
              <Link to={"/profile"}>Sổ liên lạc</Link>
            </li>
            <li>
              <Link to={"/achievement"}>Thành tích</Link>
            </li>
            <li>
              <Link to={"/upgrade"}>Nâng cấp</Link>
            </li>
            {/* <li>
              <Link to={"/about-us"}>Giới thiệu</Link>
            </li> */}
            {/* <li onClick={() => handleChangePopupIntro(!showPopupIntro)}>
              Hướng dẫn sử dụng
            </li> */}
            {/* <li>Chăm sóc khách hàng</li>
            <li>Hướng dẫn sử dụng</li> */}
          </ul>
        </div>
        <div
          style={{
            padding: "1rem 0rem",
            gap: "1.5rem",
            borderRadius: "1rem",
            marginTop: "4rem",
          }}
          className="has-background-kurio-main-color-light-gray is-flex is-justify-content-center is-align-items-center"
        >
          <p
            className="has-text-kurio-main-color-black"
            style={{ fontSize: "1.125rem" }}
          >
            Liên hệ với Kurio qua Zalo QR
          </p>

          <ImageFromUrl
            style={{
              width: "5rem",
              height: "5rem",
            }}
            objectId="admin/frontend-images/hub/QRcode.svg"
            alt=""
          />

          <p
            className="has-text-kurio-main-color-black"
            style={{ fontSize: "1.125rem" }}
          >
            hoặc Zalo
          </p>

          <p
            className="has-text-kurio-main-color-black"
            style={{ fontSize: "1.5rem", fontWeight: 700 }}
          >
            0971 500 120
          </p>
        </div>
        <hr style={{ marginTop: "4rem" }} />

        <span>All rights reserved @ KURIO 2023</span>
      </div>

      {/* <div
          className="modal is-active modal-background"
          style={{
            opacity: 1,
            zIndex: 1000,
            backgroundImage:
              'url("/images/hub/background-popup-daily-mission.svg")',
            width: "100%",
            height: "100%",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          <BackgroundImageFromUrl
            // objectId={
            //   isValidImageAtIndex(imageUrls, 3)
            //     ? imageUrls[3]
            //     : "admin/frontend-images/hub/1 bg popup.jpg"
            // }
            className="is-relative "
            objectId={
              defaultSelected === 0
                ? isValidImageAtIndex(imageUrls, 3)
                  ? imageUrls[3]
                  : "admin/frontend-images/hub/background-1-popup-daily-mission.svg"
                : defaultSelected === 1
                ? "admin/frontend-images/hub/background-daily-monthly-exam.svg"
                : defaultSelected === 2
                ? "admin/frontend-images/hub/background-daily-exam-vault.svg"
                : defaultSelected === 3
                ? "admin/frontend-images/hub/background-daily-exercise.svg"
                : defaultSelected === 4
                ? "admin/frontend-images/hub/background-daily-list-unit.svg"
                : ""
            }
            style={{
              width: "56.875rem",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
              backgroundSize: "cover",
              height: "34.125rem",
              borderRadius: "2.5rem",
              position: "relative",
            }}
          >
            <div>
              <div
                className="is-flex is-flex-direction-row "
                style={{
                  width: "50.5rem",
                  gap: "1rem",
                  borderRadius: "1.5rem",
                  background: "#fff",
                  border:
                    "1.5px solid var(--kurio-main-colors-kurio-main-black, #0A2A66)",
                  textAlign: "center",
                  padding: "0.75rem",
                  zIndex: 10,
                  position: "absolute",
                  bottom: "-2rem",
                  left: "3.3rem",
                }}
              >
                {MenuPopupDaily.map((item, index) => {
                  return (
                    <div
                      key={item._id}
                      onClick={() => onHandleClickMenu(item.pathName, item._id)}
                      className="is-flex is-flex-direction-column is-justify-content-center"
                      style={
                        item.pathName === selectedMenu
                          ? divDefaultStyle
                          : unDefaultStyle
                      }
                    >
                      <p
                        className="is-flex is-align-items-center"
                        style={{
                          fontSize: "0.875rem",
                          fontWeight: 700,
                          color: "#0A2A66",
                          lineHeight: "normal",
                          padding: "0.625rem 1rem",
                          gap: "1rem",
                        }}
                      >
                        <img
                          style={{ width: "1.125rem", height: "1.125rem" }}
                          src={item.icon}
                          alt=""
                        />{" "}
                        {item.title}
                      </p>
                    </div>
                  );
                })}
              </div>

              {defaultSelected === 0 ? (
                <div
                  className="is-flex is-justify-content-center"
                  style={{ width: "100%" }}
                >
                  <div>
                    <div
                      style={{
                        position: "absolute",
                        top: "4.44rem",
                        left: "19rem",
                      }}
                    >
                      <img src="/images/hub/messerger-kurio.svg" alt="" />
                    </div>

                    <ImageFromUrl
                      style={{
                        right: "3.3125rem",
                        bottom: "1.1875rem",
                        position: "absolute",
                        width: "42.5625rem",
                      }}
                      objectId={
                        startHP !== 0 &&
                        startHP / 2 < currentHP &&
                        currentHP <= startHP &&
                        questionDaily !== null
                          ? isValidImageAtIndex(imageUrls, 0)
                            ? imageUrls[0]
                            : "admin/frontend-images/hub/0-1 quai vat khoe manh.png"
                          : startHP !== 0 &&
                            currentHP <= startHP / 2 &&
                            questionDaily !== null
                          ? isValidImageAtIndex(imageUrls, 1)
                            ? imageUrls[1]
                            : "admin/frontend-images/hub/0-2 quai vat yeu.png"
                          : currentHP === 0 && questionDaily !== null
                          ? isValidImageAtIndex(imageUrls, 2)
                            ? imageUrls[2]
                            : "admin/frontend-images/hub/0-3 quai vat dau hang.png"
                          : ""
                      }
                      alt=""
                    />

                    <div
                      className="is-flex is-flex-direction-column is-align-items-center"
                      style={{
                        gap: "1rem",
                        position: "absolute",
                        top: "9.19rem",
                        left: "4rem",
                        zIndex: "10",
                      }}
                    >
                      <div className="is-relative is-flex is-flex-direction-row is-justify-content-space-evenly">
                        <ImageFromUrl
                          style={{ cursor: "pointer" }}
                          objectId={
                            (questionDaily !== undefined ||
                              questionDaily !== null) &&
                            questionDaily
                              ?.map(
                                (item) =>
                                  item.correct === true ||
                                  item.correct === false
                              )
                              .flat()
                              .indexOf(false) !== -1
                              ? "admin/frontend-images/hub/button-popup-daily-mission.svg"
                              : "admin/frontend-images/hub/button-finished-success-daily-mission.svg"
                          }
                          alt=""
                          onClick={handleClickShowPopupStartDaily}
                        />
                      </div>

                      <div
                        className="is-flex is-flex-direction-row is-align-items-center is-justify-content-center "
                        style={{
                          width: "17.4375rem",
                          background: "#FAFBFC",
                          zIndex: 10,
                          height: "2.5rem",
                          gap: "0.75rem",
                          borderRadius: "1rem 1rem 1rem 1rem",
                          padding: "0.5rem 0rem",
                          border:
                            "2px solid var(--kurio-main-colors-kurio-main-black, #0A2A66)",
                        }}
                      >
                        <div className="progress-bar-nav-bar">
                          <div
                            className="progress-bar"
                            style={{
                              width: `${(currentHP / startHP) * 100}%`,
                              backgroundColor: "#E34D4D", // Màu xanh cho phần trước
                            }}
                          ></div>
                        </div>

                        <p
                          className="has-text-kurio-main-color-black"
                          style={{
                            fontSize: "1.25rem",
                            fontWeight: 700,
                            textTransform: "uppercase",
                          }}
                        >
                          <b
                            className="has-text-kurio-red-main-accent"
                            style={{ marginRight: "0.3rem" }}
                          >
                            {currentHP}
                          </b>
                          /<b style={{ marginLeft: "0.3rem" }}>{startHP}</b>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              ) : defaultSelected === 1 ? (
                <div>
                  <p
                    className="has-text-kurio-main-color-black"
                    style={{
                      position: "absolute",
                      top: "7.9rem",
                      fontWeight: 400,
                      left: "6.9rem",
                      fontSize: "3rem",
                      fontFamily: "Dela Gothic One",
                    }}
                  >
                    Thi tháng
                  </p>

                  <div className="nar-bar-exam-monthly">
                    <div
                      className={
                        (JSON.parse(localStorage.getItem("listExamInfoMonthly"))
                          ?.length > 0 &&
                          JSON.parse(
                            localStorage.getItem("listExamInfoMonthly")
                          )[0]?.takeExamLP !== null) ||
                        (deadline &&
                          serverTime &&
                          new Date(deadline).getTime() -
                            new Date(serverTime).getTime() <=
                            0)
                          ? "add-css-content-card-monthly-exam"
                          : " content-card-monthly-exam"
                      }
                    >
                      <div
                        className={
                          (JSON.parse(
                            localStorage.getItem("listExamInfoMonthly")
                          )?.length > 0 &&
                            JSON.parse(
                              localStorage.getItem("listExamInfoMonthly")
                            )[0]?.takeExamLP !== null) ||
                          (deadline &&
                            serverTime &&
                            new Date(deadline).getTime() -
                              new Date(serverTime).getTime() <=
                              0)
                            ? "time-finished-card"
                            : "add-create-time"
                        }
                      >
                        <p>Còn lại</p>
                      </div>
                      {isLoading ? (
                        <span className="loader"></span>
                      ) : (
                        <Countdown
                          key="countdown1"
                          date={Date.now() + countdown}
                          renderer={rerender}
                        />
                      )}

                      {isLoading ? (
                        <span className="loader"></span>
                      ) : (JSON.parse(
                          localStorage.getItem("listExamInfoMonthly")
                        )?.length > 0 &&
                          JSON.parse(
                            localStorage.getItem("listExamInfoMonthly")
                          )[0]?.takeExamLP !== null) ||
                        (deadline &&
                          serverTime &&
                          new Date(deadline).getTime() -
                            new Date(serverTime).getTime() <=
                            0) ? (
                        <ImageFromUrl
                          objectId="admin/frontend-images/hub/button-success-exam-monthly.svg"
                          alt=""
                        />
                      ) : (
                        <PrimaryButtonPageLevel
                          onClick={handleClickShowPopup}
                          style={{ height: "4rem", borderRadius: "1rem" }}
                        >
                          {JSON.parse(
                            localStorage.getItem("listExamInfoMonthly")
                          )?.length > 0 &&
                          JSON.parse(
                            localStorage.getItem("listExamInfoMonthly")
                          )[0]?.takeExamId
                            ? "Làm Tiếp"
                            : "Làm ngay"}
                        </PrimaryButtonPageLevel>
                      )}
                    </div>
                  </div>

                  <p
                    onClick={() => handleCancelPopup(defaultSelected)}
                    className="has-text-kurio-main-color-black is-flex align-is-align-items-center"
                    style={
                      (JSON.parse(localStorage.getItem("listExamInfoMonthly"))
                        ?.length > 0 &&
                        JSON.parse(
                          localStorage.getItem("listExamInfoMonthly")
                        )[0]?.takeExamLP !== null) ||
                      (deadline &&
                        serverTime &&
                        new Date(deadline).getTime() -
                          new Date(serverTime).getTime() <=
                          0)
                        ? {
                            position: "absolute",
                            bottom: "8.9rem",
                            fontWeight: 500,
                            left: "11.2rem",
                            gap: "0.75rem",
                            fontSize: "1.125rem",
                            cursor: "pointer",
                          }
                        : {
                            position: "absolute",
                            bottom: "7.5rem",
                            fontWeight: 500,
                            left: "11.2rem",
                            gap: "0.75rem",
                            fontSize: "1.125rem",
                            cursor: "pointer",
                          }
                    }
                  >
                    Thông tin <img src="/images/menu/icon-out.svg" alt="" />
                  </p>
                </div>
              ) : defaultSelected === 2 ? (
                <img
                  onClick={() => handleCancelPopup(defaultSelected)}
                  style={{
                    position: "absolute",
                    top: "20.69rem",
                    left: "7.94rem",
                    cursor: "pointer",
                  }}
                  src="/images/hub/button-click-daily-exam-vault.svg"
                  alt=""
                />
              ) : defaultSelected === 3 ? (
                <div
                  style={{ position: "relative", top: "8.56rem", left: "5rem" }}
                >
                  <div
                    style={{
                      width: "17.5rem",
                      height: "14rem",
                      gap: "1rem",
                      borderRadius: "2.5rem",
                      border:
                        "1.5px solid var(--kurio-main-colors-kurio-main-black, #0A2A66)",
                      boxShadow: "2px 2px 0px 0px #0A2A66",
                      backgroundColor: "#FAFBFC",
                    }}
                    className="is-flex is-flex-direction-column is-justify-content-center is-align-items-center"
                  >
                    <p
                      className="has-text-kurio-main-color-black"
                      style={{
                        fontSize: "2rem",
                        fontWeight: 400,
                        fontFamily: "Dela Gothic One",
                      }}
                    >
                      Luyện tập
                    </p>

                    <span
                      className="has-text-kurio-main-color-black"
                      style={{
                        fontSize: "1.125rem",
                        fontWeight: 500,
                        textAlign: "center",
                        width: "11.625rem",
                        lineHeight: "normal",
                      }}
                    >
                      Hoàn thành các bài tập thú vị và kiếm thêm điểm số!
                    </span>
                  </div>

                  <div
                    style={{
                      position: "absolute",
                      bottom: "-2.5rem",
                      left: "1.3rem",
                    }}
                  >
                    <PrimaryButtonPageLevel
                      onClick={() => handleCancelPopup(defaultSelected)}
                      style={{ height: "4rem", borderRadius: "1rem" }}
                    >
                      Làm ngay
                    </PrimaryButtonPageLevel>
                  </div>
                </div>
              ) : defaultSelected === 4 ? (
                <div
                  style={{ position: "relative", top: "8.56rem", left: "5rem" }}
                >
                  <div
                    style={{
                      width: "17.5rem",
                      height: "14rem",
                      gap: "1rem",
                      borderRadius: "2.5rem",
                      border:
                        "1.5px solid var(--kurio-main-colors-kurio-main-black, #0A2A66)",
                      boxShadow: "2px 2px 0px 0px #0A2A66",
                      backgroundColor: "#FAFBFC",
                    }}
                    className="is-flex is-flex-direction-column is-justify-content-center is-align-items-center"
                  >
                    {lastLesson?._id === null ? (
                      <>
                        <div style={{ width: "13rem" }}>
                          <img
                            style={{
                              position: "absolute",
                              zIndex: 2,
                              top: "-2rem",
                              left: "19rem",
                            }}
                            src="/images/hub/cu-Nam.svg"
                            alt=""
                          />
                          <div className="status-start__grade-unit">
                            <img
                              style={{
                                position: "absolute",
                                width: "100%",
                                left: "27rem",
                                top: "1rem",
                              }}
                              src="/images/hub/image-start-lesson.svg"
                              alt=""
                            />
                          </div>
                        </div>
                        <div className="document-grade-unit__home-page">
                          {lastLesson?._id === null ? (
                            <>
                              <div
                                className="is-flex is-flex-direction-column"
                                style={{
                                  gap: "0.75rem",
                                  marginTop: "-1.5rem",
                                  marginLeft: "1rem",
                                }}
                              >
                                <div className="content-document-home-page">
                                  <p
                                    className="document-grade-unit has-text-kurio-main-color-black"
                                    style={{
                                      textAlign: "center",
                                      fontWeight: "bold",
                                      fontSize: "2rem",
                                    }}
                                  >
                                    Bài giảng
                                  </p>
                                </div>

                                <p className="documnent-start-lesson has-text-kurio-main-color-black">
                                  Ôn lại và đào sâu các kiến thức đã học trên
                                  lớp.
                                </p>
                              </div>

                              <div
                                style={{
                                  position: "absolute",
                                  bottom: "-2.5rem",
                                  left: "1.3rem",
                                }}
                              >
                                <PrimaryButtonPageLevel
                                  onClick={() => handleClick(firstLesson?._id)}
                                  style={{
                                    height: "4rem",
                                    borderRadius: "1rem",
                                  }}
                                >
                                  Bắt đầu học
                                </PrimaryButtonPageLevel>
                              </div>
                            </>
                          ) : (
                            <>
                              {checkLesson ? (
                                <>
                                  <div className="content-document-home-page">
                                    <p className="document-grade-unit has-text-kurio-main-color-black">
                                      Bài bạn đang học đang được cập nhật.
                                    </p>
                                  </div>
                                </>
                              ) : (
                                <>
                                  <div
                                    className="is-flex is-flex-direction-column"
                                    style={{ gap: "0.75rem" }}
                                  >
                                    <div className="content-document-home-page">
                                      <p
                                        className="document-grade-unit has-text-kurio-main-color-black"
                                        style={{
                                          textAlign: "center",
                                          fontWeight: "bold",
                                          fontSize: "2rem",
                                        }}
                                      >
                                        Bài giảng
                                      </p>
                                    </div>

                                    <p className="documnent-start-lesson has-text-kurio-main-color-black">
                                      Ôn lại và đào sâu các kiến thức đã học
                                      trên lớp.
                                    </p>
                                  </div>

                                  <div
                                    style={{
                                      position: "absolute",
                                      bottom: "-2.5rem",
                                      left: "1.3rem",
                                    }}
                                  >
                                    <PrimaryButtonPageLevel
                                      onClick={() =>
                                        handleClick(firstLesson?._id)
                                      }
                                      style={{
                                        height: "4rem",
                                        borderRadius: "1rem",
                                      }}
                                    >
                                      Bắt đầu học
                                    </PrimaryButtonPageLevel>
                                  </div>
                                </>
                              )}
                            </>
                          )}
                        </div>
                      </>
                    ) : (
                      <>
                        <div style={{ width: "13rem" }}>
                          <img
                            style={{
                              position: "absolute",
                              zIndex: 2,
                              top: "-2rem",
                              left: "19rem",
                            }}
                            src="/images/hub/cu-Nam.svg"
                            alt=""
                          />

                          {listGradeUnit &&
                            listGradeUnit?.length > 0 &&
                            lastLesson && (
                              <>
                                <div className="status__grade-unit-narbar">
                                  <p className="title__grade-unit has-text-kurio-main-color-white">
                                    Đang học
                                  </p>
                                  <div className="after-background_grade-unit">
                                    {!checkLesson && (
                                      <ImageFromUrl
                                        style={{
                                          width: "100%",
                                          height: "100%",
                                          objectFit: "cover",
                                          borderRadius: "0.5rem",
                                        }}
                                        objectId={
                                          lastLesson?._id !== null
                                            ? checkLesson
                                              ? ""
                                              : findImageUnit(
                                                  lastLesson?.unitName
                                                )
                                            : listGradeUnit[0].image
                                        }
                                        alt=""
                                      />
                                    )}
                                  </div>
                                </div>
                              </>
                            )}
                        </div>

                        <div className="document-grade-unit__home-page">
                          {lastLesson?._id === null ? (
                            <>
                              <div
                                className="is-flex is-flex-direction-column"
                                style={{
                                  gap: "0.75rem",
                                  marginTop: "-2.5rem",
                                }}
                              >
                                <p
                                  className="document-grade-unit has-text-kurio-main-color-black"
                                  style={{
                                    textAlign: "left",
                                    fontWeight: "bold",
                                    fontSize: "1.5rem",
                                  }}
                                >
                                  Đang học
                                </p>

                                <span className="title-grade-unit__popup-narbar">
                                  <p>
                                    {listGradeUnit?.length > 0 &&
                                      jsonParser(
                                        listGradeUnit[0]?.unitName,
                                        0,
                                        "list-unit-name"
                                      )}
                                    {/* Chương 1 - Bài 1
                                  </p>
                                </span>

                                <div
                                  style={{
                                    fontSize: "1.25rem",
                                    fontWeight: 700,
                                    lineHeight: "1.4rem",
                                    height: "2.75rem",
                                    cursor: "pointer",
                                    display: "-webkit-box",
                                    WebkitLineClamp: 2,
                                    WebkitBoxOrient: "vertical",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                  }}
                                  className="content-document-home-page"
                                >
                                  <p
                                    className="has-text-kurio-main-color-back"
                                    style={{ textAlign: "left" }}
                                  >
                                    {firstLesson?.title}
                                  </p>
                                </div>
                              </div>

                              <div
                                style={{
                                  position: "absolute",
                                  bottom: "-2.5rem",
                                  left: "1.3rem",
                                }}
                              >
                                <PrimaryButtonPageLevel
                                  onClick={() => handleClick(lastLesson?._id)}
                                  style={{
                                    height: "4rem",
                                    borderRadius: "1rem",
                                  }}
                                >
                                  Học tiếp
                                </PrimaryButtonPageLevel>
                              </div>
                            </>
                          ) : (
                            <>
                              {checkLesson ? (
                                <>
                                  <div className="content-document-home-page">
                                    <p className="document-grade-unit has-text-kurio-main-color-black">
                                      Bài bạn đang học đang được cập nhật
                                    </p>
                                  </div>
                                </>
                              ) : (
                                <>
                                  <div
                                    className="is-flex is-flex-direction-column is-align-items-flex-start"
                                    style={{
                                      gap: "0.75rem",
                                      marginTop: " -2.5rem",
                                    }}
                                  >
                                    <p
                                      className="document-grade-unit has-text-kurio-main-color-black"
                                      style={{
                                        textAlign: "left",
                                        fontWeight: "bold",
                                        fontSize: "1.5rem",
                                      }}
                                    >
                                      Đang học
                                    </p>

                                    {lastLesson &&
                                      lastLesson?.unitName?.length > 0 &&
                                      lastLesson?.order !== undefined && (
                                        <span
                                          style={{ display: "-webkit-box" }}
                                          className="title-grade-unit__popup-narbar"
                                        >
                                          <p>
                                            {lastLesson &&
                                              lastLesson?.unitName?.length >
                                                0 &&
                                              jsonParser(
                                                lastLesson?.unitName,
                                                0,
                                                "list-unit-name"
                                              )}
                                          </p>
                                        </span>
                                      )}

                                    <div
                                      style={{
                                        fontSize: "1.25rem",
                                        fontWeight: 700,
                                        lineHeight: "1.4rem",
                                        height: "2.75rem",
                                        // cursor: "pointer",
                                        display: "-webkit-box",
                                        WebkitLineClamp: 2,
                                        WebkitBoxOrient: "vertical",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                      }}
                                      className="content-document-home-page has-text-kurio-main-color-black"
                                    >
                                      <p
                                        // className="document-grade-unit"
                                        style={{ textAlign: "left" }}
                                      >
                                        {lastLesson?.title}
                                      </p>
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      position: "absolute",
                                      bottom: "-2.5rem",
                                      left: "1.3rem",
                                    }}
                                  >
                                    <PrimaryButtonPageLevel
                                      onClick={() =>
                                        handleClick(lastLesson?._id)
                                      }
                                      style={{
                                        height: "4rem",
                                        borderRadius: "1rem",
                                      }}
                                    >
                                      Học tiếp
                                    </PrimaryButtonPageLevel>
                                  </div>
                                </>
                              )}
                            </>
                          )}
                        </div>
                      </>
                    )}
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>

            <div
              onClick={handleCancelPopupDailyMission}
              style={{
                position: "absolute",
                top: "-2rem",
                left: "-1.5rem",
                zIndex: 9999,
                background: "transparent",
                border: "none",
                width: "5rem",
                fontSize: "2rem",
              }}
            >
              <PopupCloseButton />
            </div>
          </BackgroundImageFromUrl>
        </div> */}
    </div>
  );
};

function mapStateToProps(state) {
  return {
    questionDaily: state.dailyMission.questions,
    listUserInfo: state.grades.listUserInfo,
    questionExam: state.examsQuestion.questions,
    startHP: state.dailyMission.startingHp,
    currentHP: state.dailyMission.currentHp,
    listGradeUnit: state.grades.grades,
    lastLesson: state.grades.lastlesson,
    takeExamIdLPInfo: state.examsQuestion?.examInformation?.takeExamIdLP,
    maxScore: state.examsQuestion.maxScore,
    currentScore: state.examsQuestion.currentScore,
    listMonthlyExams: state.examsQuestion.listMonthlyExams,
    deadline: state.examsQuestion.checkExamTime,
    serverTime: state.examsQuestion.serverTime,
    grades: state.grades.gradeId,
    gradeName: state.grades.gradeName,
    isChangeGrade: state.grades.isChangeGrade,
    sections: state.questions.sections,
    imageUrls: state.dailyMission.imageUrls,
    error: state.examsQuestion.error,
    status: state.examsQuestion.status,
  };
}

export default connect(mapStateToProps)(HomePage);
