import Cookies from "universal-cookie";
import ConstantStrings from "../../constants/ConstantStrings";

const cookies = new Cookies();

export const checkAndGetAnonymousUserId = () => {
  var anonymousUserId = cookies.get("anonymousUser");
  if (anonymousUserId) {
    return anonymousUserId;
  } else {
    let uuid = window.crypto.randomUUID();
    anonymousUserId = uuid.split("-").join("").substring(0, 24);

    cookies.set("anonymousUser", anonymousUserId, {
      path: "/",
      maxAge: 24 * 60 * 60,
    });

    return anonymousUserId;
  }
};

export const checkRouteCategoryRoadMap = (router) => {
  switch (router) {
    case "/roadmap-exam":
      return ConstantStrings.IKMC_ID;
    case "/exam-preparation-HK1":
      return ConstantStrings.HK1_ID;
    case "/exam-preparation-HK2":
      return ConstantStrings.HK2_ID;

    default:
      return ConstantStrings.IKMC_ID;
  }
};

export const USER_IKMC_TYPE = 2;

export const checkPremium = (userInfo,isShowSolution = false) => {
  var result = false;
  if (
    userInfo !== null &&
    userInfo?.premiumFrom &&
    userInfo?.premiumTo &&
    new Date(userInfo?.premiumFrom).getTime() <= new Date().getTime() &&
    new Date(userInfo?.premiumTo).getTime() > new Date().getTime()
  ) {
    result = true;
  } else if (userInfo?.studentType?.type === USER_IKMC_TYPE) {
    if (isShowSolution) {
      result = false;
    }else{
      result = true;
    }
  } else if (userInfo?.premiumFrom === null || userInfo?.premiumTo === null) {
    result = false;
  }
  return result;
};
