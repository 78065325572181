import React, { useState } from "react";
import CheckBox from "../../components/button/check-box-and-radio/CheckBox";
import PrimaryButtonPageLevel from "../../components/button/primary-button/PrimaryButtonLevelPage";
import PopupCloseButton from "../../components/button/special-button/PopupCloseButton";
import ConstantStrings from "../../constants/ConstantStrings";
import { UpgradeAccountContext } from "./UpgradeAccount";

const PaymentMethod = ({
  handleSelectStep,
  handleNextStep,
  setnameSelectedPaymentMethod,
  setShowTermsPopup,
  showTermsPopup,
  paymentRecord,
  setDirectTransferConfirm,
  setSelectMethodsState,
  setConfirmState,
  setshowErrorPayment,
  showErrorPayment,
}) => {
  const {
    nameSelectedService,
    directTransferConfirm,
    methods,
    setPaymentMethodId,
    PaymentMethodId,
  } = React.useContext(UpgradeAccountContext);
  const [selected, setSelected] = useState(PaymentMethodId);
  const [paymentMethod, setPaymentMethod] = useState("");
  const [isAgreed, setIsAgreed] = useState(false);
  const handleClickCheckbox = (state) => {
    setIsAgreed(state);
  };

  const removePopup = () => {
    setShowTermsPopup(false);
    setshowErrorPayment(false);
  };

  const handleSelect = (_id, name) => {
    if (selected === _id) {
      setSelected(-1);
      setPaymentMethod("");
      setPaymentMethodId("");
    } else {
      handleSelectStep(2);
      // setSelected(_id);
      setPaymentMethod(name);
      setnameSelectedPaymentMethod(name);
      handleNextStep(_id);
      // setPaymentMethodId(_id);
    }

    // handleNextStep(_id);
  };
  const handleContinue = () => {
    // setnameSelectedPaymentMethod(paymentMethod);
    if (paymentRecord.includes("vnpay")) {
      window.location.href = paymentRecord;
    } else {
      setDirectTransferConfirm({
        ...directTransferConfirm,
        paymentRecordId: paymentRecord,
      });
      setSelectMethodsState(false);
      setConfirmState(true);
    }
  };
  const selectStyle = {
    border: "1.5px solid #0A2A66",
    boxShadow: "2px 4px 0px 0px #0A2A66",
    padding: "2.5rem 2rem 3.5rem 2rem",
    height: "27.5rem",
    width: "26.25rem",
    background: "#FAFBFC",
    borderRadius: "1rem",
  };
  return (
    <div className="is-flex is-flex-direction-row" style={{ gap: "1.5rem" }}>
      {methods.map((method, index) => {
        return (
          <div key={index}>
            {/* {!method.name.includes("VNPAY") && (
            )} */}
            <div
              key={index}
              className={`${
                // selected === method._id
                //   ? "has-background-kurio-main-color-black has-text-kurio-main-color-white"
                //   :
                "has-text-kurio-main-color-black"
              } is-clickable is-flex is-flex-direction-column is-flex-gap-4 is-relative`}
              style={
                selected === method._id
                  ? selectStyle
                  : {
                      padding: "2.5rem 2rem 3.5rem 2rem",
                      borderRadius: "1rem",
                      width: "26.25rem",
                      border: "1.5px solid #0A2A66",
                      background: "#FAFBFC",
                      height: "27.5rem",
                      boxShadow: "2px 4px 0px 0px #0A2A66",
                    }
              }
            >
              <div
                className="is-flex is-flex-direction-column"
                style={{ gap: "1rem", textAlign: "center" }}
              >
                <div
                  className="is-flex is-flex-direction-column is-align-items-center"
                  style={{ gap: "0.5rem" }}
                >
                  <p
                    style={{
                      fontSize: "1.125rem",
                      fontWeight: 700,
                      textTransform: "uppercase",
                    }}
                  >
                    Thanh toán qua
                  </p>
                  {method.name.includes("VNPAY") ? (
                    <p
                      className="has-text-weight-bold"
                      style={{
                        fontSize: "1.875rem",
                        lineHeight: "normal",
                      }}
                    >
                      Mã QR <span style={{ fontSize: "1.25rem" }}>hoặc</span>{" "}
                      <br></br> Thẻ Ngân hàng
                    </p>
                  ) : (
                    <p
                      className={`${
                        // selected === method._id
                        //   ? "has-text-kurio-main-color-white"
                        //   :
                        "has-text-kurio-main-color-black"
                      } has-text-weight-bold`}
                      style={{
                        width: "16rem",
                        fontSize: "1.875rem",
                        lineHeight: "normal",
                      }}
                    >
                      {/* {method.name} */}
                      Chuyển khoản Trực tiếp
                    </p>
                  )}
                </div>

                {method.name.includes("VNPAY") ? (
                  <img
                    src="/images/upgrades/quan-upgrade.png"
                    style={{
                      width: "14.64706rem",
                      height: "7.5rem",
                      margin: "0 auto",
                    }}
                    alt=""
                  />
                ) : (
                  <img
                    src="/images/upgrades/ly-upgrade.png"
                    style={{
                      width: "10.64706rem",
                      height: "7.5rem",
                      margin: "0 auto",
                    }}
                    alt=""
                  />
                )}
                <div
                  className="is-flex is-flex-direction-column is-align-items-center"
                  style={{
                    fontSize: "1.125rem",
                    fontWeight: 500,
                    lineHeight: "normal",
                  }}
                >
                  {method.name.includes("VNPAY") ? (
                    <p>
                      Thanh toán qua <br></br> {ConstantStrings.VNPAY_CONTENT}
                    </p>
                  ) : (
                    ConstantStrings.DIRECT_TRANFER_CONTENT
                  )}
                  {index === 0 && (
                    <img
                      style={{
                        width: "8.25rem",
                        height: "2.5rem",
                        margin: "0 auto",
                      }}
                      src="/images/upgrades/image-vnpay.svg"
                      alt=""
                    />
                  )}
                </div>
              </div>
              <PrimaryButtonPageLevel
                style={{
                  height: "4rem",
                  borderRadius: "1rem",
                  position: "absolute",
                  right: "5.625rem",
                  bottom: "-1.8rem",
                }}
                onClick={() => {
                  handleSelect(method._id, method.name);
                }}
              >
                Chọn
              </PrimaryButtonPageLevel>
            </div>
          </div>
        );
      })}

      {showErrorPayment && (
        <div className="modal is-active" style={{ opacity: 1, zIndex: 100 }}>
          <div
            className=" modal-background"
            style={{ opacity: 0.8 }}
            onClick={removePopup}
          ></div>
          <div
            className="is-relative has-text-kurio-main-color-black is-relative has-background-kurio-main-color-white is-flex is-align-items-center is-justify-content-center"
            style={{
              width: "50rem",
              padding: "2.5rem",
              borderRadius: "1rem",
              // maxHeight: "30rem",
              gap: "2.5rem",
              // overflowY:"auto",
              // left:"6rem",
              // top:"-2rem"
            }}
          >
            <div
              className="is-flex is-flex-direction-column is-align-items-flex-start"
              style={{ gap: "1rem" }}
            >
              <p>
                <b>
                  Bạn đã đăng ký tài khoản Premium qua ứng dụng di động và vẫn
                  còn hạn sử dụng.
                </b>{" "}
                Nếu bạn cần hỗ trợ thêm, vui lòng nhấn vào nút bên dưới để liên
                hệ với <b>Chăm sóc khách hàng</b>.
              </p>

              <div
                className="is-flex is-flex-direction-column is-align-items-flex-start"
                style={{ gap: "1rem" }}
              >
                <div
                  className="is-flex is-flex-direction-row is-align-items-center"
                  style={{ gap: "1rem" }}
                >
                  <img src="/images/landing-page/icon-gmail.svg" alt="" />
                  <p
                    style={{
                      fontWeight: 500,
                      fontSize: "1.2rem",
                      color: "#0A2A66",
                      lineHeight: "normal",
                    }}
                  >
                    contact@ikemso.com
                  </p>
                </div>
                <div
                  className="is-flex is-flex-direction-row is-align-items-center"
                  style={{ gap: "1rem" }}
                >
                  <img src="/images/landing-page/icon-zalo.png" alt="" />
                  <p
                    style={{
                      fontWeight: 700,
                      fontSize: "1.2rem",
                      color: "#0A2A66",
                      lineHeight: "normal",
                    }}
                  >
                    0971 500 120
                  </p>
                </div>
                <div
                  className="is-flex is-flex-direction-row is-align-items-flex-start"
                  style={{ gap: "1rem" }}
                >
                  <img src="/images/landing-page/icon-address.svg" alt="" />
                  <p
                    style={{
                      fontWeight: 500,
                      fontSize: "1.2rem",
                      color: "#0A2A66",
                      lineHeight: "1.5rem",
                    }}
                  >
                    Số 128, phố Nguyễn Thái Học, <br />
                    phường Điện Biên, quận Ba Đình, Hà Nội
                  </p>
                </div>
              </div>
            </div>

            <div
              onClick={removePopup}
              className=""
              style={{
                position: "absolute",
                top: "-20%",
                left: "-4%",
                zIndex: 999,
                background: "transparent",
                border: "none",
                width: "5rem",
                fontSize: "2rem",
              }}
            >
              <PopupCloseButton />
            </div>
          </div>
        </div>
      )}

      {showTermsPopup && (
        <div className="modal is-active" style={{ opacity: 1, zIndex: 100 }}>
          <div
            className=" modal-background"
            style={{ opacity: 0.8 }}
            onClick={removePopup}
          ></div>
          <div
            className="is-relative has-text-kurio-main-color-black is-relative has-background-kurio-main-color-white is-flex is-align-items-center is-justify-content-center"
            style={{
              width: "50rem",
              padding: "2.5rem",
              borderRadius: "1rem",
              // maxHeight: "30rem",
              gap: "2.5rem",
              // overflowY:"auto",
              // left:"6rem",
              // top:"-2rem"
            }}
          >
            <div
              className="is-flex is-flex-direction-column is-flex-gap-5 is-align-items-flex-start is-justify-content-center"
              style={{
                width: "12.5rem",
                padding: "1.5rem",
                borderRadius: "2rem",
                border: "1px dashed #572CD1",
              }}
            >
              <h1
                className=" has-text-kurio-main-color-purple has-text-weight-bold"
                style={{ fontSize: "1.5rem" }}
              >
                Gói đang chọn
              </h1>
              <div
                className="is-flex is-flex-direction-column is-justify-content-center is-align-items-flex-start is-flex-gap-2 has-text-kurio-main-color-black "
                style={{ lineHeight: "normal" }}
              >
                <p style={{ fontSize: "0.75rem" }}>Loại</p>
                <p
                  className="has-text-weight-bold"
                  style={{ fontSize: "1.125rem" }}
                >
                  {nameSelectedService}
                </p>
              </div>
              <div
                className="is-flex is-flex-direction-column is-justify-content-center is-align-items-flex-start is-flex-gap-2 has-text-kurio-main-color-black "
                style={{ lineHeight: "normal" }}
              >
                <p style={{ fontSize: "0.75rem" }}>Giá</p>
                <p
                  className="has-text-weight-bold"
                  style={{ fontSize: "1.125rem" }}
                >
                  {directTransferConfirm?.transferAmount?.toLocaleString(
                    "vi-VN"
                  ) + " VNĐ"}
                </p>
              </div>
              <div
                className="is-flex is-flex-direction-column is-justify-content-center is-align-items-flex-start is-flex-gap-2 has-text-kurio-main-color-black "
                style={{ lineHeight: "normal" }}
              >
                <p style={{ fontSize: "0.75rem" }}>Hình thức thanh toán</p>
                <p
                  className="has-text-weight-bold"
                  style={{ fontSize: "1.125rem" }}
                >
                  {paymentMethod}
                </p>
              </div>
            </div>
            <div
              className=" is-flex is-flex-direction-column is-flex-gap-4"
              style={{ width: "30rem" }}
            >
              <div
                className=" has-background-kurio-main-color-light-gray is-flex is-flex-direction-column is-align-items-center is-align-self-stretch"
                style={{
                  maxHeight: "17.5rem",
                  fontSize: "0.875rem",
                  // overflowY: "auto",
                  padding: " 1.5rem 0.75rem 0.75rem 0rem",
                  borderRadius: "0.5rem",
                  gap: "0.625rem",
                }}
              >
                <div
                  className="box-terms has-background-kurio-main-color-light-gray is-flex is-flex-direction-column is-align-items-flex-start is-align-self-stretch"
                  style={{
                    maxHeight: "17.5rem",
                    fontSize: "0.875rem",
                    overflowY: "auto",
                    padding: "0.5rem 1.25rem 1.25rem 2rem",
                    borderRadius: "0.5rem",
                    gap: "0.625rem",
                  }}
                >
                  {/* <h1 className="has-text-weight-bold is-uppercase">
                    ĐIỀU KHOẢN SỬ DỤNG
                  </h1> */}
                  <h1
                    className="has-text-weight-bold is-uppercase has-text-centered"
                    style={{ width: "100%" }}
                  >
                    ĐIỀU KHOẢN SỬ DỤNG TÀI KHOẢN KURIO
                  </h1>
                  <p style={{ color: "#272266", fontWeight: 400 }}>
                    Điều khoản sử dụng tài khoản Kurio (sau đây gọi tắt là “Điều
                    khoản sử dụng”) là một thỏa thuận/hợp đồng điện tử được
                    thiết lập và ràng buộc các quyền và nghĩa vụ giữa CÔNG TY CỔ
                    PHẦN IKEMSO TOÀN CẦU (“Công ty”) và Khách hàng khi Khách
                    hàng sử dụng dịch vụ, sản phẩm mà Công ty cung cấp. <br />{" "}
                    <br />
                    Để truy cập và sử dụng tài khoản Kurio, Khách hàng vui lòng
                    xem xét cẩn trọng và xác nhận đồng ý với Điều khoản sử dụng
                    này trước khi bắt đầu sử dụng tài khoản Kurio với các điều
                    khoản và điều kiện sau đây: <br />
                  </p>
                  <h2
                    className=" has-text-left"
                    style={{ color: "#272266", fontWeight: 700 }}
                  >
                    1. Các điều khoản về việc sử dụng
                  </h2>
                  <p style={{ color: "#272266", fontWeight: 500 }}>
                    {" "}
                    Với việc sử dụng tài khoản Kurio, Khách hàng đồng ý tuân
                    theo tất cả những điều khoản dưới đây:{" "}
                  </p>
                  <p
                    style={{
                      color: "#272266",
                      fontWeight: 500,
                      marginLeft: "2rem",
                    }}
                  >
                    <b style={{ marginRight: "1rem" }}>-</b>Khi đăng ký tài
                    khoản, học viên phải cung cấp đầy đủ và chính xác thông tin
                    về Tên, Email, Số điện thoại... Những thông tin này sẽ được
                    dùng làm căn cứ để hỗ trợ giải quyết các vấn đề phát sinh
                    trong quá trình học viên theo học tại Kurio.
                  </p>
                  <p
                    style={{
                      color: "#272266",
                      fontWeight: 500,
                      marginLeft: "2rem",
                    }}
                  >
                    <b style={{ marginRight: "1rem" }}>-</b>Khi tạo tài khoản
                    trên Kurio, Khách hàng đồng ý nhận những email cập nhật các
                    tính năng và bài viết mới, cũng như những email thông báo
                    các khuyến mãi từ Kurio. Kurio sẽ cung cấp đường link ở cuối
                    email để Khách hàng có thể hủy đăng ký nhận thông báo đó
                    bằng cách kích vào link đó.{" "}
                  </p>
                  <p
                    style={{
                      color: "#272266",
                      fontWeight: 500,
                      marginLeft: "2rem",
                    }}
                  >
                    <b style={{ marginRight: "1rem" }}>-</b>Khách hàng sẽ không
                    truy cập Kurio bằng các chương trình tự động.{" "}
                  </p>
                  <p
                    style={{
                      color: "#272266",
                      fontWeight: 500,
                      marginLeft: "2rem",
                    }}
                  >
                    <b style={{ marginRight: "1rem" }}>-</b>Trong quá trình sử
                    dụng tài khoản Kurio, tất cả những dữ liệu, bao gồm các
                    thông tin về kết quả và lịch sử học tập của Khách hàng, tài
                    liệu học tập, hình ảnh, âm thanh và tất cả các dữ liệu khác
                    do Khách hàng tạo ra hay được thu thập bởi Kurio thông qua
                    việc học của Khách hàng, dù miễn phí hay không, sẽ là tài
                    sản tuyệt đối và của riêng Kurio.{" "}
                  </p>
                  <p
                    style={{
                      color: "#272266",
                      fontWeight: 500,
                      marginLeft: "2rem",
                    }}
                  >
                    <b style={{ marginRight: "1rem" }}>-</b>Khách hàng sẽ không
                    sao chép, phát tán hay phát hành bất kì tài liệu nào của
                    Kurio, dù miễn phí hay không, mà không có sự cho phép bằng
                    văn bản của Kurio. Tuy nhiên, Khách hàng có thế để đường dẫn
                    (link) đến những tài liệu trên Kurio.{" "}
                  </p>
                  <p
                    style={{
                      color: "#272266",
                      fontWeight: 500,
                      marginLeft: "2rem",
                    }}
                  >
                    <b style={{ marginRight: "1rem" }}>-</b>Mật khẩu của tài
                    khoản: Sau khi đăng ký, học viên được cung cấp tài khoản và
                    mật khẩu. Mật khẩu được sử dụng để đăng nhập vào website
                    Kurio.vn và các dịch vụ trong hệ thống của Kurio. Học viên
                    có trách nhiệm tự bảo quản mật khẩu, nếu mật khẩu bị lộ ra
                    ngoài dưới bất kỳ hình thức nào, Kurio sẽ không chịu trách
                    nhiệm về mọi tổn thất phát sinh.{" "}
                  </p>
                  <p
                    style={{
                      color: "#272266",
                      fontWeight: 500,
                      marginLeft: "2rem",
                    }}
                  >
                    <b style={{ marginRight: "1rem" }}>-</b>Nếu Khách hàng vi
                    phạm bất kì điều khoản sử dụng nào ở trên, Kurio giữ quyền
                    khóa tài khoản của Khách hàng vô thời hạn và việc này có thể
                    khiến cho Khách hàng không còn có thế sử dụng những sản phẩm
                    hay dịch vụ mà Khách hàng đã mua ở Kurio (nếu có).
                  </p>
                  <h2 style={{ color: "#272266", fontWeight: 700 }}>
                    2. Các điều khoản về việc mua hàng/sử dụng dịch vụ
                  </h2>
                  <p style={{ color: "#272266", fontWeight: 500 }}>
                    Khi mua một sản phẩm hay dịch vụ trên Kurio, ví dụ như mua
                    tài khoản để học, Khách hàng đồng ý tuân theo tất cả những
                    điều khoản sau:{" "}
                  </p>
                  <p
                    style={{
                      color: "#272266",
                      fontWeight: 500,
                      marginLeft: "2rem",
                    }}
                  >
                    <b style={{ marginRight: "1rem" }}>-</b>Những sản phẩm và
                    dịch vụ mà Khách hàng đã mua trên Kurio, ví dụ như các tài
                    khoản học, là để chỉ mình Khách hàng sử dụng. Khách hàng
                    không được phép để bất kì người nào khác đăng nhập vào tài
                    khoản của Khách hàng để sử dụng những sản phẩm hay dịch vụ
                    mà Khách hàng đã mua.
                  </p>
                  <p
                    style={{
                      color: "#272266",
                      fontWeight: 500,
                      marginLeft: "2rem",
                    }}
                  >
                    <b style={{ marginRight: "1rem" }}>-</b>Khi thực hiện thanh
                    toán trên Kurio, Khách hàng đồng ý sẽ cung cấp những thông
                    tin thanh toán xác thực và chính xác nhất.{" "}
                  </p>
                  <p
                    style={{
                      color: "#272266",
                      fontWeight: 500,
                      marginLeft: "2rem",
                    }}
                  >
                    <b style={{ marginRight: "1rem" }}>-</b>Nếu Khách hàng vi
                    phạm bất kì điều khoản sử dụng nào ở trên, Kurio giữ quyền
                    khóa tài khoản của Khách hàng vô thời hạn và việc này có thể
                    khiến cho Khách hàng không còn có thế sử dụng những sản phẩm
                    hay dịch vụ mà Khách hàng đã mua ở Kurio (nếu có).
                  </p>
                  <h2 style={{ color: "#272266", fontWeight: 700 }}>
                    3. Phương thức thanh toán
                  </h2>
                  <p style={{ color: "#272266", fontWeight: 500 }}>
                    Kurio chấp nhận nhiều hình thức thanh toán khác nhau, bao
                    gồm: <br /> <br />
                    a. Thanh toán bằng thẻ quốc tế như Visa/Master được phát
                    hành hợp pháp bởi một tổ chức tín dụng tại Việt Nam.
                    <br /> <br />
                    b. Thanh toán bằng thẻ ATM nội địa có chức năng Internet
                    Banking hoặc QR Code được cung cấp bởi một tổ chức tín dụng
                    tại Việt Nam. <br /> <br />
                    c. Thanh toán bằng chuyển khoản theo thông tin dưới đây:{" "}
                    <br /> <br />
                    CÔNG TY CỔ PHẦN IKEMSO TOÀN CẦU <br /> <br />
                    Số tài khoản: 19036816201018 <br /> <br />
                    Ngân hàng: TMCP Kỹ thương Việt Nam (Techcombank) - Chi nhánh
                    Ba Đình - Hà Nội <br /> <br />
                    Ghi chú: Khi chuyển khoản tại mục "Nội dung" Khách hàng ghi
                    rõ: Số điện thoại - Họ Tên - Email đăng ký học - Khóa học
                    đăng ký. <br /> <br />
                    Ví dụ: 0901234567 – Nguyen Van A - nguyevana@gmail.com –
                    Toan tu duy <br /> <br />
                    <b>Hướng dẫn thanh toán:</b> <br /> <br />
                    Sau khi Khách hàng đã đăng ký tài khoản mới trên Kurio hoặc
                    đăng nhập vào tài khoản đã đăng ký trên Kurio, Khách hàng
                    thực hiện các bước sau để mua khóa học phù hợp và tiến hành
                    thanh toán:
                  </p>
                  <p
                    style={{
                      color: "#272266",
                      fontWeight: 500,
                      marginLeft: "2rem",
                    }}
                  >
                    <b style={{ marginRight: "1rem" }}>+</b>Bước 1: Tại trang
                    chủ Kurio, nhấn chọn “Nâng cấp” sau đó chọn khóa học mà
                    Khách hàng muốn mua.
                  </p>
                  <p
                    style={{
                      color: "#272266",
                      fontWeight: 500,
                      marginLeft: "2rem",
                    }}
                  >
                    <b style={{ marginRight: "1rem" }}>+</b>Bước 2: Nhấn chọn
                    “mua khóa học”.{" "}
                  </p>
                  “Mua khóa học”: Chỉ có thể truy cập vào một khóa học mà Khách
                  hàng đăng ký mua và giới hạn thời gian cụ thể cho từng khóa
                  học. <p />
                  <p
                    style={{
                      color: "#272266",
                      fontWeight: 500,
                      marginLeft: "2rem",
                    }}
                  >
                    <b style={{ marginRight: "1rem" }}>+</b>Bước 3: Nhấn chọn
                    “thanh toán” để tiến hành thanh toán.{" "}
                  </p>
                  <p
                    style={{
                      color: "#272266",
                      fontWeight: 500,
                      marginLeft: "2rem",
                    }}
                  >
                    <b style={{ marginRight: "1rem" }}>+</b>Bước 4. Ở màn hình
                    chọn Phương thức thanh toán (ví dụ: người dùng chọn phương
                    thức "Thẻ ATM") rồi nhấn Thanh toán.{" "}
                  </p>
                  <p
                    style={{
                      color: "#272266",
                      fontWeight: 500,
                      marginLeft: "2rem",
                    }}
                  >
                    <b style={{ marginRight: "1rem" }}>+</b>Bước 5. Tiến hành
                    nhập đầy đủ các thông tin theo yêu cầu và nhấn Thanh toán.{" "}
                  </p>
                  <p
                    style={{
                      color: "#272266",
                      fontWeight: 500,
                      marginLeft: "2rem",
                    }}
                  >
                    <b style={{ marginRight: "1rem" }}>+</b>Bước 6. Khách hàng
                    sẽ nhận được thông báo thanh toán hoàn tất và có thể tiến
                    hành truy cập khóa học. Khách hàng vào mục "Tài khoản" để
                    kiểm tra thông tin khóa học.{" "}
                  </p>
                  <p style={{ color: "#272266", fontWeight: 500 }}>
                    Trường hợp sau khi thanh toán, nếu Khách hàng phát hiện Gói
                    dịch vụ có lỗi xin vui lòng thông báo ngay theo số điện
                    thoại 0971 500 120 của Kurio để được trợ giúp.{" "}
                  </p>
                  <h2 style={{ color: "#272266", fontWeight: 700 }}>
                    4. Toàn bộ thỏa thuận
                  </h2>
                  <p style={{ color: "#272266", fontWeight: 500 }}>
                    Tất cả những điều khoản trên là toàn bộ bản hợp đồng giữa
                    Kurio và Khách hàng về tất cả những khía cạnh của việc sử
                    dụng tài khoản Kurio của Khách hàng. Những điều khoản này sẽ
                    thay thế cho những điều khoản ở trước đó.{" "}
                  </p>
                  <h2 style={{ color: "#272266", fontWeight: 700 }}>
                    5. Chính sách hoàn trả
                  </h2>
                  <p style={{ color: "#272266", fontWeight: 500 }}>
                    Trong trường hợp Khách hàng muốn hỗ trợ hoàn trả khóa học
                    trên web Kurio, vui lòng liên hệ với chúng tôi theo hotline
                    0971500120 hoặc email cskh@kurio.vn để được hỗ trợ.
                  </p>
                  <h2 style={{ color: "#272266", fontWeight: 700 }}>
                    6. Thông tin liên lạc
                  </h2>
                  <p style={{ color: "#272266", fontWeight: 500 }}>
                    Nếu Khách hàng có câu hỏi nào về những điều khoản này, Khách
                    hàng hãy liên lạc với chúng tôi: <br />
                    <br />
                    CÔNG TY CỔ PHẦN IKEMSO TOÀN CẦU <br /> <br />
                    Email: cskh@kurio.vn <br /> <br />
                    Tel: 0971500120 <br /> <br />
                    Địa chỉ trụ sở: số 128 Nguyễn Thái Học, phường Điện Biên,
                    quận Ba Đình, Hà Nội <br /> <br />
                  </p>
                  <h1
                    className=" has-text-kurio-main-color-black has-text-weight-bold has-text-centered"
                    style={{ width: "100%" }}
                  >
                    CHÍNH SÁCH BẢO MẬT THÔNG TIN
                  </h1>
                  <h2 style={{ color: "#272266", fontWeight: 700 }}>
                    1. Chúng tôi thu thập những thông tin gì?
                  </h2>
                  <p style={{ color: "#272266", fontWeight: 500 }}>
                    Chúng tôi thu nhập thông tin từ bạn khi bạn đăng ký trên
                    trang web của chúng tôi. <br /> <br />
                    Dữ liệu cá nhân mà Kurio có thể thu thập bao gồm, nhưng
                    không giới hạn: <br />
                  </p>
                  <p
                    style={{
                      color: "#272266",
                      fontWeight: 500,
                      marginLeft: "2rem",
                    }}
                  >
                    <b style={{ marginRight: "1rem" }}>+</b>Họ tên{" "}
                  </p>
                  <p
                    style={{
                      color: "#272266",
                      fontWeight: 500,
                      marginLeft: "2rem",
                    }}
                  >
                    <b style={{ marginRight: "1rem" }}>+</b>Địa chỉ email{" "}
                  </p>
                  <p
                    style={{
                      color: "#272266",
                      fontWeight: 500,
                      marginLeft: "2rem",
                    }}
                  >
                    <b style={{ marginRight: "1rem" }}>+</b>Ngày sinh{" "}
                  </p>
                  <p
                    style={{
                      color: "#272266",
                      fontWeight: 500,
                      marginLeft: "2rem",
                    }}
                  >
                    <b style={{ marginRight: "1rem" }}>+</b>Tài khoản ngân hàng
                    và thông tin thanh toán{" "}
                  </p>
                  <p
                    style={{
                      color: "#272266",
                      fontWeight: 500,
                      marginLeft: "2rem",
                    }}
                  >
                    <b style={{ marginRight: "1rem" }}>+</b>Số điện thoại{" "}
                  </p>
                  <p
                    style={{
                      color: "#272266",
                      fontWeight: 500,
                      marginLeft: "2rem",
                    }}
                  >
                    <b style={{ marginRight: "1rem" }}>+</b>Giới tính{" "}
                  </p>
                  <p
                    style={{
                      color: "#272266",
                      fontWeight: 500,
                      marginLeft: "2rem",
                    }}
                  >
                    <b style={{ marginRight: "1rem" }}>+</b>Địa chỉ thanh toán{" "}
                  </p>
                  <p
                    style={{
                      color: "#272266",
                      fontWeight: 500,
                      marginLeft: "2rem",
                    }}
                  >
                    <b style={{ marginRight: "1rem" }}>+</b>Thông tin được gửi
                    bởi hoặc liên quan đến (các) thiết bị được sử dụng để truy
                    cập vào Các Dịch vụ hoặc Nền tảng của chúng tôi.{" "}
                  </p>
                  <p
                    style={{
                      color: "#272266",
                      fontWeight: 500,
                      marginLeft: "2rem",
                    }}
                  >
                    <b style={{ marginRight: "1rem" }}>+</b>Bất kỳ thông tin nào
                    khác về người dùng khi người dùng đăng nhập để sử dụng Các
                    Dịch Vụ hoặc Nền tảng của chúng tôi.{" "}
                  </p>
                  <p
                    style={{
                      color: "#272266",
                      fontWeight: 500,
                      marginLeft: "2rem",
                    }}
                  >
                    <b style={{ marginRight: "1rem" }}>+</b>Dữ liệu tổng hợp về
                    nội dung người dùng sử dụng.{" "}
                  </p>
                  <p
                    style={{
                      color: "#272266",
                      fontWeight: 500,
                      marginLeft: "2rem",
                    }}
                  >
                    <b style={{ marginRight: "1rem" }}>+</b>Bạn có thể tự chỉnh
                    sửa được tên trong trang quản lý tài khoản. Nếu cần đổi
                    email đăng nhập hoặc số điện thoại, bạn cần gửi yêu cầu cho
                    chúng tôi và trải qua các bước xác thực.{" "}
                  </p>
                  <h2 style={{ color: "#272266", fontWeight: 700 }}>
                    2. Chúng tôi sẽ làm gì với thông tin của bạn?
                  </h2>
                  <p style={{ color: "#272266", fontWeight: 500 }}>
                    Kurio sử dụng thông tin chúng tôi thu thập cho các mục đích:{" "}
                    <br />
                  </p>
                  <p
                    style={{
                      color: "#272266",
                      fontWeight: 500,
                      marginLeft: "2rem",
                    }}
                  >
                    <b style={{ marginRight: "1rem" }}>+</b>Để xem xét và/hoặc
                    xử lý đơn đăng ký/giao dịch; đăng ký email của bạn với chúng
                    tôi{" "}
                  </p>
                  <p
                    style={{
                      color: "#272266",
                      fontWeight: 500,
                      marginLeft: "2rem",
                    }}
                  >
                    <b style={{ marginRight: "1rem" }}>+</b>Để quản lý việc bạn
                    sử dụng dịch vụ của chúng tôi{" "}
                  </p>
                  <p
                    style={{
                      color: "#272266",
                      fontWeight: 500,
                      marginLeft: "2rem",
                    }}
                  >
                    <b style={{ marginRight: "1rem" }}>+</b>Để đáp ứng, xử lý,
                    giải quyết hoặc hoàn tất một giao dịch và/hoặc đáp ứng các
                    yêu cầu của bạn đối với các sản phẩm và dịch vụ nhất định và
                    thông báo cho bạn về các vấn đề dịch vụ và các hoạt động tài
                    khoản bất thường{" "}
                  </p>
                  <p
                    style={{
                      color: "#272266",
                      fontWeight: 500,
                      marginLeft: "2rem",
                    }}
                  >
                    <b style={{ marginRight: "1rem" }}>+</b>Để thực thi các Điều
                    Khoản Dịch Vụ của chúng tôi{" "}
                  </p>
                  <p
                    style={{
                      color: "#272266",
                      fontWeight: 500,
                      marginLeft: "2rem",
                    }}
                  >
                    <b style={{ marginRight: "1rem" }}>+</b>Để bảo vệ sự an toàn
                    cá nhân và các quyền, tài sản hoặc sự an toàn của người khác{" "}
                  </p>
                  <p
                    style={{
                      color: "#272266",
                      fontWeight: 500,
                      marginLeft: "2rem",
                    }}
                  >
                    <b style={{ marginRight: "1rem" }}>+</b>Để nhận dạng và/hoặc
                    xác minh{" "}
                  </p>
                  <p
                    style={{
                      color: "#272266",
                      fontWeight: 500,
                      marginLeft: "2rem",
                    }}
                  >
                    <b style={{ marginRight: "1rem" }}>+</b>Để giải quyết hoặc
                    tạo điều kiện dịch vụ khách hàng, thực hiện các khiếu nại
                    của bạn, giải quyết hoặc trả lời bất kỳ thắc mắc nào được
                    gửi bởi (hoặc nhằm được gửi bởi) bạn hoặc thay mặt bạn{" "}
                  </p>
                  <p
                    style={{
                      color: "#272266",
                      fontWeight: 500,
                      marginLeft: "2rem",
                    }}
                  >
                    <b style={{ marginRight: "1rem" }}>+</b>Để liên hệ với bạn
                    hoặc liên lạc với bạn qua điện thoại, tin nhắn văn bản,
                    email nhằm mục đích quản trị quan hệ của bạn với chúng tôi{" "}
                  </p>
                  <p
                    style={{
                      color: "#272266",
                      fontWeight: 500,
                      marginLeft: "2rem",
                    }}
                  >
                    <b style={{ marginRight: "1rem" }}>+</b>Để gửi thông báo tới
                    bạn qua trình duyệt hoặc qua ứng dụng Kurio trên điện thoại{" "}
                  </p>
                  <p
                    style={{
                      color: "#272266",
                      fontWeight: 500,
                      marginLeft: "2rem",
                    }}
                  >
                    <b style={{ marginRight: "1rem" }}>+</b>Để tiến hành các
                    hoạt động nghiên cứu, phân tích và phát triển (bao gồm,
                    nhưng không giới hạn ở, phân tích dữ liệu, khảo sát, phát
                    triển và/hoặc lập đặc tính sản phẩm và dịch vụ), để phân
                    tích cách thức bạn sử dụng Các Dịch Vụ của chúng tôi, để cải
                    thiện Các Dịch Vụ hoặc sản phẩm của chúng tôi và/hoặc để cải
                    thiện trải nghiệm khách hàng của bạn{" "}
                  </p>
                  <p
                    style={{
                      color: "#272266",
                      fontWeight: 500,
                      marginLeft: "2rem",
                    }}
                  >
                    <b style={{ marginRight: "1rem" }}>+</b>Vì mục đích tiếp
                    thị, gửi cho bạn qua email, thông tin và tài liệu tiếp thị
                    và quảng bá liên quan đến các sản phẩm và/hoặc dịch vụ. Bạn
                    có thể hủy đăng ký nhận các thông tin tiếp thị tại bất cứ
                    thời điểm nào bằng cách sử dụng chức năng hủy đăng ký email{" "}
                  </p>
                  <p
                    style={{
                      color: "#272266",
                      fontWeight: 500,
                      marginLeft: "2rem",
                    }}
                  >
                    <b style={{ marginRight: "1rem" }}>+</b>Để ngăn chặn hoặc
                    điều tra bất kỳ hoạt động gian lận, phi pháp, thiếu sót hay
                    hành vi sai trái nào, cho dù có liên quan đến việc bạn sử
                    dụng Các Dịch Vụ của chúng tôi{" "}
                  </p>
                  <p />
                  <h2 style={{ color: "#272266", fontWeight: 700 }}>
                    3. Bảo mật
                  </h2>
                  <p style={{ color: "#272266", fontWeight: 500 }}>
                    Bảo mật thông tin cá nhân của Khách hàng là ưu tiên hàng đầu
                    của chúng tôi. Các thông tin đăng ký mua hàng của Khách hàng
                    sẽ được bảo mật bằng cách sử dụng công nghệ mã hóa an toàn
                    Secure Sockets Layer (SSL). Chúng tôi cam kết sẽ cố gắng để
                    đảm bảo an ninh ở mức cao nhất cho Khách hàng. Tuy nhiên,
                    Kurio xin nhấn mạnh không có phương án nào đảm bảo được tính
                    bảo mật 100%. Nếu bạn có bất kỳ câu hỏi về vấn đề bảo mật
                    trên trang web của chúng tôi, bạn có thể gửi thắc mắc về
                    email cskh@kurio.vn.
                  </p>
                  <h2 style={{ color: "#272266", fontWeight: 700 }}>
                    4. Cookies và công nghệ phân tích dữ liệu khác
                  </h2>
                  <p style={{ color: "#272266", fontWeight: 500 }}>
                    Cookies là một lượng nhỏ dữ liệu, được gửi tới trình duyệt
                    của bạn từ một trang web và được lưu trữ trên ổ đĩa cứng của
                    máy tính. Cookies cho phép trang web hoặc hệ thống của các
                    bên cung cấp dịch vụ nhận diện trình duyệt của bạn và lưu
                    lại một số thông tin nhất định. Chúng tôi dùng cookies để
                    hiểu và lưu trữ hoạt động của bạn và đáp ứng các nhu cầu của
                    bạn tốt hơn cho lần truy cập sau.
                  </p>
                  <h2 style={{ color: "#272266", fontWeight: 700 }}>
                    5. Chúng tôi có cung cấp thông tin cho bên khác không?
                  </h2>
                  <p style={{ color: "#272266", fontWeight: 500 }}>
                    Chúng tôi không bán, trao đổi, hoặc sử dụng các hình thức
                    thương mại khác với những thông tin cá nhân của bạn. Tuy
                    nhiên, thông tin không định danh của những người truy cập
                    vào trang web có thể được sử dụng và cung cấp cho các bên
                    khác nhằm mục đích marketing, quảng cáo hoặc mục đích sử
                    dụng khác.
                  </p>
                  <h2 style={{ color: "#272266", fontWeight: 700 }}>
                    6. Xóa tài khoản
                  </h2>
                  <p style={{ color: "#272266", fontWeight: 500 }}>
                    Các cá nhân sở hữu tài khoản trên Kurio được quyền xóa bỏ
                    tài khoản bất kỳ thời điểm nào. Trong trường hợp Khách hàng
                    muốn xóa tài khoản, vui lòng liên hệ với Kurio theo hotline
                    0971500120 hoặc email <b>cskh@kurio.vn</b> để được hỗ trợ.
                    Ngoài ra, chúng tôi chỉ hỗ trợ khôi phục lại tài khoản trong
                    trường hợp nhầm lẫn hoặc tài khoản bị tấn công trong thời
                    gian 30 ngày kể từ thời điểm xóa. Vui lòng đọc kỹ các thông
                    tin được cung cấp trước khi thực sự làm hành động này.
                  </p>
                  <h2 style={{ color: "#272266", fontWeight: 700 }}>
                    7. Thông tin liên hệ
                  </h2>
                  <p style={{ color: "#272266", fontWeight: 500 }}>
                    Nếu bạn có thắc mắc, hãy liên hệ với chúng tôi: <br />
                  </p>
                  <p
                    style={{
                      color: "#272266",
                      fontWeight: 500,
                      marginLeft: "2rem",
                    }}
                  >
                    <b style={{ marginRight: "1rem" }}>+</b>
                    Hotline: 0971500120{" "}
                  </p>
                  <p
                    style={{
                      color: "#272266",
                      fontWeight: 500,
                      marginLeft: "2rem",
                    }}
                  >
                    <b style={{ marginRight: "1rem" }}>+</b>Email: cskh@kurio.vn{" "}
                  </p>
                  <p
                    style={{
                      color: "#272266",
                      fontWeight: 500,
                      marginLeft: "2rem",
                    }}
                  >
                    <b style={{ marginRight: "1rem" }}>+</b>Địa chỉ: số 128
                    Nguyễn Thái Học, phường Điện Biên, quận Ba Đình, Hà Nội.
                  </p>
                  <p /> <br />
                </div>
              </div>
              <div className="is-flex is-flex-direction-column is-flex-gap-4 is-align-items-center">
                <div className=" is-flex is-flex-gap-4 is-align-items-center">
                  <CheckBox onClick={() => handleClickCheckbox(!isAgreed)} />
                  <div
                    className=" is-flex is-align-items-center is-flex-gap-2"
                    style={{ fontSize: "0.75rem", lineHeight: "normal" }}
                  >
                    <p>Tôi đã đọc và đồng ý với Điều khoản Sử dụng.</p>
                  </div>
                </div>
              </div>
              <div
                className="is-flex"
                style={{ gap: "1rem", margin: "0 auto" }}
              >
                <PrimaryButtonPageLevel
                  style={{ height: "4rem", width: "30rem", maxWidth: "30rem" }}
                  disabled={!isAgreed}
                  onClick={handleContinue}
                >
                  tiếp tục thanh toán
                </PrimaryButtonPageLevel>
              </div>
            </div>
            <div
              onClick={removePopup}
              className=""
              style={{
                position: "absolute",
                top: "-8%",
                left: "-4%",
                zIndex: 999,
                background: "transparent",
                border: "none",
                width: "5rem",
                fontSize: "2rem",
              }}
            >
              <PopupCloseButton />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default PaymentMethod;
