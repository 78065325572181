import instance from "./instance";

const paymentUrl = "/payment/v1/user/payment-record";
const baseCategoryUserUrl = "/category/v1/user";
const subscriptionUrl = "/payment/v1/user/subscription-record";

export const getPaymentMethod = () => {
  // checkAndCallDailyLoginAPI()
  return instance.get(
    `${baseCategoryUserUrl}/payment-method/get-payment-method-category`
  );
};
export const createPaymentRecord = (record) => {
  // checkAndCallDailyLoginAPI()
  return instance.put(`${paymentUrl}/create-payment-record`, record);
};
export const getSubcriptionPackge = () => {
  // checkAndCallDailyLoginAPI()
  return instance.get(
    `${baseCategoryUserUrl}/subscription-package/get-subscription-package-category`
  );
};
export const getAPaymentRecord = (id) => {
  // checkAndCallDailyLoginAPI()
  return instance.get(`${paymentUrl}/get-a-payment-record/${id}`);
};

export const checkGooglePlayRenew =()=>{
  return instance.get(`${subscriptionUrl}/check-google-play-renew`);
}
