import React, { useState } from "react";
import ScrollRef from "../../detail-components/ScrollRef";
import jsonParser from "../helper/jsonParser";

import BackgroundImageFromUrl from "../../components/parseImage/BackgroundImageFromUrl";
import { checkImage } from "../../constants/checkImage";
import ConstantStrings from "../../constants/ConstantStrings";
import { loadWidthImage } from "../../constants/loadWidthImage";
import { cacheImage, getCacheImage, getImageUrl } from "../helper/parseImage";
import MainContent1Title from "./MainContent1Title";

const MainContent4ImageTextLarge = ({ data, isLast }) => {
  const content = data?.content;
  const contentText =
    content?.texts[0] !== null &&
    content?.texts[0] !== undefined &&
    content?.texts[0]?.length > 0
      ? jsonParser(content?.texts[0])
      : content?.texts[0];
  var imageStyle = {
    width: "calc((100% - 5rem)/2)",
  };
  const [isLoading, setIsLoading] = useState(
    checkImage(content?.images[0])
      ? getCacheImage([content?.images[0]])
        ? false
        : true
      : false
  );
  var key = getImageUrl(content?.images[0]);
  loadWidthImage(key, (err, img) => {
    cacheImage([key]);
    setIsLoading(false);
  });
  return (
    <div
      className={`m-auto has-text-kurio-main-color-black`}
      style={{
        borderRadius: "1rem",
        maxWidth: "60rem",
        textAlign: "center",
      }}
    >
      {!isLast && !isLoading && <ScrollRef />}
      {isLoading && <div>Loading</div>}
      <BackgroundImageFromUrl
        objectId={content?.backgroundImage}
        style={{
          borderRadius: "1rem",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          backgroundSize: "cover",
          fontSize: "1.25rem",
        }}
      >
        {data?.title && !isLoading && <MainContent1Title title={data?.title} />}
        <div
          className={`${
            isLoading ? "is-hidden" : ""
          }content-image-text columns m-auto is-align-items-center`}
          style={{
            maxWidth: "60rem",
            gap: "5rem",
          }}
        >
          <img src={key} style={imageStyle} onError={(e)=>{ e.target.src = ConstantStrings.DEFAULT_IMAGE;e.target.style.width = "2.5rem";e.target.style.height = "2.5rem"; }} alt={content?.images[0]} />
          <div
            className="content-text column has-text-left p-0"
            style={{ width: "calc((100% - 5rem)/2)" }}
          >
            {contentText}
          </div>
        </div>
      </BackgroundImageFromUrl>
    </div>
  );
};

export default MainContent4ImageTextLarge;
