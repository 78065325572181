import React, { useEffect, useState } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { connect, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import PrimaryButtonPageLevel from "../../components/button/primary-button/PrimaryButtonLevelPage";
import BackgroundImageFromUrl from "../../components/parseImage/BackgroundImageFromUrl";
import ImageFromUrl from "../../components/parseImage/ImageFromUrl";
import { getAllAchievement } from "../../config/achievement";
import ScrollRefWrapper from "../../detail-components/ScrollRefWrapper";
import {
  getCurrentEvent,
  setClearStateImageUrlDaily,
} from "../../reducers/dailyMissionSlice";
import { setIsChangeGrade } from "../../reducers/gradesSlice";
import DailyMissionMainWrapper from "../daily-mission/DailyMissionMainWrapper";
import "./Achievement.style.css";
import AchievementDetail from "./AchievementDetail";

const AchievementMainWrapper = ({ questionDaily, grades, isChangeGrade }) => {
  const [achievementAll, setAchievementAll] = useState(true);
  const [achievementLesson, setAchievementLesson] = useState(false);
  const [achievementExercises, setAchievementExercises] = useState(false);
  const [achievementMonthlyExam, setAchievementMonthlyExam] = useState(false);
  const [achievementDailyQuiz, setAchievementDailyQuiz] = useState(
    localStorage.getItem("showDailyQuiz")
      ? localStorage.getItem("showDailyQuiz")
      : false
  );
  const [achievements, setAchievements] = useState([]);
  const [showBadge, setShowBadge] = useState(0); // 1: hiện chiến đấu ngay, 2: hiện huy hiệu
  const [showPopupStartDailyMission, setShowPopupStartDailyMission] =
    useState(false);
  const [showPopupCongratulations, setShowPopupCongratulations] =
    useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [lifeTime, setLifeTime] = useState(0);
  const [showPopupAchievementDetail, setShowPopupAchievementDetail] =
    useState(false);
  const [achievementDetail, setAchievementDetail] = useState(null);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  var boxStyle = {
    width: "10rem",
    height: "12.5rem",
    borderRadius: "1rem",
    border: "1.5px solid #0A2A66",
    boxShadow: "0.125rem 0.25rem 0rem 0rem #0A2A66",
  };

  const handleClick = () => {
    if (achievementAll) {
      navigate("/");
    } else if (achievementLesson) {
      navigate("/list-unit");
    } else if (achievementExercises) {
      navigate("/exercise");
    } else if (achievementMonthlyExam) {
      navigate("/monthly-exams");
    } else if (achievementDailyQuiz) {
      if (
        !questionDaily ||
        (questionDaily === undefined && questionDaily === null)
      ) {
        alert("Hiện tại chưa có nhiệm vụ hàng ngày");
        return;
      }
    }

    setShowPopupStartDailyMission(true);
  };

  const handleNavigate = () => {
    setShowPopupCongratulations(false);
    localStorage.setItem("popupCongratulationCheck", false);
    localStorage.setItem("showDailyQuiz", true);
    window.location.reload();
  };

  const removePopup = () => {
    setShowPopupCongratulations(false);
    localStorage.setItem("popupCongratulationCheck", false);
    window.location.reload();
  };

  const handleShowLesson = () => {
    setAchievementLesson(true);
    setAchievementExercises(false);
    setAchievementAll(false);
    setAchievementMonthlyExam(false);
    setAchievementDailyQuiz(false);
    // setAchievements([]);
  };

  const handleShowAll = () => {
    setAchievementAll(true);
    setAchievementLesson(false);
    setAchievementExercises(false);
    setAchievementMonthlyExam(false);
    setAchievementDailyQuiz(false);
  };

  const handleShowExercises = () => {
    setAchievementExercises(true);
    setAchievementLesson(false);
    setAchievementAll(false);
    setAchievementMonthlyExam(false);
    setAchievementDailyQuiz(false);
    // setAchievements([]);
  };

  const handleShowMonthlyExam = () => {
    setAchievementDailyQuiz(false);
    setAchievementAll(false);
    setAchievementMonthlyExam(true);
    setAchievementLesson(false);
    setAchievementExercises(false);
    // setAchievements([]);
  };

  const handleShowDailyQuiz = () => {
    setAchievementDailyQuiz(true);
    setAchievementMonthlyExam(false);
    setAchievementAll(false);
    setAchievementLesson(false);
    setAchievementExercises(false);
    // setAchievements([]);
  };

  const handleShowPopupAchievementDetail = (achievement) => {
    setShowPopupAchievementDetail(true);
    setAchievementDetail(achievement);
  };

  var styleOnChange = {
    borderRadius: "1rem",
    display: "flex",
    padding: "0.75rem 1.5rem",
    flexDirection: "column",
    alignItems: "flex-start",
    gap: "0.625rem",
  };

  var styleNone = {
    display: "flex",
    padding: "0.75rem 1.5rem",
    flexDirection: "column",
    alignItems: "flex-start",
    gap: "0.625rem",
    borderRadius: "1rem",
    border: "0.09375rem solid #0A2A66",
    boxShadow: "0.125rem 0.25rem 0rem 0rem #0A2A66",
  };

  const newAchievements = achievements.reduce((acc, item) => {
    const { menu, count } = item;

    // Kiểm tra các biến state và xác định điều kiện phù hợp
    const isMenuAllowed = () => {
      if (achievementAll) return true;
      if (achievementLesson && menu === 1) return true;
      if (achievementExercises && menu === 2) return true;
      if (achievementMonthlyExam && menu === 3) return true;
      if (achievementDailyQuiz && menu === 4) return true;
      return false;
    };
    // Kiểm tra xem mảng con đã tồn tại chưa
    const existingGroup = acc.find((group) => group[0]?.menu === menu);

    if (existingGroup) {
      // Nếu mảng con đã tồn tại và điều kiện phù hợp, thêm đối tượng vào mảng con đó
      if (isMenuAllowed()) {
        existingGroup.push(item);
      }
    } else {
      // Nếu mảng con chưa tồn tại và điều kiện phù hợp, tạo một mảng mới và thêm vào mảng chính
      if (isMenuAllowed()) {
        acc.push([item]);
      }
    }

    return acc;
  }, []);

  // const checkState = (achievements) => {
  //   // if (achievementAll) {
  //   //   return true;
  //   // } else
  //   if (achievementDailyQuiz) {
  //     if (achievements.some((item) => item.menu !== 4)) {
  //       setAchievements([]);
  //       return false;
  //     }
  //     return true;
  //   } else if (achievementExercises) {
  //     if (achievements.some((item) => item.menu !== 2)) {
  //       setAchievements([]);
  //       return false;
  //     }
  //     return true;
  //   } else if (achievementLesson) {
  //     if (achievements.some((item) => item.menu !== 1)) {
  //       setAchievements([]);
  //       return false;
  //     }
  //     return true;
  //   } else if (achievementMonthlyExam) {
  //     if (achievements.some((item) => item.menu !== 3)) {
  //       setAchievements([]);
  //       return false;
  //     }
  //     return true;
  //   }
  // };
  useEffect(() => {
    const getAchievements = async () => {
      try {
        const { result } = await getAllAchievement();
        setIsLoading(true);
        setLifeTime(result.lifeTimeScore);
        // if (achievements.length > 0) {
        //   setAchievements([]);
        // }
        if (achievementAll) {
          setAchievements(result.achievements);
          // result.achievements.every((item) => item.count < 1) ? 1 : 2
          setShowBadge(2);
        } else if (achievementLesson) {
          // var filterLesson = result.achievements.filter(
          //   (item) => item.menu === 1
          // );
          // setAchievements(filterLesson);
          // filterLesson.every((item) => item.count <= 0) ? 1 : 2
          setShowBadge(2);
        } else if (achievementExercises) {
          // var filterExercises = result.achievements.filter(
          //   (item) => item.menu === 2
          // );
          // setAchievements(filterExercises);
          // filterExercises.every((item) => item.count <= 0) ? 1 : 2
          setShowBadge(2);
        } else if (achievementMonthlyExam) {
          // var filterMonthlyExams = result.achievements.filter(
          //   (item) => item.menu === 3
          // );
          // setAchievements(filterMonthlyExams);
          // filterMonthlyExams.every((item) => item.count <= 0) ? 1 : 2
          setShowBadge(2);
        } else if (achievementDailyQuiz) {
          // var filterDailyQuiz = result.achievements.filter(
          //   (item) => item.menu === 4
          // );
          // setAchievements(filterDailyQuiz.filter((item) => item.count > 0));
          localStorage.removeItem("showDailyQuiz");
          // filterDailyQuiz.every((item) => item.count <= 0) ? 1 : 2
          setShowBadge(2);
        }
      } catch (error) {
        console.log("error", error);
      }
    };
    if (isChangeGrade && grades) {
      const lastLoginKey = "lastLoginDate";
      const clearAllKey = "clearAll";

      const keys = Object.keys(localStorage);
      keys.forEach((key) => {
        if (key !== lastLoginKey && key !== clearAllKey) {
          localStorage.removeItem(key);
        }
      });

      dispatch(setClearStateImageUrlDaily());
      dispatch(getCurrentEvent());

      dispatch(setIsChangeGrade(false));
    }
    if (achievements?.length === 0) {
      getAchievements();
    } else {
      if (achievementAll) {
        setShowBadge(achievements.every((item) => item.count < 1) ? 1 : 2);
      } else if (achievementLesson) {
        // var filterLesson = achievements.filter((item) => item.menu === 1);
        // setAchievements(filterLesson);
        setShowBadge(2);
      } else if (achievementExercises) {
        // var filterExercises = achievements.filter((item) => item.menu === 2);
        // setAchievements(filterExercises);
        // filterExercises.every((item) => item.count <= 0) ? 1 :2
        setShowBadge(2);
      } else if (achievementMonthlyExam) {
        // var filterMonthlyExams = achievements.filter((item) => item.menu === 3);
        // setAchievements(filterMonthlyExams);
        // filterMonthlyExams.every((item) => item.count <= 0) ? 1 : 2
        setShowBadge(2);
      } else if (achievementDailyQuiz) {
        // var filterDailyQuiz = achievements.filter((item) => item.menu === 4);
        // setAchievements(filterDailyQuiz.filter((item) => item.count > 0));
        localStorage.removeItem("showDailyQuiz");
        // filterDailyQuiz.every((item) => item.count <= 0) ? 1 : 2
        setShowBadge(2);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    achievementDailyQuiz,
    achievementExercises,
    achievementLesson,
    achievementAll,
    achievementMonthlyExam,
    // achievements,
    dispatch,
    grades,
    isChangeGrade,
  ]);

  if (isLoading) {
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }

  return (
    <div
      className="is-flex is-flex-direction-column m-auto is-align-items-center is-relative"
      style={{
        width: "50rem",
        paddingTop: "1rem",
        gap: "2.5rem",
        paddingBottom: "3rem",
        top: "0",
        minHeight: "100vh",
      }}
    >
      <ScrollRefWrapper />
      {showPopupStartDailyMission && (
        <DndProvider backend={HTML5Backend}>
          <DailyMissionMainWrapper
            setShowPopupStartDailyMission={setShowPopupStartDailyMission}
            setShowPopupCongratulations={setShowPopupCongratulations}
            showPopupCongratulations={showPopupCongratulations}
          />
        </DndProvider>
      )}

      {showPopupAchievementDetail && (
        <AchievementDetail
          achievementDetail={achievementDetail}
          setShowPopupAchievementDetail={setShowPopupAchievementDetail}
        />
      )}

      {showPopupCongratulations && (
        <BackgroundImageFromUrl
          objectId="admin/frontend-images/hub/popup-chuc-mung-dat-thanh-tich.svg"
          className="modal is-active modal-background"
          style={{
            opacity: 1,
            zIndex: 1000,
            width: "100%",
            height: "100%",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          <div className="is-flex is-flex-direction-row is-align-items-center">
            <div
              className="is-flex is-flex-direction-column"
              style={{ gap: "2.5rem" }}
            >
              <p
                style={{
                  fontSize: "8rem",
                  textAlign: "center",
                  fontFamily: "Sriracha",
                  lineHeight: "87.5%",
                  fontWeight: 400,
                  color: "#FAFBFC",
                }}
              >
                Chúc <br /> mừng!
              </p>

              <p
                style={{
                  textAlign: "center",
                  fontSize: "1.5rem",
                  fontFamily: "Montserrat",
                  lineHeight: "normal",
                  fontWeight: 500,
                  color: "#FAFBFC",
                }}
              >
                Bạn đã đạt được thêm <br />{" "}
                <b>1 huy hiệu cho Bảng Thành Tích!</b>
              </p>

              <div
                className="is-flex is-flex-direction-row"
                style={{ gap: "1rem" }}
              >
                <PrimaryButtonPageLevel
                  style={{
                    height: "4rem",
                    width: "20rem",
                    borderRadius: "1rem",
                    maxWidth: "20rem",
                  }}
                  isDarkColor={true}
                  onClick={handleNavigate}
                >
                  TỚI TRANG THÀNH TÍCH
                </PrimaryButtonPageLevel>
                <PrimaryButtonPageLevel
                  style={{
                    height: "4rem",
                    width: "10rem",
                    borderRadius: "1rem",
                  }}
                  onClick={removePopup}
                >
                  Đóng
                </PrimaryButtonPageLevel>
              </div>
            </div>

            <div style={{ width: "33.22rem" }}>
              <div className="is-relative" style={{ marginLeft: "-3rem" }}>
                <img src="/images/hub/background-congratulations.svg" alt="" />
                <div
                  style={{
                    position: "absolute",
                    left: "6.3rem",
                    top: "7rem",
                  }}
                >
                  <div className="is-relative">
                    <ImageFromUrl
                      style={{
                        position: "absolute",
                        left: "3.3rem",
                        top: "-4rem",
                      }}
                      objectId="admin/frontend-images/hub/image-congratulations-12.svg"
                      alt=""
                    />
                    <ImageFromUrl
                      objectId="admin/frontend-images/hub/bg-laurel.svg"
                      alt=""
                    />
                  </div>

                  <div
                    style={{
                      padding: "1.5rem 4rem",
                      width: "20.5rem",
                      height: "6.625rem",
                      borderRadius: "5rem",
                      border: "8px solid #fed131",
                      margin: "0 auto",
                    }}
                    className="is-flex is-align-items-center has-background-kurio-yellow-light-3"
                  >
                    <p
                      className="has-text-kurio-main-color-black"
                      style={{
                        fontSize: "1.5rem",
                        fontWeight: 700,
                        lineHeight: "normal",
                        textAlign: "center",
                      }}
                    >
                      Hạ gục Quái vật Tháng {new Date().getMonth() + 1}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </BackgroundImageFromUrl>
      )}

      <div className="is-flex is-flex-direction-column is-flex-gap-5 has-text-kurio-main-color-black is-align-items-center">
        <p className="has-text-weight-bold is-size-3 has-text-centered">
          Thành tích
        </p>

        <div
          className="has-background-kurio-purple-light-3 is-flex is-align-items-center is-justify-content-center"
          style={{
            width: "50rem",
            height: "10rem",
            borderRadius: "5rem",
            border: "0.09375rem solid #0A2A66",
            boxShadow: "0.125rem 0.25rem 0rem 0rem #0A2A66",
          }}
        >
          <div
            className="has-text-kurio-purple-main-accent is-flex is-flex-direction-column is-align-items-center"
            style={{
              gap: "0.125rem",
            }}
          >
            <p>Điểm tích lũy</p>
            <div className="is-flex is-align-items-center is-flex-gap-2">
              <p
                className="has-text-weight-bold"
                style={{ fontSize: "4rem", lineHeight: "normal" }}
              >
                {lifeTime}
              </p>
              <img
                style={{
                  width: "4.0056rem",
                  height: "4rem",
                }}
                src="/images/hub/star.png"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
      <div
        className={`${
          achievementAll ||
          achievementExercises ||
          achievementLesson ||
          achievementMonthlyExam ||
          achievementDailyQuiz
            ? "has-text-kurio-main-color-black"
            : "has-text-kurio-main-color-white"
        } is-flex is-align-items-flex-start is-justify-content-center menu-achievement`}
        style={{
          gap: "1.5rem",
          fontSize: "1.125rem",
        }}
      >
        <li
          className={`${
            achievementAll
              ? "has-background-kurio-main-color-black has-text-kurio-main-color-white has-text-weight-bold"
              : "menu-item"
          } is-clickable has-text-weight-bold`}
          onClick={handleShowAll}
          style={achievementAll ? styleOnChange : styleNone}
        >
          Tất cả
        </li>
        <li
          className={`${
            achievementLesson
              ? "has-background-kurio-main-color-black has-text-kurio-main-color-white has-text-weight-bold"
              : "menu-item"
          } is-clickable has-text-weight-bold`}
          onClick={handleShowLesson}
          style={achievementLesson ? styleOnChange : styleNone}
        >
          Bài giảng
        </li>
        <li
          className={`${
            achievementExercises
              ? "has-background-kurio-main-color-black has-text-kurio-main-color-white has-text-weight-bold"
              : "menu-item"
          } is-clickable has-text-weight-bold`}
          onClick={handleShowExercises}
          style={achievementExercises ? styleOnChange : styleNone}
        >
          Bài thi
        </li>
        <li
          className={`${
            achievementMonthlyExam
              ? "has-background-kurio-main-color-black has-text-kurio-main-color-white has-text-weight-bold"
              : "menu-item"
          } is-clickable has-text-weight-bold`}
          onClick={handleShowMonthlyExam}
          style={achievementMonthlyExam ? styleOnChange : styleNone}
        >
          Xếp hạng
        </li>
        <li
          className={`${
            achievementDailyQuiz
              ? "has-background-kurio-main-color-black has-text-kurio-main-color-white has-text-weight-bold"
              : "menu-item"
          } is-clickable has-text-weight-bold`}
          onClick={handleShowDailyQuiz}
          style={achievementDailyQuiz ? styleOnChange : styleNone}
        >
          Chuyên cần
        </li>
      </div>
      {isLoading && <span className="loader"></span>}
      <div className="is-flex is-flex-direction-column is-align-items-center is-flex-gap-5">
        {newAchievements &&
          !isLoading &&
          // checkState(achievements) &&
          showBadge === 2 &&
          newAchievements?.length > 0 &&
          newAchievements.map((items, index) => (
            <div
              className="is-flex is-flex-direction-column is-align-items-center is-align-content-center is-justify-content-center"
              style={{ gap: "1.5rem", width: "38rem" }}
              key={index}
            >
              {achievementAll && (
                <p
                  className="has-text-kurio-main-color-black has-text-weight-bold"
                  style={{
                    fontSize: "1.5rem",
                    lineHeight: "normal",
                    fontStyle: "normal",
                  }}
                >
                  {items[0]?.menu === 1
                    ? "Bài giảng"
                    : items[0]?.menu === 2
                    ? "Bài thi"
                    : items[0]?.menu === 3
                    ? "Xếp hạng"
                    : items[0]?.menu === 4
                    ? "Chuyên cần"
                    : ""}
                </p>
              )}
              {/* <div
                className="is-flex is-flex-direction-column is-align-items-center is-align-content-center is-justify-content-center"
                style={{ gap: "1.5rem", width: "38rem" }}
              >
              </div> */}
              <div
                className="is-flex is-align-items-flex-start is-flex-wrap-wrap is-justify-content-center"
                style={{ width: "38rem", gap: "2.5rem" }}
              >
                {items.map((achievement, itemIndex) => (
                  <div
                    key={itemIndex}
                    className="has-text-kurio-main-color-black has-text-centered is-flex is-flex-direction-column is-align-items-center is-flex-gap-5"
                    style={{ width: "10rem", fontSize: "1.125rem" }}
                  >
                    {achievement.count > 0 && (
                      <div
                        className="is-flex is-flex-direction-column is-flex-gap-5 has-text-centered"
                        style={{ lineHeight: "normal", maxWidth: "10rem" }}
                      >
                        <div
                          onClick={() =>
                            handleShowPopupAchievementDetail(achievement)
                          }
                          style={
                            achievement?.achievedImageUrl !== null &&
                            achievement?.achievedImageUrl?.length > 0
                              ? { ...boxStyle, zIndex: 1 }
                              : { width: "10rem", height: "12.5rem" }
                          }
                          className={`${
                            achievement.achievedImageUrl !== null &&
                            achievement.achievedImageUrl?.length > 0
                              ? "has-background-kurio-main-color-white"
                              : ""
                          } is-flex is-align-items-center is-justify-content-center  is-relative`}
                        >
                          <div
                            className="is-flex is-align-items-center"
                            style={{
                              backgroundSize: "cover",
                              backgroundRepeat: "no-repeat",
                              backgroundPosition: "center",
                              backgroundImage:
                                achievement?.achievedImageUrl !== null &&
                                achievement?.achievedImageUrl?.length > 0
                                  ? "url('/images/achievement/background-achievement.svg')"
                                  : "",
                              width: "9rem",
                              height: "11.5rem",
                              position: "absolute",
                              zIndex: 2,
                            }}
                          ></div>
                          <ImageFromUrl
                            className="is-relative"
                            isAchievement={true}
                            style={{
                              // objectFit: "contain",
                              height: "11.5rem",
                              width: "9rem",
                              borderRadius: "0.5rem",
                              zIndex: 1,
                            }}
                            objectId={
                              achievement?.achievedImageUrl !== null &&
                              achievement?.achievedImageUrl?.length > 0
                                ? achievement.achievedImageUrl
                                : "admin/frontend-images/achievement/badge-image.svg"
                            }
                            alt=""
                          />
                        </div>
                        {achievement.count >= 1 && achievement.type === 2 && (
                          <p className="is-size-3 has-text-weight-bold">
                            {achievement.type === 2 && "x "}
                            {achievement.count}
                          </p>
                        )}
                        {achievement.count > 0 && achievement.count < 1 && (
                          <p className="is-size-3 has-text-weight-bold is-lowercase">
                            {achievement.count}%
                          </p>
                        )}
                        <p style={{ maxWidth: "10rem" }}>{achievement.name}</p>
                      </div>
                    )}
                    {achievement.count <= 0 && (
                      <div
                        className="is-flex is-flex-direction-column is-flex-gap-5 has-text-centered"
                        style={{ lineHeight: "normal", maxWidth: "10rem" }}
                      >
                        <div
                          onClick={() =>
                            handleShowPopupAchievementDetail(achievement)
                          }
                          style={
                            achievement?.unachievedImageUrl !== null &&
                            achievement?.unachievedImageUrl?.length > 0
                              ? {
                                  ...boxStyle,
                                  boxShadow:
                                    "0.125rem 0.25rem 0rem 0rem #000000",
                                  borderColor: "#000000",
                                  zIndex: 1,
                                }
                              : { width: "10rem", height: "12.5rem" }
                          }
                          className={`${
                            achievement.unachievedImageUrl !== null &&
                            achievement.unachievedImageUrl?.length > 0
                              ? "has-background-kurio-purple-light-3"
                              : ""
                          } is-flex is-align-items-center is-justify-content-center is-relative`}
                        >
                          <div
                            onClick={() => console.log(2)}
                            className="is-flex is-align-items-center"
                            style={{
                              backgroundSize: "cover",
                              backgroundRepeat: "no-repeat",
                              backgroundPosition: "center",
                              backgroundImage:
                                achievement?.unachievedImageUrl !== null &&
                                achievement?.unachievedImageUrl?.length > 0
                                  ? "url('/images/achievement/background-achievement.svg')"
                                  : "",
                              width: "9rem",
                              height: "11.5rem",
                              position: "absolute",
                              filter: "grayScale(100%)",
                              zIndex: 2,
                            }}
                          ></div>
                          <ImageFromUrl
                            className="is-relative"
                            isAchievement={true}
                            style={{
                              // objectFit: "contain",
                              height: "11.5rem",
                              width: "9rem",
                              borderRadius: "0.5rem",
                              zIndex: 1,
                            }}
                            objectId={
                              achievement?.unachievedImageUrl !== null &&
                              achievement?.unachievedImageUrl?.length > 0
                                ? achievement.unachievedImageUrl
                                : "admin/frontend-images/achievement/badge-image-none.svg"
                            }
                            alt=""
                          />
                        </div>
                        {/* {achievement.count >= 1 && achievement.type === 2 && (
                            <p className="is-size-3 has-text-weight-bold">
                              {achievement.type === 2 && "x "}
                              {achievement.count}
                            </p>
                          )}
                          {achievement.count > 0 && achievement.count < 1 && (
                            <p className="is-size-3 has-text-weight-bold is-lowercase">
                              {achievement.count}%
                            </p>
                          )} */}
                        <p style={{ maxWidth: "10rem" }}>{achievement.name}</p>
                      </div>
                    )}
                  </div>
                ))}
              </div>
              {items[0]?.menu !== 4 && achievementAll && (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="608"
                  height="2"
                  viewBox="0 0 608 2"
                >
                  <path
                    d="M0 1H608"
                    stroke="#8C9DC1"
                    strokeWidth="2"
                    strokeDasharray="8 8"
                  />
                </svg>
              )}
            </div>
          ))}
        {newAchievements &&
          !isLoading &&
          // achievementDailyQuiz &&
          // checkState(achievements) &&
          showBadge === 1 && (
            // <div className="is-flex is-align-items-center is-flex-gap-8">
            //   <div
            //     className="has-text-kurio-main-color-black has-text-centered is-flex is-flex-direction-column is-align-items-center is-flex-gap-5"
            //     style={{ width: "9rem", fontSize: "1.125rem" }}
            //   >
            //   </div>
            // </div>
            <div
              className="is-flex is-flex-direction-column is-flex-wrap-wrap is-align-items-center is-align-content-center is-justify-content-center"
              style={{ gap: "2.5rem", width: "38rem" }}
            >
              <div
                className="is-flex is-flex-direction-row is-justify-content-center is-align-items-center is-flex-gap-8"
                style={{ lineHeight: "normal", width: "29rem" }}
              >
                <img
                  style={{ objectFit: "cover" }}
                  src={`${
                    achievementAll
                      ? "/images/achievement/achievement-all-empty.svg"
                      : achievementLesson
                      ? "/images/achievement/achievement-lesson-empty.svg"
                      : achievementDailyQuiz
                      ? "/images/achievement/achievement-empty.svg"
                      : achievementExercises
                      ? "/images/achievement/achievement-exercise-empty.svg"
                      : achievementMonthlyExam
                      ? "/images/achievement/achievement-monthly-exam-empty.svg"
                      : ""
                  }`}
                  alt=""
                />
                <div className="is-flex is-flex-direction-column is-flex-gap-5 is-align-items-flex-start">
                  <p
                    className="has-text-kurio-main-color-black"
                    style={{
                      fontSize: "1.125rem",
                      lineHeight: "normal",
                      fontStyle: "normal",
                    }}
                  >
                    {achievementAll
                      ? "Bắt đầu hành trình thu thập các Huy hiệu Đặc biệt của Kurio nào!"
                      : achievementLesson
                      ? "Hoàn thành Bài giảng để mở khóa các Huy hiệu mới!"
                      : achievementDailyQuiz
                      ? "Đánh bại Quái vật Tháng để thu thập các Huy hiệu Đặc biệt!"
                      : achievementExercises
                      ? "Chăm chỉ Luyện tập để thu thập các Huy hiệu Đặc biệt!"
                      : achievementMonthlyExam
                      ? "Đạt thứ hạng cao trong kỳ Thi tháng để thu thập các Huy hiệu Đặc biệt!"
                      : ""}
                  </p>
                  <PrimaryButtonPageLevel
                    onClick={handleClick}
                    style={{ height: "4rem", borderRadius: "1rem" }}
                  >
                    {achievementAll
                      ? "về trang chủ"
                      : achievementLesson
                      ? "học tiếp"
                      : achievementDailyQuiz
                      ? "chiến đấu ngay"
                      : achievementExercises
                      ? "thử sức"
                      : achievementMonthlyExam
                      ? "làm ngay"
                      : ""}
                  </PrimaryButtonPageLevel>
                </div>
              </div>
            </div>
          )}
      </div>
    </div>
  );
};
function mapStateToProps(state) {
  return {
    questionDaily: state.dailyMission.questions,
    startHP: state.dailyMission.startingHp,
    currentHP: state.dailyMission.currentHp,
    imageUrls: state.dailyMission.imageUrls,
    isChangeGrade: state.grades.isChangeGrade,
    grades: state.grades.gradeId,
  };
}

export default connect(mapStateToProps)(AchievementMainWrapper);
