import React from "react";
import { useNavigate } from "react-router-dom";
import PrimaryButtonLevelSection from "../../components/button/primary-button/PrimaryButtonLevelSection";

const SemesterExamPreparation = () => {
  const navigate = useNavigate();
  return (
    <div className="is-flex is-flex-direction-column is-align-items-center is-justify-content-center is-relative">
      <div className="is-flex is-flex-direction-column is-align-items-center is-flex-gap-5 has-text-kurio-main-color-black">
        <p
          className="has-text-weight-bold"
          style={{
            fontSize: "2rem",
            lineHeight: "normal",
            fontStyle: "normal",
          }}
        >
          Luyện thi Học kỳ
        </p>
        <div className=" is-flex is-flex-gap-6 is-align-items-flex-start is-justify-content-center">
          <div
            className="is-flex is-flex-direction-column is-align-items-center has-background-kurio-main-color-white is-justify-content-center"
            style={{
              border: "0.09375rem solid #0A2A66",
              borderRadius: "1rem",
              width: "25rem",
              height: "15rem",
              boxShadow: "0.125rem 0.25rem 0rem 0rem #0A2A66",
            }}
          >
            <div
              className="is-flex is-flex-direction-column has-background-kurio-red-main-accent is-align-items-center is-justify-content-center"
              style={{ width: "23rem", height: "13rem" }}
            >
              <div className="is-flex is-flex-direction-row is-flex-gap-7">
                <img src="/images/semester-exam/icon_1.svg" alt="" />
                <div
                  style={{ width: "10.75rem", gap: "1rem" }}
                  className="is-flex is-flex-direction-column is-align-items-center is-justify-content-center has-text-kurio-main-color-white"
                >
                  <p
                    style={{
                      fontSize: "2rem",
                      fontFamily: "Dela Gothic One",
                      textAlign: "center",
                      lineHeight: "2.2rem",
                    }}
                  >
                    Luyện thi Học kỳ 1
                  </p>
                  <PrimaryButtonLevelSection
                    isDarkColor={true}
                    onClick={() => navigate("/exam-preparation-HK1")}
                  >
                    bắt đầu
                  </PrimaryButtonLevelSection>
                </div>
              </div>
            </div>
          </div>
          <div
            className="is-flex is-flex-direction-column is-align-items-center has-background-kurio-main-color-white is-justify-content-center"
            style={{
              border: "0.09375rem solid #0A2A66",
              borderRadius: "1rem",
              width: "25rem",
              height: "15rem",
              boxShadow: "0.125rem 0.25rem 0rem 0rem #0A2A66",
            }}
          >
            <div
              className="is-flex is-flex-direction-column has-background-kurio-green-main-accent is-align-items-center is-justify-content-center"
              style={{ width: "23rem", height: "13rem" }}
            >
              <div className="is-flex is-flex-direction-row is-flex-gap-7">
                <img src="/images/semester-exam/icon_2.svg" alt="" />
                <div
                  style={{ width: "10.75rem", gap: "1rem" }}
                  className="is-flex is-flex-direction-column is-align-items-center is-justify-content-center has-text-kurio-main-color-white"
                >
                  <p
                    style={{
                      fontSize: "2rem",
                      fontFamily: "Dela Gothic One",
                      textAlign: "center",
                      lineHeight: "2.2rem",
                    }}
                  >
                    Luyện thi Học kỳ 2
                  </p>
                  <PrimaryButtonLevelSection
                    isDarkColor={true}
                    onClick={() => navigate("/exam-preparation-HK2")}
                  >
                    bắt đầu
                  </PrimaryButtonLevelSection>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SemesterExamPreparation;
