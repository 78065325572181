/* eslint-disable no-useless-escape */
import React, { useState } from "react";
import ConstantStrings from "../../constants/ConstantStrings";
import ImageFromUrl from "../parseImage/ImageFromUrl";
import "./GapFill.style.css";
const GapFill = ({
  choice,
  answer,
  correctAnswers,
  onChange,
  questionSolutions,
  checkingState,
  placeholder,
  index,
}) => {
  var gapStyle = {
    width: "10rem",
    height: "3rem",
    padding: "0.5rem",
    borderRadius: "0.5rem",
    fontWeight: "bold",
    border: "0.0625rem solid",
    textAlign: "center",
    fontSize: "1.125rem",
  };
  var focusStyle = {
    ...gapStyle,
    border: "0.125rem solid",
  };
  var imgStyle = {
    width: "3rem",
    height: "3rem",
  };

  var iconStyle = {
    zIndex: 1,
    position: "absolute",
    width: "1.5rem",
    height: "1.5rem",
    top: "1.25rem",
    right: "-0.25rem",
  };
  const [focus, setFocus] = useState(false);
  const handleChangeValue = (index) => (e) => {
    e.preventDefault();
    const regex = /^-?[a-zA-Z0-9.,\/]*$/;
    var value = e.target.value;
    if (regex.test(value) === true || !value) {
      onChange(e);
    }
  };
  var imageUrl = ConstantStrings.IMAGE_URL + encodeURIComponent(choice);
  imageUrl = imageUrl.replace(/\(/g, "%28").replace(/\)/g, "%29");

  const IconComponent = ({ index }) => {
    return (
      <>
        {checkingState ? (
          correctAnswers === true || questionSolutions ? (
            <img
              src="/images/common/greenV.svg"
              style={iconStyle}
              alt="loading"
            />
          ) : (
            <img
              src="/images/common/redX.svg"
              style={iconStyle}
              alt="loading"
            />
          )
        ) : (
          <div
            className="icon"
            style={{ ...iconStyle, width: "0.75rem", height: "0.75rem" }}
          ></div>
        )}
      </>
    );
  };

  return (
    <div>
      <div
        className="column is-flex is-flex-direction-row is-relative is-narrow p-2 is-relative"
        style={{ gap: "1rem" }}
      >
        {choice && choice.length > 0 && (
          <>
          <ImageFromUrl style={imgStyle} objectId={choice} />
            {/* <img src={imageUrl} style={imgStyle} alt={choice} /> */}
          </>
        )}
        <div>
          <input
            style={focus && !checkingState ? focusStyle : gapStyle}
            onFocus={() => {
              setFocus(true);
            }}
            onBlur={() => {
              setFocus(false);
            }}
            className={`${
              correctAnswers === true || questionSolutions
                ? "has-background-kurio-green-light-3 is-kurio-green-main-accent has-text-kurio-green-main-accent"
                : correctAnswers === false
                ? "has-background-kurio-red-light-3 is-kurio-red-main-accent has-text-kurio-red-main-accent"
                : focus
                ? "has-background-kurio-main-color-white has-text-kurio-main-color-black is-kurio-main-color-black"
                : ""
            } input has-text-centered is-shadowless has-text-kurio-main-color-black has-text-weight-bold`}
            type="text"
            placeholder={placeholder ? placeholder : "Dien vao day"}
            value={questionSolutions ? questionSolutions : answer || ""}
            onChange={handleChangeValue(index)}
            disabled={checkingState}
          />
          <IconComponent index={index} />
        </div>
      </div>
    </div>
  );
};

export default GapFill;
