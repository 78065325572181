import React from "react";
import "./Numberle.style.css";

const Score = ({ rePlay, handleShowModal }) => {
  return (
    <div className="has-text-kurio-main-color-black pt-4 is-flex is-flex-direction-column is-align-items-center is-justify-content-center group-btn-numberle">
      <div
        className="btn-numberle is-flex is-align-items-center is-justify-content-center is-clickable is-uppercase has-text-weight-bold has-text-kurio-main-color-purple has-background-kurio-purple-light-2"
        style={{
          height: "3rem",
          borderRadius: "1rem",
          width: "11rem",
        }}
        onClick={handleShowModal}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M6.49994 7.50024C6.49994 5.57024 8.06994 4.00024 9.99994 4.00024C11.9299 4.00024 13.4999 5.57024 13.4999 7.50024C13.4999 9.08024 12.4399 10.4032 10.9999 10.8372V12.0002C10.9999 12.5532 10.5529 13.0002 9.99994 13.0002C9.44694 13.0002 8.99994 12.5532 8.99994 12.0002V10.0002C8.99994 9.44724 9.44694 9.00024 9.99994 9.00024C10.8269 9.00024 11.4999 8.32724 11.4999 7.50024C11.4999 6.67324 10.8269 6.00024 9.99994 6.00024C9.17294 6.00024 8.49994 6.67324 8.49994 7.50024C8.49994 8.05324 8.05294 8.50024 7.49994 8.50024C6.94694 8.50024 6.49994 8.05324 6.49994 7.50024ZM8.99957 15.0002C8.99957 14.4472 9.44657 14.0002 9.99957 14.0002C10.5526 14.0002 10.9996 14.4472 10.9996 15.0002C10.9996 15.5532 10.5526 16.0002 9.99957 16.0002C9.44657 16.0002 8.99957 15.5532 8.99957 15.0002ZM9.99997 18.0001C5.58897 18.0001 1.99997 14.4111 1.99997 10.0001C1.99997 5.58912 5.58897 2.00012 9.99997 2.00012C14.411 2.00012 18 5.58912 18 10.0001C18 14.4111 14.411 18.0001 9.99997 18.0001ZM10 0C4.486 0 0 4.486 0 10C0 15.514 4.486 20 10 20C15.514 20 20 15.514 20 10C20 4.486 15.514 0 10 0Z"
            fill="#572CD1"
          />
        </svg>
        <p>Hướng dẫn</p>
      </div>
      <div
        className=" btn-numberle is-clickable is-flex is-align-items-center is-justify-content-center is-uppercase has-text-weight-bold has-text-kurio-main-color-white has-background-kurio-main-color-purple"
        style={{ height: "3rem", borderRadius: "1rem", width: "11rem" }}
        onClick={rePlay}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
        >
          <path
            d="M10 0L12.7009 7.29909L20 10L12.7009 12.7009L10 20L7.29909 12.7009L0 10L7.29909 7.29909L10 0Z"
            fill="white"
          />
        </svg>
        <p>game mới</p>
      </div>
    </div>
  );
};

export default Score;
