/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Countdown, { zeroPad } from "react-countdown";
import { connect, useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import DuplicateLoginErrorComponent from "../../components/DuplicateLoginErrorComponent";
import NotificationUpgradeAccount from "../../components/NotificationUpgradeAccount";
import NextButton from "../../components/button/pagination-button/NextButton";
import PreviousButton from "../../components/button/pagination-button/PreviousButton";
import PrimaryButtonPageLevel from "../../components/button/primary-button/PrimaryButtonLevelPage";
import PrimaryButtonLevelSection from "../../components/button/primary-button/PrimaryButtonLevelSection";
import PopupCloseButton from "../../components/button/special-button/PopupCloseButton";
import ImageFromUrl from "../../components/parseImage/ImageFromUrl";
import { checkServerTimeError } from "../../config/checkAndCallDailyLoginApi";
import ScrollRefWrapper from "../../detail-components/ScrollRefWrapper";
import {
  clearState,
  clearTakeExamId,
  createNewExams,
  getExamQuestion,
  getListYearExamsQuestion,
  setQuestionsAnswered,
  setTakeExamIdInformation,
  setTakeExamIdLPInformation,
  setTakeExamsQuestion,
} from "../../reducers/examsQuestionSlice";
import { getGradeUserInfo, setIsChangeGrade } from "../../reducers/gradesSlice";
import { checkPremium } from "../../templates/helper/CheckAndGetAnonymousUserId";
import "./ExamsQuestionVault.css";

const ExamsQuestionVault = ({
  examQuestions,
  recordCount,
  pageCount,
  grades,
  takeExamIdInformation,
  takeExamIdLPInformation,
  takeExamsQuestionId,
  gradeName,
  status,
  error,
  examInformation,
  isChangeGrade,
  serverTime,
  deadline,
  questions,
  listUserInfo,
  errorGrade,
}) => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // const cookies = new Cookies();
  // const token = cookies.get("signinUser");

  const [showOption, setShowOption] = useState(false);
  const [listYears, setListYears] = useState([]);
  const [selectedYear, setSelectedYear] = useState("");
  const [currentPage, setCurrentPage] = useState(
    parseInt(searchParams.get("page")) || 1
  );
  const [examId, setExamId] = useState("");
  const [showPopupExam, setShowPopupExam] = useState(false);
  const [showPopupSuccessExam, setShowPopupSuccessExam] = useState(false);
  const [showPopupStartExam, setShowPopupStartExam] = useState(false);
  const [startExam, setStartExam] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [loadingPopup, setLoadingPopup] = useState(false);
  // const [apiCalled, setApiCalled] = useState(false);
  const [start, setStart] = useState(false);
  const [changeTime, setChangeTime] = useState(false);
  // const [timeDifference, setTimeDifference] = useState(null);
  const [countdownDate, setCountDownDate] = useState(null);
  // const [count, setCount] = useState(0);
  const [timeLeft, setTimeLeft] = useState(null);
  const [showNotification, setShowNotification] = useState(false);
  const [showServerTimeError, setShowServerTimeError] = useState(false);
  // if (new Date(serverTime).getTime() > 0 && timeDifference === null) {
  //   // timeDifference = new Date(serverTime).getTime() - new Date().getTime();
  //   setTimeDifference(new Date(serverTime).getTime() - new Date().getTime());

  //   // localStorage.setItem("timeDifference",JSON.stringify(timeDifference))
  // }

  const page = 1;
  const size = 60;

  const selectedPage = {
    background: "#E9EEF8",
    padding: "0.525rem",
    borderRadius: "2rem",
    width: "2.5rem",
    height: "2.5rem",
    fontSize: "1rem",
    color: "#0A2A66",
    fontWeight: 700,
  };

  const handleClick = (event, page) => {
    event.preventDefault();
    searchParams.set("page", page);
    navigate(`${window.location.pathname}?${searchParams.toString()}`);
    setCurrentPage(page);
  };

  const handlePreviousButton = () => {
    if (currentPage !== 1) {
      setCurrentPage(page - 1);
      searchParams.set("page", page - 1);
      navigate(`${window.location.pathname}?${searchParams.toString()}`);
    }
  };

  const handleNextButton = () => {
    if (pageCount !== currentPage) {
      setCurrentPage(page + 1);
      searchParams.set("page", page + 1);
      navigate(`${window.location.pathname}?${searchParams.toString()}`);
    }
  };
  const listExamInfo = JSON.parse(localStorage.getItem("listExamInfo"));
  const findTakeExamId = (action, examId) => {
    var result = "";
    if (action === "takeExamId") {
      listExamInfo?.map((item) =>
        item.id === examId ? (result = item.takeExamId) : item
      );
    } else if (action === "takeExamLP") {
      listExamInfo?.map((item) =>
        item.id === examId ? (result = item.takeExamLP) : item
      );
    }
    return result;
  };

  // const pages = examQuestions && Math.ceil(examQuestions.length / recordCount);
  const startIndex = (currentPage - 1) * recordCount;
  const endIndex = startIndex + recordCount;

  const currentItems =
    examQuestions && examQuestions.slice(startIndex, endIndex);

  const renderPageNumbers = () => {
    const pageNumbers = [];
    for (let i = 1; i <= pageCount; i++) {
      pageNumbers.push(
        <li
          style={i === currentPage ? selectedPage : {}}
          key={i}
          onClick={(e) => handleClick(e, i)}
          to={`?page=${i}`}
          className={i === currentPage ? "active" : null}
        >
          {i}
        </li>
      );
    }
    return pageNumbers;
  };

  const handleClickOption = () => {
    setShowOption(!showOption);
  };

  const handleSelectItemOption = (yearNow) => {
    if (yearNow !== -1) {
      searchParams.set("yearNow", yearNow);
    } else {
      searchParams.set("yearNow", "Tat_ca_cac_nam");
    }
    navigate(`${window.location.pathname}?${searchParams.toString()}`);
    setSelectedYear(yearNow);
    setShowOption(false);
  };

  const handleClickExamsQuestion = async (examsQuestionId, examName) => {
    localStorage.setItem("gradeName", getGradeName(gradeName));
    const checkServerTime = await checkServerTimeError();
    setShowServerTimeError(checkServerTime);
    var yearExamNow = examQuestions.filter(
      (item) => item._id === examsQuestionId
    );
    localStorage.removeItem("questionStateExams");
    localStorage.removeItem("pagination");

    localStorage.setItem(
      "currentScore",
      handleCheckCurrentScore(examsQuestionId)
    );
    localStorage.setItem("maxScore", handleCheckMaxScore(examsQuestionId));

    localStorage.setItem("yearExamNow", JSON.stringify(yearExamNow));

    localStorage.setItem("nameExam", examName);
    var takeExamId = findTakeExamId("takeExamId", examsQuestionId);
    var takeExamIdLP = findTakeExamId("takeExamLP", examsQuestionId);
    dispatch(setTakeExamIdInformation(takeExamId));
    dispatch(setTakeExamIdLPInformation(takeExamIdLP));
    setExamId(examsQuestionId);

    // if (
    //   listUserInfo?.premiumFrom &&
    //   listUserInfo?.premiumTo &&
    //   (new Date(listUserInfo?.premiumFrom).getTime() > new Date().getTime() ||
    //     new Date(listUserInfo?.premiumTo).getTime() < new Date().getTime())
    // ) {
    //   setShowNotification(true);
    // } else {
    // }
    if (takeExamId !== null && questions === null) {
      dispatch(
        getExamQuestion({
          takeExamsQuestionId: takeExamId,
          page,
          size,
        })
      );
      setLoadingPopup(true);
      localStorage.setItem("examsQuestionId", examsQuestionId);
      // setShowPopupExam(true);
    } else if (takeExamIdLP !== null) {
      setShowPopupSuccessExam(true);
    } else {
      setShowPopupStartExam(true);
      // dispatch(createNewExams({ examsQuestionId }));
      localStorage.setItem("examsQuestionId", examsQuestionId);

      // setTimeout(() => {
      //   navigate("/exam-questions", {
      //     state: { prevRouter: location.pathname },
      //   });
      // }, 500);
    }
  };

  const handleClickButton = (action) => {
    if ((!checkPremium(listUserInfo,true)) && action === "XEM LOI GIAI" ) {
      setShowNotification(true);
    } else {
      dispatch(setQuestionsAnswered([]));
      dispatch(clearState([]));

      if (action === "LAM LAI") {
        localStorage.setItem("examsQuestionId", examId);
        if (examId.length > 0) {
          dispatch(
            createNewExams({
              examsQuestionId: examId,
            })
          );
        }
      } else if (action === "LAM TIEP") {
        localStorage.setItem("examsQuestionId", examId);
        if (takeExamIdInformation) {
          localStorage.setItem("takeExamIdInformation", takeExamIdInformation);
          dispatch(setTakeExamsQuestion(takeExamIdInformation));
          // dispatch(
          //   getExamQuestion({
          //     takeExamsQuestionId: takeExamIdInformation,
          //     page: 1,
          //     size: 50,
          //     token,
          //   })
          // );
        }
      } else if (action === "XEM LOI GIAI") {
        localStorage.setItem("examsQuestionId", examId);
        if (takeExamIdLPInformation) {
          localStorage.setItem(
            "takeExamIdLPInformation",
            takeExamIdLPInformation
          );
          // dispatch(
          //   getExamQuestionSolutions({
          //     takeExamsQuestionId: takeExamIdLPInformation,
          //   })
          // );
        }
      } else if (action === "BAT DAU") {
        dispatch(createNewExams({ examsQuestionId: examId }));
        localStorage.setItem("examsQuestionId", examId);
      }
      setTimeout(() => {
        setStartExam(true);
      }, 500);
      // setTimeout(() => {
      //   navigate("/exam-questions", {
      //     state: { prevRouter: location.pathname },
      //   });
      // }, 500);
    }
  };

  const removePopup = () => {
    setShowPopupExam(false);
    setShowPopupStartExam(false);
    setShowPopupSuccessExam(false);
    localStorage.removeItem("nameExam");
    setTimeLeft(null);
    setStart(false);
  };

  const handleSetStartExam = (state) => {
    setStartExam(state);
  };
  const handleSetNotification = (state) => {
    if (showNotification) {
      setShowNotification(state);
    } else if (startExam) {
      setStartExam(state);
    }
  };

  const handleCheckCurrentScore = (examsQuestionId) => {
    const listExamInfo = JSON.parse(localStorage.getItem("listExamInfo"));
    const listNewExamSolutions = JSON.parse(
      localStorage.getItem("listNewExamSolutions")
    );

    let currentScore = 0;
    listExamInfo?.map((item, index) => {
      if (item.id === examsQuestionId && item.takeExamLP !== null) {
        currentScore = listNewExamSolutions[index].currentScore;
      }

      return true;
    });

    return currentScore;
  };

  const handleCheckMaxScore = (examsQuestionId) => {
    const listExamInfo = JSON.parse(localStorage.getItem("listExamInfo"));
    const listNewExamSolutions = JSON.parse(
      localStorage.getItem("listNewExamSolutions")
    );

    let maxScore = 0;
    listExamInfo?.map((item, index) => {
      if (item.id === examsQuestionId && item.takeExamLP !== null) {
        maxScore = listNewExamSolutions[index].maxScore;
      }

      return true;
    });

    return maxScore;
  };

  const removePopupOutSide = (e) => {
    if (
      e?.target?.className !== "option-filter-year" &&
      e?.target?.className !== "option-filter"
    ) {
      setShowOption(false);
    }
  };
  const getGradeName = (gradeName) => {
    var result = "";
    switch (gradeName) {
      case "Lớp 1":
        result = "Level 1";
        break;
      case "Lớp 2":
        result = "Level 1";
        break;
      case "Lớp 3":
        result = "Level 2";
        break;
      case "Lớp 4":
        result = "Level 2";
        break;
      case "Lớp 5":
        result = "Level 3";
        break;
      case "Lớp 6":
        result = "Level 3";
        break;
      case "Lớp 7":
        result = "Level 4";
        break;
      case "Lớp 8":
        result = "Level 4";
        break;
      default:
        break;
    }
    return result;
  };
  useEffect(() => {
    localStorage.removeItem("questionStateExams");
    localStorage.removeItem("targets");
    localStorage.removeItem("examMonthlyNow");
    localStorage.removeItem("listExamInfoMonthly");
    localStorage.removeItem("takeExamIdInfo");
    localStorage.removeItem("createExamQuestionId");
    localStorage.removeItem("takeExamYearQuestionId");
    localStorage.removeItem("solutionInfo");
    localStorage.removeItem("examsQuestionId");
    localStorage.removeItem("takeExamIdLPInformation");
    localStorage.removeItem("takeExamIdInformation");
    localStorage.removeItem("showSolutionId");
    localStorage.removeItem("pagination");
    localStorage.removeItem("currentIndex");
    if (gradeName?.length <= 0) {
      dispatch(getGradeUserInfo());
    }

    dispatch(clearTakeExamId());
    const lastLoginKey = "lastLoginDate";
    const clearAllKey = "clearAll";
    window.onbeforeunload = () => {
      if (window.location.pathname === "/exams-vault") {
        const keys = Object.keys(localStorage);
        keys.forEach((key) => {
          if (key !== lastLoginKey && key !== clearAllKey) {
            localStorage.removeItem(key);
          }
        });
      }
      window.history.replaceState(null, "");
    };

    if (isChangeGrade && grades) {
      dispatch(getListYearExamsQuestion({ page, size }));

      dispatch(setIsChangeGrade(false));
    }

    const getListYear = () => {
      // Lấy năm hiện tại
      const currentYear = new Date().getFullYear();

      // Tạo một mảng chứa tất cả các năm từ năm 1900 đến năm hiện tại
      const years = [];
      for (let year = 2010; year <= currentYear; year++) {
        years.push(year);
      }
      setListYears(years);
    };

    if (examQuestions !== null) {
      dispatch(clearState());
    }

    // if (examQuestions === null) {
    //   setTimeout(() => {
    //     setIsLoading(false);
    //   }, 500);
    // }

    getListYear();

    if (isLoading && examQuestions !== null) {
      setTimeout(() => {
        setIsLoading(false);
      }, 2000);
    }

    if (examQuestions !== null) {
      // if (!apiCalled) {
      //   dispatch(getListYearExamsQuestion({ page, size }));
      //   setApiCalled(true);
      // }
    } else {
      dispatch(getListYearExamsQuestion({ page, size }));
    }

    if (examQuestions === null) {
      setIsLoading(true);
    }

    // const listExamInfo = JSON.parse(localStorage.getItem("listExamInfo"));
    if (deadline !== null && !start && loadingPopup) {
      const count = Math.floor(
        new Date(deadline).getTime() - new Date(serverTime).getTime()
      );
      setTimeout(() => {
        setLoadingPopup(false);
        if (count > 0) {
          setShowPopupExam(true);
          setStart(true);
          setTimeLeft(count);
        } else {
          var takeExamId = findTakeExamId("takeExamId", examId);
          var takeExamIdLP = findTakeExamId("takeExamLP", examId);
          if (takeExamIdLP === null) {
            localStorage.setItem("showSolutionId", takeExamId);
          }
          setShowPopupExam(false);
          setShowPopupSuccessExam(true);
        }
      }, 500);
    } else {
      if (loadingPopup && deadline === null) {
        setTimeout(() => {
          setLoadingPopup(false);
          setShowPopupExam(false);
          setShowPopupStartExam(true);
        }, 500);
      }
    }
    const countdown = setInterval(() => {
      setChangeTime(true);
      // setCount((count) => count + 1);
      // setTimeLeft((timeLeft)=> timeLeft - 60000)
      setCountDownDate(new Date(serverTime).getTime() + timeLeft);
    }, 60000);
    return () => clearInterval(countdown);

    // if (listExamInfo) {
    //   listExamInfo?.map((item, index) => {
    //     dispatch(
    //       getListExamsInformation({
    //         examYearId: item.id,
    //       })
    //     );
    //     return true;
    //   });
    // }
  }, [
    grades,
    selectedYear,
    dispatch,
    currentPage,
    examQuestions,
    countdownDate,
    isChangeGrade,
    questions,
  ]);

  let listNewYear = examQuestions?.slice();

  listNewYear !== undefined &&
    listNewYear?.sort((a, b) => {
      if (a?.name?.match(/\d+/) === null && b?.name?.match(/\d+/) === null) {
        return 0; // Giữ nguyên thứ tự
      } else if (a?.name?.match(/\d+/) === null) {
        return 1; // Đưa mục không có số xuống dưới cùng
      } else if (b?.name?.match(/\d+/) === null) {
        return -1; // Đưa mục không có số xuống dưới cùng
      }

      if (a?.name?.match(/\d+/) !== null && b?.name?.match(/\d+/) !== null) {
        var soA = parseInt(a?.name?.match(/\d+/)[0]);
        var soB = parseInt(b?.name?.match(/\d+/)[0]);

        return soB - soA;
      }
      return true;
    });

  // if (error) {
  //   navigate("/exams-vault");
  // }
  if (errorGrade !== null && errorGrade === 409) {
    localStorage.setItem("clearAll", true);
    return <DuplicateLoginErrorComponent isShowPopup={true} />;
  }

  if (startExam) {
    if (
      error &&
      error.includes(
        "Bạn không phải là tài khoản premium, không thể làm bài kiểm tra này"
      )
    ) {
      return (
        <NotificationUpgradeAccount
          handleSetShowError={(state) => handleSetStartExam(state)}
          isShowPopup={startExam}
        />
      );
    } else {
      // if (status === "succeeded") {
      setTimeout(() => {
        navigate("/ikmc-exam-questions", {
          state: { prevRouter: location.pathname },
        });
      }, 500);
      // }
    }
  } else if (showNotification) {
    return (
      <NotificationUpgradeAccount
        handleSetShowError={(state) => handleSetNotification(state)}
        isShowPopup={showNotification}
      />
    );
  }
  const rerender = ({ hours, minutes, seconds, completed }) => {
    // console.log("test", (minutes + hours * 60 + seconds )*1000 - timeLeft)
    if (completed) {
      // var takeExamId = findTakeExamId("takeExamId", examId);
      // var takeExamIdLP = findTakeExamId("takeExamLP", examId);
      if (!showServerTimeError) {
        removePopup();
        window.location.reload();
      } else {
        return (
          <>
            <p
              style={{
                marginRight: "0.25rem",
                fontWeight: 700,
                fontSize: "1.5rem",
                lineHeight: "normal",
                textTransform: "uppercase",
              }}
            >
              {zeroPad(minutes + hours * 60)}
            </p>
            <div>
              <p
                style={{
                  marginRight: "0.25rem",
                  fontSize: "1.5rem",
                  lineHeight: "normal",
                }}
              >
                {" "}
                :{" "}
              </p>
            </div>

            <p
              style={{
                textTransform: "uppercase",
                display: "flex",
                fontWeight: 700,
                fontSize: "1.5rem",
                lineHeight: "normal",
              }}
            >
              {zeroPad(seconds)}
            </p>
          </>
        );
      }
    } else {
      return (
        <>
          <p
            style={{
              marginRight: "0.25rem",
              fontWeight: 700,
              fontSize: "1.5rem",
              lineHeight: "normal",
              textTransform: "uppercase",
            }}
          >
            {zeroPad(minutes + hours * 60)}
          </p>
          <div>
            <p
              style={{
                marginRight: "0.25rem",
                fontSize: "1.5rem",
                lineHeight: "normal",
              }}
            >
              {" "}
              :{" "}
            </p>
          </div>

          <p
            style={{
              textTransform: "uppercase",
              display: "flex",
              fontWeight: 700,
              fontSize: "1.5rem",
              lineHeight: "normal",
            }}
          >
            {zeroPad(seconds)}
          </p>
        </>
      );
    }
  };

  return (
    <>
      <ScrollRefWrapper />
      {loadingPopup && (
        <div
          onClick={removePopup}
          className="modal is-active modal-background"
          style={{ opacity: 1 }}
        >
          <div
            className=" has-text-kurio-main-color-black is-relative has-background-kurio-main-color-white is-flex is-flex-direction-column is-flex-gap-5 is-align-items-center is-justify-content-center"
            style={{
              width: "39rem",
              padding: "2.5rem 4rem",
              borderRadius: "2rem",
              height: "25.56rem",
            }}
          >
            <span className="loader"></span>

            <div
              onClick={removePopup}
              className=""
              style={{
                position: "absolute",
                top: "-8%",
                left: "-4%",
                zIndex: 9999,
                background: "transparent",
                border: "none",
                width: "5rem",
                fontSize: "2rem",
              }}
            >
              <PopupCloseButton />
            </div>
          </div>
        </div>
      )}

      {showPopupExam && (
        <div className="modal is-active" style={{ opacity: 1 }}>
          <div className=" modal-background" onClick={removePopup}></div>
          <div
            className=" has-text-kurio-main-color-black is-relative has-background-kurio-main-color-white is-flex is-flex-direction-column is-flex-gap-5 is-align-items-center is-justify-content-center"
            style={{
              width: "39rem",
              padding: "2.5rem 4rem",
              borderRadius: "2rem",
            }}
          >
            <div className="is-flex is-flex-direction-column is-flex-gap-5 is-align-items-center">
              <div className="has-text-centered">
                <p
                  className="has-text-centered has-text-weight-bold"
                  style={{
                    fontSize: "2.25rem",
                    lineHeight: "normal",
                  }}
                >
                  Đề thi
                </p>
                <p
                  className="is-uppercase has-text-weight-bold"
                  style={{
                    fontSize: "2.25rem",
                    lineHeight: "normal",
                  }}
                >
                  {localStorage.getItem("nameExam") ||
                    JSON.parse(localStorage.getItem("yearExamNow"))[0]?.name}
                </p>
                <p
                  className="is-uppercase has-text-weight-bold has-text-centered"
                  style={{ fontSize: "1.5rem" }}
                >
                  {getGradeName(gradeName)}
                </p>
              </div>
              <div className="is-flex is-flex-gap-4">
                <div
                  className="is-flex is-flex-direction-column is-flex-gap-2 is-align-items-center has-background-kurio-red-light-3"
                  style={{
                    borderRadius: "0.5rem",
                    padding: "1rem 1.5rem",
                    lineHeight: "normal",
                  }}
                >
                  <span style={{ fontSize: "1.25rem" }}>Thời gian làm bài</span>
                  <div className="is-flex">
                    {start && (
                      <Countdown
                        date={
                          changeTime && countdownDate !== null
                            ? countdownDate
                            : new Date(serverTime).getTime() + timeLeft
                        }
                        renderer={rerender}
                      />
                    )}
                    {!start && <div>-</div>}
                  </div>
                </div>
              </div>
            </div>
            {showServerTimeError && (
              <p className="has-text-centered has-text-kurio-main-color-black">
                <span className="has-text-danger">Cảnh báo : </span>
                Thời gian trên máy của bạn không phải múi giờ tiêu chuẩn, hãy
                đồng bộ hoá hoặc liên hệ số <b>0971 500 120</b> để được giúp đỡ
              </p>
            )}
            <div className="is-flex" style={{ gap: "1rem", margin: "0 auto" }}>
              <PrimaryButtonPageLevel
                style={{ height: "4rem" }}
                onClick={() =>
                  handleClickButton(
                    "LAM TIEP",
                    localStorage.getItem("examsQuestionId")
                  )
                }
              >
                làm tiếp
              </PrimaryButtonPageLevel>
            </div>

            <div
              onClick={removePopup}
              className=""
              style={{
                position: "absolute",
                top: "-8%",
                left: "-4%",
                zIndex: 9999,
                background: "transparent",
                border: "none",
                width: "5rem",
                fontSize: "2rem",
              }}
            >
              <PopupCloseButton />
            </div>
          </div>
        </div>
      )}

      {showPopupSuccessExam && (
        <div className="modal is-active" style={{ opacity: 1 }}>
          <div className=" modal-background" onClick={removePopup}></div>
          <div
            className=" has-text-kurio-main-color-black is-relative has-background-kurio-main-color-white is-flex is-flex-direction-column is-flex-gap-5 is-align-items-center is-justify-content-center"
            style={{
              width: "39rem",
              padding: "2.5rem 4rem",
              borderRadius: "2rem",
            }}
          >
            <div className="is-flex is-flex-direction-column is-flex-gap-5 is-align-items-center">
              <div className="has-text-centered">
                <p
                  className="has-text-centered has-text-weight-bold"
                  style={{
                    fontSize: "2.25rem",
                    lineHeight: "normal",
                    width: "11.9375rem",
                  }}
                >
                  Đề thi
                </p>
                <p
                  className="is-uppercase has-text-weight-bold"
                  style={{
                    fontSize: "2.25rem",
                    lineHeight: "normal",
                  }}
                >
                  {localStorage.getItem("nameExam") ||
                    JSON.parse(localStorage.getItem("yearExamNow"))[0]?.name}
                </p>
                <p
                  className="is-uppercase has-text-weight-bold has-text-centered"
                  style={{ fontSize: "1.5rem" }}
                >
                  {getGradeName(gradeName)}
                </p>
              </div>
              <div className="is-flex is-flex-gap-4">
                <div
                  className="is-flex is-flex-direction-column is-flex-gap-2 is-align-items-center has-background-kurio-yellow-light-3"
                  style={{
                    width: "12.5rem",
                    borderRadius: "0.5rem",
                    padding: "1rem 1.5rem",
                    lineHeight: "normal",
                  }}
                >
                  <span style={{ fontSize: "1.25rem" }}>Điểm lần trước</span>
                  <h2
                    className="has-text-kurio-main-color-black has-text-weight-bold"
                    style={{ fontSize: "1.5rem", lineHeight: "normal" }}
                  >
                    <p>
                      {handleCheckMaxScore(examId) === 0
                        ? "-"
                        : `${handleCheckCurrentScore(
                            examId
                          )} / ${handleCheckMaxScore(examId)}`}
                    </p>
                  </h2>
                </div>
                <div
                  className="is-flex is-flex-direction-column is-flex-gap-2 is-align-items-center has-background-kurio-red-light-3"
                  style={{
                    borderRadius: "0.5rem",
                    padding: "1rem 1.5rem",
                    lineHeight: "normal",
                  }}
                >
                  <span style={{ fontSize: "1.25rem" }}>Thời gian làm bài</span>
                  <h2
                    className="has-text-kurio-main-color-black has-text-weight-bold"
                    style={{ fontSize: "1.5rem", lineHeight: "normal" }}
                  >
                    <p>
                      {
                        JSON.parse(localStorage.getItem("yearExamNow"))[0]
                          ?.examTime
                      }{" "}
                      phút
                    </p>
                  </h2>
                </div>
              </div>
            </div>
            {showServerTimeError && (
              <p className="has-text-centered has-text-kurio-main-color-black">
                <span className="has-text-danger">Cảnh báo : </span>
                Thời gian trên máy của bạn không phải múi giờ tiêu chuẩn, hãy
                đồng bộ hoá hoặc liên hệ số <b>0971 500 120</b> để được giúp đỡ
              </p>
            )}
            <div className="is-flex" style={{ gap: "1rem", margin: "0 auto" }}>
              <PrimaryButtonPageLevel
                isDarkColor={true}
                style={{ height: "4rem" }}
                // disabled={handleCheckMaxScore(examId) === 0}
                onClick={() => {
                  handleClickButton("XEM LOI GIAI", takeExamIdLPInformation);
                }}
              >
                Xem lời giải
              </PrimaryButtonPageLevel>
              <PrimaryButtonPageLevel
                style={{ height: "4rem" }}
                onClick={() =>
                  handleClickButton(
                    "LAM LAI",
                    localStorage.getItem("examsQuestionId")
                  )
                }
              >
                làm lại đề này
              </PrimaryButtonPageLevel>
            </div>

            <div
              onClick={removePopup}
              className=""
              style={{
                position: "absolute",
                top: "-8%",
                left: "-4%",
                zIndex: 9999,
                background: "transparent",
                border: "none",
                width: "5rem",
                fontSize: "2rem",
              }}
            >
              <PopupCloseButton />
            </div>
          </div>
        </div>
      )}

      {showPopupStartExam && (
        <div className="modal is-active" style={{ opacity: 1 }}>
          <div className=" modal-background" onClick={removePopup}></div>
          <div
            className=" has-text-kurio-main-color-black is-relative has-background-kurio-main-color-white is-flex is-flex-direction-column is-flex-gap-5 is-align-items-center is-justify-content-center"
            style={{
              width: "39rem",
              padding: "2.5rem 4rem",
              borderRadius: "2rem",
            }}
          >
            <div className="is-flex is-flex-direction-column is-flex-gap-5 is-align-items-center">
              <div className="has-text-centered">
                <p
                  className="has-text-centered has-text-weight-bold"
                  style={{
                    fontSize: "2.25rem",
                    lineHeight: "normal",
                    width: "11.9375rem",
                  }}
                >
                  Đề thi
                </p>
                <p
                  className="is-uppercase has-text-weight-bold"
                  style={{
                    fontSize: "2.25rem",
                    lineHeight: "normal",
                  }}
                >
                  {localStorage.getItem("nameExam") ||
                    JSON.parse(localStorage.getItem("yearExamNow"))[0]?.name}
                </p>
                <p
                  className="is-uppercase has-text-weight-bold has-text-centered"
                  style={{ fontSize: "1.5rem" }}
                >
                  {getGradeName(gradeName)}
                </p>
              </div>
              <div className="is-flex is-flex-gap-4">
                <div
                  className="is-flex is-flex-direction-column is-flex-gap-2 is-align-items-center has-background-kurio-yellow-light-3"
                  style={{
                    width: "12.5rem",
                    borderRadius: "0.5rem",
                    padding: "1rem 1.5rem",
                    lineHeight: "normal",
                  }}
                >
                  <span style={{ fontSize: "1.25rem" }}>Điểm lần trước</span>
                  <h2
                    className="has-text-kurio-main-color-black has-text-weight-bold"
                    style={{ fontSize: "1.5rem", lineHeight: "normal" }}
                  >
                    <p>
                      {handleCheckMaxScore(examId) === 0
                        ? "-"
                        : `${handleCheckCurrentScore(
                            examId
                          )} / ${handleCheckMaxScore(examId)}`}
                    </p>
                  </h2>
                </div>
                <div
                  className="is-flex is-flex-direction-column is-flex-gap-2 is-align-items-center has-background-kurio-red-light-3"
                  style={{
                    borderRadius: "0.5rem",
                    padding: "1rem 1.5rem",
                    lineHeight: "normal",
                  }}
                >
                  <span style={{ fontSize: "1.25rem" }}>Thời gian làm bài</span>
                  <h2
                    className="has-text-kurio-main-color-black has-text-weight-bold"
                    style={{ fontSize: "1.5rem", lineHeight: "normal" }}
                  >
                    <p>
                      {
                        JSON.parse(localStorage.getItem("yearExamNow"))[0]
                          ?.examTime
                      }{" "}
                      phút
                    </p>
                  </h2>
                </div>
              </div>
            </div>
            {showServerTimeError && (
              <p className="has-text-centered has-text-kurio-main-color-black">
                <span className="has-text-danger">Cảnh báo : </span>
                Thời gian trên máy của bạn không phải múi giờ tiêu chuẩn, hãy
                đồng bộ hoá hoặc liên hệ số <b>0971 500 120</b> để được giúp đỡ
              </p>
            )}
            <div className="is-flex" style={{ gap: "1rem", margin: "0 auto" }}>
              <PrimaryButtonPageLevel
                style={{ height: "4rem" }}
                onClick={() =>
                  handleClickButton(
                    "BAT DAU",
                    localStorage.getItem("examsQuestionId")
                  )
                }
              >
                Bắt đầu làm bài
              </PrimaryButtonPageLevel>
            </div>

            <div
              onClick={removePopup}
              className=""
              style={{
                position: "absolute",
                top: "-8%",
                left: "-4%",
                zIndex: 9999,
                background: "transparent",
                border: "none",
                width: "5rem",
                fontSize: "2rem",
              }}
            >
              <PopupCloseButton />
            </div>
          </div>
        </div>
      )}

      <div
        className="is-flex is-align-content-center is-justify-content-center is-align-items-center"
        style={{ minHeight: "100vh", top: "-6.5rem" }}
      >
        <div
          onClick={removePopupOutSide}
          style={{ padding: "1rem 4.5rem 3rem 4.5rem" }}
        >
          <div className="container-wrapper">
            <div
              className="is-flex is-flex-direction-column"
              style={{ gap: "0.75rem" }}
            >
              <h3 className="has-text-kurio-main-color-white">Đề thi IKMC</h3>
              <p
                className="has-text-kurio-main-color-white"
                style={{ fontSize: "1rem", fontWeight: 500 }}
              >
                Trải nghiệm chân thực Đề thi IKMC các năm trước với chức năng
                Phòng Thi Ảo.
              </p>
            </div>
            <div className="filter-exams is-hidden">
              <img src="/images/hub/icon-filter.svg" alt="" />

              <div onClick={handleClickOption}>
                <div
                  style={
                    showOption ? { border: "none", background: "#D9E1F0" } : {}
                  }
                  className="option-filter-year"
                >
                  <p className="option-filter">
                    {selectedYear !== -1 && selectedYear !== ""
                      ? selectedYear
                      : "Tất cả các năm"}{" "}
                  </p>

                  <ImageFromUrl
                    objectId="admin/frontend-images/exam/icon-option.svg"
                    alt=""
                  />
                </div>
              </div>

              {showOption && (
                <div className="accmenu__button-click__grade ">
                  <ul>
                    <li
                      onClick={() => handleSelectItemOption(-1)}
                      style={{ color: "#0A2A66" }}
                    >
                      Tất cả các năm{" "}
                      {listYears.includes(selectedYear) === false && (
                        <ImageFromUrl
                          style={{
                            width: "0.5rem",
                            height: "0.5rem",
                            marginLeft: "2.875rem",
                          }}
                          objectId="admin/frontend-images/exam/icon-active.svg"
                          alt=""
                        />
                      )}
                    </li>

                    {listYears &&
                      listYears.length > 0 &&
                      listYears.map((item, index) => {
                        return (
                          <li
                            onClick={() => handleSelectItemOption(item)}
                            key={index}
                          >
                            {item}

                            {selectedYear === item && (
                              <ImageFromUrl
                                style={{
                                  width: "0.5rem",
                                  height: "0.5rem",
                                  marginLeft: "6.875rem",
                                }}
                                objectId="admin/frontend-images/exam/icon-active.svg"
                                alt=""
                              />
                            )}
                          </li>
                        );
                      })}
                  </ul>
                </div>
              )}
            </div>

            {isLoading && examQuestions === null ? (
              <span className="loader"></span>
            ) : (
              <div className="content-exams">
                {selectedYear !== -1
                  ? listNewYear &&
                    listNewYear
                      ?.filter(
                        (item) =>
                          item.name !== null && item.name.includes(selectedYear)
                      )
                      .map((item, index) => {
                        return (
                          <div
                            className={`${
                              !checkPremium(listUserInfo) && item.premium
                                ? "has-background-kurio-gray-light-2"
                                : ""
                            } item-exams`}
                            key={index}
                          >
                            {((listUserInfo !== null &&
                              checkPremium(listUserInfo)) ||
                              !item.premium) && (
                              <>
                                <div className="item-exams-top is-relative">
                                  <ImageFromUrl objectId={item.images[0]} />
                                  <div className="content-item">
                                    <div className="title-name">
                                      Đề thi <br />
                                      <span>{item.name}</span>
                                      <div
                                        style={{
                                          padding: "0.375rem 0.75rem",
                                          borderRadius: "1rem",
                                          background: "#FAFBFC",
                                          width: "5.3rem",
                                        }}
                                      >
                                        <p
                                          className="has-text-kurio-main-color-black"
                                          style={{
                                            fontSize: "0.875rem",
                                            fontWeight: 700,
                                            textTransform: "uppercase",
                                          }}
                                        >
                                          {getGradeName(gradeName)}
                                        </p>
                                      </div>
                                    </div>

                                    <PrimaryButtonLevelSection
                                      onClick={() =>
                                        handleClickExamsQuestion(
                                          item._id,
                                          item.name
                                        )
                                      }
                                    >
                                      Xem
                                    </PrimaryButtonLevelSection>
                                  </div>
                                </div>
                                {JSON.parse(
                                  localStorage.getItem("listExamInfo")
                                ) &&
                                JSON.parse(
                                  localStorage.getItem("listNewExamSolutions")
                                ) &&
                                handleCheckMaxScore(item._id) &&
                                handleCheckMaxScore(item._id) !== 0 ? (
                                  <div className="item-exams-bottom has-background-kurio-main-color-black ">
                                    <p className="title-item-exams has-text-kurio-main-color-white">
                                      Kết quả gần nhất
                                    </p>

                                    <div
                                      className="has-text-kurio-main-color-white is-uppercase has-text-weight-bold has-text-centered"
                                      style={{
                                        fontSize: "2rem",
                                        lineHeight: "2.43rem",
                                      }}
                                    >
                                      <div className="is-flex is-align-items-center is-flex-direction-row is-justify-content-center">
                                        <p
                                          style={{
                                            marginRight: "0.25rem",
                                            fontWeight: 700,
                                            fontSize: "2rem",
                                            lineHeight: "2.4rem",
                                            textTransform: "uppercase",
                                          }}
                                        >
                                          {handleCheckCurrentScore(item._id)}
                                        </p>
                                        <span
                                          style={{
                                            marginTop: "0.625rem",
                                            textTransform: "uppercase",
                                            display: "flex",
                                            fontWeight: 700,
                                            fontSize: "0.9rem",
                                            lineHeight: "1.5rem",
                                          }}
                                        >
                                          <p style={{ marginRight: "0.25rem" }}>
                                            /
                                          </p>
                                          {handleCheckMaxScore(item._id)}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                ) : (
                                  <div className="item-exams-bottom">
                                    <p className="title-item-exams">
                                      Kết quả gần nhất
                                    </p>

                                    <ImageFromUrl
                                      objectId="admin/frontend-images/hub/icon-point-exam.svg"
                                      alt=""
                                    />
                                  </div>
                                )}
                              </>
                            )}
                            {!checkPremium(listUserInfo) && item.premium && (
                              <>
                                <div className="item-exams-top is-relative">
                                  <ImageFromUrl
                                    objectId={item.images[0]}
                                    style={{ filter: "grayScale(100%)" }}
                                  />
                                  <div
                                    className="has-background-kurio-main-color-black has-text-centered"
                                    style={{
                                      opacity: 0.5,
                                      position: "absolute",
                                      width: "15rem",
                                      height: "23.5rem",
                                      borderRadius: "0.75rem",
                                      top: "0rem",
                                      zIndex: 1,
                                    }}
                                  ></div>
                                  <ImageFromUrl
                                    style={{
                                      position: "absolute",
                                      top: "7.75rem",
                                      left: "5.75rem",
                                      width: "4rem",
                                      height: "4rem",
                                      zIndex: 2,
                                    }}
                                    objectId="admin/frontend-images/units/lock-white.svg"
                                  />
                                  <div className="content-item">
                                    <div className="title-name">
                                      Đề thi <br />
                                      <span>{item.name}</span>
                                      <div
                                        style={{
                                          padding: "0.375rem 0.75rem",
                                          borderRadius: "1rem",
                                          background: "#FAFBFC",
                                          width: "5.3rem",
                                        }}
                                      >
                                        <p
                                          className="has-text-kurio-main-color-black"
                                          style={{
                                            fontSize: "0.875rem",
                                            fontWeight: 700,
                                            textTransform: "uppercase",
                                          }}
                                        >
                                          {getGradeName(gradeName)}
                                        </p>
                                      </div>
                                    </div>

                                    <PrimaryButtonLevelSection
                                      onClick={() =>
                                        handleClickExamsQuestion(
                                          item._id,
                                          item.name
                                        )
                                      }
                                      disabled={true}
                                    >
                                      Xem
                                    </PrimaryButtonLevelSection>
                                  </div>
                                </div>
                                {JSON.parse(
                                  localStorage.getItem("listExamInfo")
                                ) &&
                                JSON.parse(
                                  localStorage.getItem("listNewExamSolutions")
                                ) &&
                                handleCheckMaxScore(item._id) &&
                                handleCheckMaxScore(item._id) !== 0 ? (
                                  <div className="item-exams-bottom has-background-kurio-gray-light-2 ">
                                    <p className="title-item-exams">
                                      Kết quả gần nhất
                                    </p>

                                    <div
                                      className="has-text-kurio-main-color-black is-uppercase has-text-weight-bold has-text-centered"
                                      style={{
                                        fontSize: "2rem",
                                        lineHeight: "2.43rem",
                                      }}
                                    >
                                      <div className="is-flex is-align-items-center is-flex-direction-row is-justify-content-center">
                                        <p
                                          style={{
                                            marginRight: "0.25rem",
                                            fontWeight: 700,
                                            fontSize: "2rem",
                                            lineHeight: "2.4rem",
                                            textTransform: "uppercase",
                                          }}
                                        >
                                          {handleCheckCurrentScore(item._id)}
                                        </p>
                                        <span
                                          style={{
                                            marginTop: "0.625rem",
                                            textTransform: "uppercase",
                                            display: "flex",
                                            fontWeight: 700,
                                            fontSize: "0.9rem",
                                            lineHeight: "1.5rem",
                                          }}
                                        >
                                          <p style={{ marginRight: "0.25rem" }}>
                                            /
                                          </p>
                                          {handleCheckMaxScore(item._id)}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                ) : (
                                  <div className="item-exams-bottom has-background-kurio-gray-light-2">
                                    <p className="title-item-exams">
                                      Kết quả gần nhất
                                    </p>

                                    <ImageFromUrl
                                      objectId="admin/frontend-images/hub/icon-point-exam.svg"
                                      alt=""
                                    />
                                  </div>
                                )}
                              </>
                            )}
                          </div>
                        );
                      })
                  : listNewYear && listNewYear.length > 0
                  ? listNewYear.map((item, index) => {
                      return (
                        <div
                          className={`${
                            !checkPremium(listUserInfo) && item.premium
                              ? "has-background-kurio-gray-light-2"
                              : ""
                          } item-exams`}
                          key={index}
                        >
                          {((listUserInfo !== null &&
                            checkPremium(listUserInfo)) ||
                            !item.premium) && (
                            <>
                              <div className="item-exams-top is-relative">
                                <ImageFromUrl objectId={item.images[0]} />
                                <div className="content-item">
                                  <div className="title-name">
                                    Đề thi <br />
                                    <span>{item.name}</span>
                                    <div
                                      style={{
                                        padding: "0.375rem 0.75rem",
                                        borderRadius: "1rem",
                                        background: "#FAFBFC",
                                        width: "5.3rem",
                                      }}
                                    >
                                      <p
                                        className="has-text-kurio-main-color-black"
                                        style={{
                                          fontSize: "0.875rem",
                                          fontWeight: 700,
                                          textTransform: "uppercase",
                                        }}
                                      >
                                        {getGradeName(gradeName)}
                                      </p>
                                    </div>
                                  </div>

                                  <PrimaryButtonLevelSection
                                    onClick={() =>
                                      handleClickExamsQuestion(
                                        item._id,
                                        item.name
                                      )
                                    }
                                  >
                                    Xem
                                  </PrimaryButtonLevelSection>
                                </div>
                              </div>
                              {JSON.parse(
                                localStorage.getItem("listExamInfo")
                              ) &&
                              JSON.parse(
                                localStorage.getItem("listNewExamSolutions")
                              ) &&
                              handleCheckMaxScore(item._id) &&
                              handleCheckMaxScore(item._id) !== 0 ? (
                                <div className="item-exams-bottom has-background-kurio-main-color-black ">
                                  <p className="title-item-exams has-text-kurio-main-color-white">
                                    Kết quả gần nhất
                                  </p>

                                  <div
                                    className="has-text-kurio-main-color-white is-uppercase has-text-weight-bold has-text-centered"
                                    style={{
                                      fontSize: "2rem",
                                      lineHeight: "2.43rem",
                                    }}
                                  >
                                    <div className="is-flex is-align-items-center is-flex-direction-row is-justify-content-center">
                                      <p
                                        style={{
                                          marginRight: "0.25rem",
                                          fontWeight: 700,
                                          fontSize: "2rem",
                                          lineHeight: "2.4rem",
                                          textTransform: "uppercase",
                                        }}
                                      >
                                        {handleCheckCurrentScore(item._id)}
                                      </p>
                                      <span
                                        style={{
                                          marginTop: "0.625rem",
                                          textTransform: "uppercase",
                                          display: "flex",
                                          fontWeight: 700,
                                          fontSize: "0.9rem",
                                          lineHeight: "1.5rem",
                                        }}
                                      >
                                        <p style={{ marginRight: "0.25rem" }}>
                                          /
                                        </p>
                                        {handleCheckMaxScore(item._id)}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              ) : (
                                <div className="item-exams-bottom">
                                  <p className="title-item-exams">
                                    Kết quả gần nhất
                                  </p>

                                  <ImageFromUrl
                                    objectId="admin/frontend-images/hub/icon-point-exam.svg"
                                    alt=""
                                  />
                                </div>
                              )}
                            </>
                          )}
                          {!checkPremium(listUserInfo) && item.premium && (
                            <>
                              <div className="item-exams-top is-relative">
                                <ImageFromUrl
                                  objectId={item.images[0]}
                                  style={{ filter: "grayScale(100%)" }}
                                />
                                <div
                                  className="has-background-kurio-main-color-black has-text-centered"
                                  style={{
                                    opacity: 0.5,
                                    position: "absolute",
                                    width: "15rem",
                                    height: "23.5rem",
                                    borderRadius: "0.75rem",
                                    top: "0rem",
                                    zIndex: 1,
                                  }}
                                ></div>
                                <ImageFromUrl
                                  style={{
                                    position: "absolute",
                                    top: "7.75rem",
                                    left: "5.75rem",
                                    width: "4rem",
                                    height: "4rem",
                                    zIndex: 2,
                                  }}
                                  objectId="admin/frontend-images/units/lock-white.svg"
                                />
                                <div className="content-item">
                                  <div className="title-name">
                                    Đề thi <br />
                                    <span>{item.name}</span>
                                    <div
                                      style={{
                                        padding: "0.375rem 0.75rem",
                                        borderRadius: "1rem",
                                        background: "#FAFBFC",
                                        width: "5.3rem",
                                      }}
                                    >
                                      <p
                                        className="has-text-kurio-main-color-black"
                                        style={{
                                          fontSize: "0.875rem",
                                          fontWeight: 700,
                                          textTransform: "uppercase",
                                        }}
                                      >
                                        {getGradeName(gradeName)}
                                      </p>
                                    </div>
                                  </div>

                                  <PrimaryButtonLevelSection
                                    onClick={() =>
                                      handleClickExamsQuestion(
                                        item._id,
                                        item.name
                                      )
                                    }
                                    disabled={true}
                                  >
                                    Xem
                                  </PrimaryButtonLevelSection>
                                </div>
                              </div>
                              {JSON.parse(
                                localStorage.getItem("listExamInfo")
                              ) &&
                              JSON.parse(
                                localStorage.getItem("listNewExamSolutions")
                              ) &&
                              handleCheckMaxScore(item._id) &&
                              handleCheckMaxScore(item._id) !== 0 ? (
                                <div className="item-exams-bottom has-background-kurio-gray-light-2 ">
                                  <p className="title-item-exams has-text-kurio-main-color-black">
                                    Kết quả gần nhất
                                  </p>

                                  <div
                                    className="has-text-kurio-main-color-black is-uppercase has-text-weight-bold has-text-centered"
                                    style={{
                                      fontSize: "2rem",
                                      lineHeight: "2.43rem",
                                    }}
                                  >
                                    <div className="is-flex is-align-items-center is-flex-direction-row is-justify-content-center">
                                      <p
                                        style={{
                                          marginRight: "0.25rem",
                                          fontWeight: 700,
                                          fontSize: "2rem",
                                          lineHeight: "2.4rem",
                                          textTransform: "uppercase",
                                        }}
                                      >
                                        {handleCheckCurrentScore(item._id)}
                                      </p>
                                      <span
                                        style={{
                                          marginTop: "0.625rem",
                                          textTransform: "uppercase",
                                          display: "flex",
                                          fontWeight: 700,
                                          fontSize: "0.9rem",
                                          lineHeight: "1.5rem",
                                        }}
                                      >
                                        <p style={{ marginRight: "0.25rem" }}>
                                          /
                                        </p>
                                        {handleCheckMaxScore(item._id)}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              ) : (
                                <div className="item-exams-bottom has-background-kurio-gray-light-2">
                                  <p className="title-item-exams">
                                    Kết quả gần nhất
                                  </p>

                                  <ImageFromUrl
                                    objectId="admin/frontend-images/hub/icon-point-exam.svg"
                                    alt=""
                                  />
                                </div>
                              )}
                            </>
                          )}
                        </div>
                      );
                    })
                  : currentPage &&
                    currentItems.map((item, index) => {
                      return (
                        <div
                          className={`${
                            !checkPremium(listUserInfo) && item.premium
                              ? "has-background-kurio-gray-light-2"
                              : ""
                          } item-exams`}
                          key={index}
                        >
                          {((listUserInfo !== null &&
                            checkPremium(listUserInfo)) ||
                            !item.premium) && (
                            <>
                              <div className="item-exams-top is-relative">
                                <ImageFromUrl objectId={item.images[0]} />
                                <div className="content-item">
                                  <div className="title-name">
                                    Đề thi <br />
                                    <span>{item.name}</span>
                                    <div
                                      style={{
                                        padding: "0.375rem 0.75rem",
                                        borderRadius: "1rem",
                                        background: "#FAFBFC",
                                        width: "5.3rem",
                                      }}
                                    >
                                      <p
                                        className="has-text-kurio-main-color-black"
                                        style={{
                                          fontSize: "0.875rem",
                                          fontWeight: 700,
                                          textTransform: "uppercase",
                                        }}
                                      >
                                        {getGradeName(gradeName)}
                                      </p>
                                    </div>
                                  </div>

                                  <PrimaryButtonLevelSection
                                    onClick={() =>
                                      handleClickExamsQuestion(
                                        item._id,
                                        item.name
                                      )
                                    }
                                  >
                                    Xem
                                  </PrimaryButtonLevelSection>
                                </div>
                              </div>
                              {JSON.parse(
                                localStorage.getItem("listExamInfo")
                              ) &&
                              JSON.parse(
                                localStorage.getItem("listNewExamSolutions")
                              ) &&
                              handleCheckMaxScore(item._id) &&
                              handleCheckMaxScore(item._id) !== 0 ? (
                                <div className="item-exams-bottom has-background-kurio-main-color-black ">
                                  <p className="title-item-exams has-text-kurio-main-color-white">
                                    Kết quả gần nhất
                                  </p>

                                  <div
                                    className="has-text-kurio-main-color-white is-uppercase has-text-weight-bold has-text-centered"
                                    style={{
                                      fontSize: "2rem",
                                      lineHeight: "2.43rem",
                                    }}
                                  >
                                    <div className="is-flex is-align-items-center is-flex-direction-row is-justify-content-center">
                                      <p
                                        style={{
                                          marginRight: "0.25rem",
                                          fontWeight: 700,
                                          fontSize: "2rem",
                                          lineHeight: "2.4rem",
                                          textTransform: "uppercase",
                                        }}
                                      >
                                        {handleCheckCurrentScore(item._id)}
                                      </p>
                                      <span
                                        style={{
                                          marginTop: "0.625rem",
                                          textTransform: "uppercase",
                                          display: "flex",
                                          fontWeight: 700,
                                          fontSize: "0.9rem",
                                          lineHeight: "1.5rem",
                                        }}
                                      >
                                        <p style={{ marginRight: "0.25rem" }}>
                                          /
                                        </p>
                                        {handleCheckMaxScore(item._id)}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              ) : (
                                <div className="item-exams-bottom">
                                  <p className="title-item-exams">
                                    Kết quả gần nhất
                                  </p>

                                  <ImageFromUrl
                                    objectId="admin/frontend-images/hub/icon-point-exam.svg"
                                    alt=""
                                  />
                                </div>
                              )}
                            </>
                          )}
                          {!checkPremium(listUserInfo) && item.premium && (
                            <>
                              <div className="item-exams-top is-relative">
                                <ImageFromUrl
                                  objectId={item.images[0]}
                                  style={{ filter: "grayScale(100%)" }}
                                />
                                <div
                                  className="has-background-kurio-main-color-black has-text-centered"
                                  style={{
                                    opacity: 0.5,
                                    position: "absolute",
                                    width: "15rem",
                                    height: "23.5rem",
                                    borderRadius: "0.75rem",
                                    top: "0rem",
                                    zIndex: 1,
                                  }}
                                ></div>
                                <ImageFromUrl
                                  style={{
                                    position: "absolute",
                                    top: "7.75rem",
                                    left: "5.75rem",
                                    width: "4rem",
                                    height: "4rem",
                                    zIndex: 2,
                                  }}
                                  objectId="admin/frontend-images/units/lock-white.svg"
                                />
                                <div className="content-item">
                                  <div className="title-name">
                                    Đề thi <br />
                                    <span>{item.name}</span>
                                    <div
                                      style={{
                                        padding: "0.375rem 0.75rem",
                                        borderRadius: "1rem",
                                        background: "#FAFBFC",
                                        width: "5.3rem",
                                      }}
                                    >
                                      <p
                                        className="has-text-kurio-main-color-black"
                                        style={{
                                          fontSize: "0.875rem",
                                          fontWeight: 700,
                                          textTransform: "uppercase",
                                        }}
                                      >
                                        {getGradeName(gradeName)}
                                      </p>
                                    </div>
                                  </div>

                                  <PrimaryButtonLevelSection
                                    onClick={() =>
                                      handleClickExamsQuestion(
                                        item._id,
                                        item.name
                                      )
                                    }
                                    disabled={true}
                                  >
                                    Xem
                                  </PrimaryButtonLevelSection>
                                </div>
                              </div>
                              {JSON.parse(
                                localStorage.getItem("listExamInfo")
                              ) &&
                              JSON.parse(
                                localStorage.getItem("listNewExamSolutions")
                              ) &&
                              handleCheckMaxScore(item._id) &&
                              handleCheckMaxScore(item._id) !== 0 ? (
                                <div className="item-exams-bottom has-background-kurio-gray-light-2 ">
                                  <p className="title-item-exams has-text-kurio-main-color-black">
                                    Kết quả gần nhất
                                  </p>

                                  <div
                                    className="has-text-kurio-main-color-black is-uppercase has-text-weight-bold has-text-centered"
                                    style={{
                                      fontSize: "2rem",
                                      lineHeight: "2.43rem",
                                    }}
                                  >
                                    <div className="is-flex is-align-items-center is-flex-direction-row is-justify-content-center">
                                      <p
                                        style={{
                                          marginRight: "0.25rem",
                                          fontWeight: 700,
                                          fontSize: "2rem",
                                          lineHeight: "2.4rem",
                                          textTransform: "uppercase",
                                        }}
                                      >
                                        {handleCheckCurrentScore(item._id)}
                                      </p>
                                      <span
                                        style={{
                                          marginTop: "0.625rem",
                                          textTransform: "uppercase",
                                          display: "flex",
                                          fontWeight: 700,
                                          fontSize: "0.9rem",
                                          lineHeight: "1.5rem",
                                        }}
                                      >
                                        <p style={{ marginRight: "0.25rem" }}>
                                          /
                                        </p>
                                        {handleCheckMaxScore(item._id)}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              ) : (
                                <div className="item-exams-bottom has-background-kurio-gray-light-2">
                                  <p className="title-item-exams">
                                    Kết quả gần nhất
                                  </p>

                                  <ImageFromUrl
                                    objectId="admin/frontend-images/hub/icon-point-exam.svg"
                                    alt=""
                                  />
                                </div>
                              )}
                            </>
                          )}
                        </div>
                      );
                    })}
              </div>
            )}

            {((listNewYear !== null &&
              listNewYear?.filter(
                (item) => item.name !== null && item.name.includes(selectedYear)
              ).length > 0) ||
              listYears.includes(selectedYear) === false ||
              `${window.location.pathname}?${searchParams.toString()}` ===
                "/exams-vault?yearNow=Tat_ca_cac_nam" ||
              `${window.location.pathname}?${searchParams.toString()}` ===
                `/exams-vault?yearNow=Tat_ca_cac_nam&page=${page}` ||
              `${window.location.pathname}?${searchParams.toString()}` ===
                `/exams-vault?page=${page}&yearNow=Tat_ca_cac_nam}`) && (
              <div className="pagination-exams">
                <nav
                  className="pagination is-rounded"
                  role="navigation"
                  aria-label="pagination"
                >
                  <ul className="pagination-list">
                    <PreviousButton
                      disable={currentPage === 1 ? true : false}
                      onClick={handlePreviousButton}
                    />

                    {renderPageNumbers()}

                    <NextButton onClick={handleNextButton} />
                  </ul>
                </nav>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

function mapStateToProps(state) {
  return {
    gradeName: state.grades.gradeName,
    listUserInfo: state.grades.listUserInfo,
    examQuestions: state.examsQuestion.listYearExams,
    examInformation: state.examsQuestion.examInformation,
    recordCount: state.examsQuestion.recordCount,
    pageCount: state.examsQuestion.pageCount,
    currentPages: state.examsQuestion.currentPage,
    takeExamsQuestionId: state.examsQuestion.takeExamsQuestion,
    pageSize: state.examsQuestion.pageSize,
    grades: state.grades.gradeId,
    takeExamIdInformation: state.examsQuestion.takeExamIdInformation,
    takeExamIdLPInformation: state.examsQuestion.takeExamIdLPInformation,
    error: state.examsQuestion.error,
    status: state.examsQuestion.status,
    isChangeGrade: state.grades.isChangeGrade,
    serverTime: state.examsQuestion.serverTime,
    deadline: state.examsQuestion.checkExamTime,
    questions: state.examsQuestion.questions,
    errorGrade: state.grades.error,
  };
}

export default connect(mapStateToProps)(ExamsQuestionVault);
